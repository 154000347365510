<template>
  <div>
    <ComputerArticleNavMenu></ComputerArticleNavMenu>

    <div class="personal-page-container">

      <!--      <el-button @click="calendar">calendar</el-button>-->

      <div class="personal-info-container">
        <div class="user-image-setting-btn">
          <div class="image-nickname-container">
            <el-image
                style="width: 100px; height: 100px;border-radius: 50%"
                :src="imageUrl"
                fit="cover"
            ></el-image>

            <span style="margin-left: 20px">{{ nickname }}</span>
            <i class="fa fa-edit" style="margin-left: 10px; font-weight: lighter;cursor: pointer;font-size: medium"
               @click="updateUserNicknameDialogVisible = true"/>
          </div>

          <!--      设置按钮区域      -->
          <div class="settings-btn-container">
            <el-dropdown trigger="click">
              <el-button class="settings-btn">
                <i class="fa fa-gear"></i>
                <span style="margin-left: 5px">{{ $t("basic.jump_to_settings") }}</span>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="confirmEmailFormVisible = true">
                  {{ $t("basic.update_password") }}
                </el-dropdown-item>
                <el-dropdown-item @click.native="logout">{{ $t("basic.quit_account") }}</el-dropdown-item>

              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>

        <div class="user-info">
          <div class="info-box">
            <span class="info-label">{{ $t('basic.username') }}</span>
            <span class="info-context">{{ username }}</span>
          </div>

          <div class="info-box">
            <span class="info-label">{{ $t('basic.email') }}</span>
            <span class="info-context">{{ email }}</span>
          </div>

          <div class="info-box">
            <span class="info-label">{{ $t('basic.activate_time') }}</span>
            <span class="info-context">{{ activateTime }}</span>
          </div>

          <div class="info-box">
            <span class="info-label">{{ $t('basic.publish_text') }}</span>
            <span class="info-context">{{ articlePostings }}</span>
          </div>
        </div>
      </div>

      <!--      文章存放：个人文章、订阅专题和文章-->
      <div class="all-article-container">
        <!--    用户个人文章显示-->
        <div class="article-list" v-loading="articleListLoading">

          <div class="article-title-desc-container">
            <span>{{ $t("basic.my_article_text") }}</span>

            <el-button class="settings-btn" v-if="isEditArticle" @click="showArticleManagement" type="primary">
              <i class="fa fa-file-text-o"></i>
              <span style="margin-left: 5px">{{ $t("basic.quit_manage_text") }}</span>
            </el-button>

            <el-button class="settings-btn" v-else @click="showArticleManagement">
              <i class="fa fa-file-text-o"></i>
              <span style="margin-left: 5px">{{ $t("basic.manage_article_text") }}</span>
            </el-button>
          </div>

          <div class="article-list-container" v-if="articleList.length !== 0">
            <div class="article-item-container" v-for="(article,index) in articleList" :key="index"
                 @click="showMyArticleDetail(article)">

              <mobile-article-card
                  :article-title="article.articleTitle"
                  :article-brief-content="article.articleBriefContent"
                  :release-time="moment(article.releaseTime).format('yyyy-MM-DD HH:mm')"
                  :article-like="article.articleLike"
                  :article-views="article.articleViews"
                  :article-comments="article.articleComments"
              >

                <!-- 调用插槽，并传入状态标签的内容 -->
                <template v-slot:statusTag>
                  <el-tag :type="article.typeJudge" size="small" style="margin-right: 5px">{{
                      article.articleStatus
                    }}
                  </el-tag>
                </template>

                <template v-slot:editButton>
                  <div class="article-edit-btn" v-show="isEditArticle">
                    <el-button v-if="article.articleStatus === '已发布' || article.articleStatus === 'Published'"
                               size="small"
                               @click.stop="undercarriageArticleConfirm(article)">{{ $t("basic.undercarriage_text") }}
                    </el-button>
                    <el-button size="small" @click.stop="showMyArticleDetail(article)">{{ $t("basic.edit_text") }}
                    </el-button>
                    <el-button size="small" @click.stop="deleteArticleConfirm(article)">
                      {{ $t("basic.delete_button_text") }}
                    </el-button>
                  </div>
                </template>
              </mobile-article-card>
            </div>
          </div>

          <!--      暂无内容提示-->
          <div v-else style="color: #949797;display: flex;align-items: center;height: 80%;justify-content: center">
            <h1>{{ $t("basic.no_data_msg") }}</h1>
          </div>
        </div>

        <!--        用户订阅专题显示-->
        <div class="subscriptions-container">
          <div class="subscription-list-visible" v-if="isSubscriptionListVisible">
            <div class="article-title-desc-container">
              <span>{{ $t("basic.my_subscriptions_text") }}</span>

              <el-popover
                  placement="top"
                  width="250"
                  v-model="addSubscriptionVisible"
                  v-loading="addSubscriptionFlag"
              >

                <div style="display: flex;flex-direction: column">
                  <span>{{ $t("basic.invitation_code_text") + ": " }}</span>

                  <el-input v-model="invitationCode"
                            :placeholder="$t('basic.enter_invitation_code_text')"
                            style="padding-top: 10px"
                  >
                  </el-input>

                  <div style="height: 10px;font-size: small;color: red">
                    <span v-show="!isCodeValid">{{ $t("basic.invalid_invitation_code_text") }}</span>
                  </div>
                </div>

                <div style="display: flex;flex-direction: row;justify-content: space-between;align-items: center">
                  <el-button type="text" size="small" @click="openCreateTopicDrawer">
                    {{ $t("basic.create_topic_text") }}
                  </el-button>

                  <div>
                    <el-button size="mini" type="text" @click="addSubscriptionVisible = false">
                      {{ $t("basic.cancel_button_text") }}
                    </el-button>
                    <el-button type="primary" size="mini" @click="addSubscription">
                      {{ $t("basic.confirm_button_text") }}
                    </el-button>
                  </div>
                </div>
                <el-button class="settings-btn" slot="reference" @click="">
                  <i class="el-icon-plus"></i>
                  <span>{{ $t("basic.add_subscription_text") }}</span>
                </el-button>
              </el-popover>

              <el-button class="settings-btn" @click="isEditTopic = !isEditTopic">
                <i class="fa fa-file-text-o"></i>
                <span style="margin-left: 5px" v-if="!isEditTopic">{{ $t("basic.manage_topic_text") }}</span>
                <span style="margin-left: 5px" v-if="isEditTopic">{{ $t("basic.quit_manage_text") }}</span>
              </el-button>


            </div>

            <div class="subscription-list">
              <div v-if="topicList.length === 0"
                   style="color: #949797;display: flex;align-items: center;height: 80%;justify-content: center">
                <h1>{{ $t("basic.no_data_msg") }}</h1>
              </div>

              <div class="subscription-list-container" v-for="(topic, index) in topicList" :key="index"
                   @click="getArticleByTopicId(topic)"
              >
                <subscription-card
                    :subscription-title="topic.topicName"
                    :subscription-desc="topic.topicDesc"
                >
                  <template v-slot:editButton>
                    <div class="article-edit-btn" v-show="isEditTopic">
                      <el-button size="small" @click.stop="getInvitationCode(topic)">
                        {{$t("basic.get_invitation_code_text")}}
                      </el-button>
                      <el-button size="small" @click.stop="unsubscribeTopicConfirm(topic)">
                        {{ $t("basic.unsubscribe_text") }}
                      </el-button>
                    </div>
                  </template>
                </subscription-card>
              </div>
            </div>
          </div>

          <!--          指定专题的文章列表-->
          <div class="subscribe-article-list" v-else>
            <div class=""
                 style="width: 100%;height: 60px;display: flex;align-items: center;justify-content: space-between;position: relative"
            >
              <i class="fa fa-angle-double-left"
                 style="padding: 10px;font-size: larger;color: #949797; cursor: pointer"
                 @click="goBackToTopicList"
              ></i>

              <span
                  style="text-align: center;position: absolute;left: 50%;transform: translateX(-50%);font-weight: bold;font-size: large">{{
                  currentTopicName
                }}</span>
            </div>

            <div class="subscribe-article-list-container">
              <div v-for="(subscribedArticle, index) in subscribedArticleList"
                   :key="index"
                   @click="getSubscribedArticleDetail(subscribedArticle)"
                   style="cursor: pointer"
              >
                <mobile-article-card
                    :article-title="subscribedArticle.articleTitle"
                    :article-brief-content="subscribedArticle.articleBriefContent"
                >
                </mobile-article-card>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>


    <!--            邮箱验证-->
    <el-dialog :title="$t('basic.update_password')"
               :visible.sync="confirmEmailFormVisible" width="400px"
               v-loading="verifyCodeFlag"
               :close-on-click-modal="false"
    >
      <div>
        <div style="display: flex;flex-direction: column;align-items: center">
          <i class="fa fa-shield fa-5x"></i>
          <span style="font-size: small;color: #949797">{{ $t("basic.verify_email_tips") }}</span>
        </div>
        <div class="update-info-element-style">
          <span>{{ $t('basic.email') }}</span>
          <div style="display: flex">
            <el-input type="text" v-model="email" :placeholder="$t('basic.email_enter_tips')" disabled></el-input>
            <el-button v-loading="sendEmailFlag" style="margin-left: 25px;color: dodgerblue;font-weight: bold"
                       @click="sendEmail">{{ $t("basic.get_verification_code_button") }}
            </el-button>
          </div>
        </div>
        <div class="update-info-element-style">
          <span>{{ $t('basic.verification_code') }}</span>
          <el-input v-model="verificationCode"></el-input>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="confirmEmailFormVisible = false">{{ $t("basic.cancel_button_text") }}</el-button>
        <!--              点击确定需要验证验证码是否正确，正确则跳转到更新密码界面-->
        <el-button type="primary" @click="verifyEmail" :disabled="!isSendEmail">{{
            $t("basic.confirm_button_text")
          }}
        </el-button>
      </div>
    </el-dialog>

    <!--          更新密码-->
    <el-dialog :title="$t('basic.update_password')"
               :visible.sync="updatePasswordFormVisible" width="400px"
               v-loading="updatePasswordFlag"
               :close-on-click-modal="false"
    >
      <div>
        <div class="update-info-element-style">
          <span>{{ $t('basic.new_password') }}</span>
          <el-input v-model="newPassword" type="password" show-password></el-input>
        </div>
        <div class="update-info-element-style">
          <span>{{ $t('basic.new_password_confirm') }}</span>
          <el-input v-model="newPasswordConfirm" type="password" show-password></el-input>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="updatePasswordFormVisible = false">{{ $t("basic.cancel_button_text") }}</el-button>
        <el-button type="primary" @click="confirmUpdatePassword" :disabled="!isSendEmail">
          {{ $t("basic.confirm_button_text") }}
        </el-button>
      </div>
    </el-dialog>

    <!--    删除确认-->
    <el-dialog
        v-loading="deleteFlag"
        :visible.sync="confirmDeleteDialogVisible"
        width="25%"
    >
      <span>{{ $t("basic.confirm_delete_text") }}</span>
      <span slot="footer" class="dialog-footer" style="display: flex;align-items: center;justify-content: space-around">
        <el-button @click="confirmDeleteDialogVisible = false">{{ $t("basic.discard_text") }}</el-button>
        <el-button type="primary" @click="deleteArticle">{{ $t("basic.yes_text") }}</el-button>
      </span>
    </el-dialog>

    <!--    下架确认-->
    <el-dialog
        v-loading="undercarriageFlag"
        :visible.sync="undercarriageArticleDialogVisible"
        width="25%"
    >
      <span>{{ $t("basic.confirm_undercarriage_text") }}</span>
      <span slot="footer" class="dialog-footer" style="display: flex;align-items: center;justify-content: space-around">
        <el-button @click="undercarriageArticleDialogVisible = false">{{ $t("basic.discard_text") }}</el-button>
        <el-button type="primary" @click="undercarriageArticle">{{ $t("basic.yes_text") }}</el-button>
      </span>
    </el-dialog>

    <!--    修改昵称-->
    <el-dialog
        :visible.sync="updateUserNicknameDialogVisible"
        width="30%"
        :title="$t('basic.update_nickname_text')"
        center
        :close-on-click-modal="false"
    >

      <div class="update-nickname-container">
        <span style="width: 80px;font-size: large">{{ $t("basic.new_nickname_text") }}</span>

        <el-input style="width: 70%"
                  v-model="newNickname"
                  maxlength="10"
                  show-word-limit
                  :placeholder="$t('basic.enter_new_nickname_tips')"></el-input>
      </div>
      <span style="font-size: small;color: red;margin-left: 80px">{{ illegalNicknameEnterTips }}</span>

      <span v-loading="updateNicknameFlag" slot="footer" class="dialog-footer"
            style="display: flex;align-items: center;justify-content: space-around">
        <el-button @click="updateUserNicknameDialogVisible = false">{{ $t("basic.cancel_button_text") }}</el-button>
        <el-button type="primary" @click="updateUserNickname">{{ $t("basic.submit_text") }}</el-button>
      </span>
    </el-dialog>

    <!--    添加专题侧边栏-->
    <el-drawer
        :title="$t('basic.create_topic_text')"
        :visible.sync="createTopicDrawVisible"
        direction="rtl"
    >
      <el-form class="create-topic-form">
        <el-form-item :label="$t('basic.topic_name_text')">
          <el-input
              :placeholder="$t('basic.enter_topic_name_text')"
              v-model="topicName"
              maxlength="64"
              show-word-limit
          ></el-input>
        </el-form-item>

        <el-form-item :label="$t('basic.topic_desc_text')">
          <el-input :placeholder="$t('basic.enter_topic_desc_text')"
                    v-model="topicDesc"
                    type="textarea"
                    :autosize="{ minRows: 4, maxRows: 6}"
                    maxlength="300"
                    show-word-limit
          ></el-input>
        </el-form-item>

        <el-form-item style="display: flex;justify-content: flex-end;width: 100%">
          <el-button @click="createTopicDrawVisible = false">{{ $t("basic.cancel_button_text") }}</el-button>

          <el-button type="primary" v-loading="addTopicFlag" @click="addTopic"
                     :disabled="topicName === '' || topicDesc === ''">{{ $t("basic.submit_text") }}
          </el-button>
        </el-form-item>
      </el-form>
    </el-drawer>

    <!--    退订确认-->
    <el-dialog
        v-loading="unsubscribeTopicFlag"
        :visible.sync="unsubscribeTopicDialogVisible"
        width="25%"
    >
      <span>{{ $t("basic.unsubscribed_topic_text") + currentTopicName }}<br><br>{{ $t("basic.confirm_unsubscribe_text") }}</span>

      <span slot="footer" class="dialog-footer" style="display: flex;align-items: center;justify-content: space-around">
        <el-button @click="unsubscribeTopicDialogVisible = false">{{ $t("basic.discard_text") }}</el-button>
        <el-button type="primary" @click="unsubscribeTopic">{{ $t("basic.yes_text") }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import moment from "moment";
import LayoutContainer from "@/components/layout/LayoutContainer";
import MobileArticleCard from "@/components/component/MobileArticleCard.vue";
import ComputerArticleNavMenu from "@/components/component/ComputerArticleNavMenu.vue";
import SubscriptionCard from "@/components/component/SubscriptionCard.vue";

export default {
  name: "User",
  components: {SubscriptionCard, ComputerArticleNavMenu, MobileArticleCard, LayoutContainer},
  data() {
    return {
      unsubscribeTopicFlag: false,
      unsubscribeTopicDialogVisible: false,
      isEditTopic: false,
      isSubscriptionListVisible: true,
      addSubscriptionFlag: false,
      addTopicFlag: false,
      createTopicDrawVisible: false,
      isCodeValid: true,
      addSubscriptionVisible: false,
      updateNicknameFlag: false,
      updateUserNicknameDialogVisible: false,
      undercarriageFlag: false,
      updatePasswordFlag: false,
      verifyCodeFlag: false,
      deleteFlag: false,
      isEditArticle: false,
      articleListLoading: true,
      isCollapse: true,
      loading: true,
      confirmEmailFormVisible: false,
      updatePasswordFormVisible: false,
      sendEmailFlag: false,
      isSendEmail: false,
      confirmDeleteDialogVisible: false,
      undercarriageArticleDialogVisible: false,

      recordIds: [],
      records: [],
      createTime: [],
      multipleSelection: [],
      tableData: [],
      articleList: [],
      topicList: [],
      subscribedArticleList: [],

      username: sessionStorage.getItem("username"),
      nickname: sessionStorage.getItem("nickname"),
      imageUrl: sessionStorage.getItem("userImage"),

      // uuid: '',
      illegalNicknameEnterTips: '',
      newNickname: '',
      email: '',
      currentArticleId: '',
      activateTime: '',
      articlePostings: 0,
      newPassword: '',
      newPasswordConfirm: '',
      verificationCode: '',
      tempToken: '',
      invitationCode: '',
      topicName: '',
      topicDesc: '',
      currentTopicName: '',
      currentTopicDesc: '',
      customArticleId: '',
      currentTopicId: '',


      labelStyle: {
        "text-align": "center",
        "font-weight": "bold",
      },
      contentStyle: {
        "text-align": "center",
      },
    }
  },

  created() {
    this.getUserInfo();
    this.getMyArticle();
    this.moment = moment;
    this.getTopicList();
  },

  methods: {
    // calendar(){
    //   const requestData = {
    //     startDate:"2024-5-1",
    //     endDate:"2024-5-24"
    //   }
    //
    //   this.$http.get('/getDateFromChoice',{
    //     params:requestData
    //   }).then(res=>{
    //     console.log(res)
    //   }).catch(err=>{
    //     console.error(err);
    //   })
    // },

    getInvitationCode(topic){
      // console.log("Attempting to copy URL:", url);

      // 创建一个临时输入元素
      const tempInput = document.createElement('input');
      tempInput.value = topic.inviteCode;
      document.body.appendChild(tempInput);
      tempInput.select();
      const success = document.execCommand('copy');
      document.body.removeChild(tempInput);

      if (success) {
        // console.log("URL copied successfully");
        this.$message.success(this.$t("basic.copy_invitation_code_success_text").toString());
      } else {
        // console.error("Failed to copy URL");
        this.$message.error(this.$t("basic.copy_invitation_code_failed_text").toString());
      }
    },

    unsubscribeTopicConfirm(topic) {
      this.currentTopicId = topic.topicId;
      this.currentTopicName = topic.topicName;
      this.unsubscribeTopicDialogVisible = true;
    },

    unsubscribeTopic() {
      this.unsubscribeTopicFlag = true;

      const config = {
        headers: {
          'Authorization': sessionStorage.getItem("token") // 替换 yourToken 为实际的 token 值
        }
      }

      const requestData = {
        topicId: this.currentTopicId
      }

      this.$http.post('/unsubscribeTopicByTopicId', requestData, config).then(res => {
        if (res.data.code === 200) {
          this.$message.success(this.$t("basic.unsubscribe_success_text").toString());
          //   重新获取订阅专题列表
          this.getTopicList();
          this.unsubscribeTopicDialogVisible = false;
        } else {
          this.$message.error(this.$t("basic.unsubscribe_failed_text").toString());
        }
      }).catch(err => {
        this.$message.error(this.$t("basic.unsubscribe_failed_text").toString());
      }).finally(() => {
        this.unsubscribeTopicFlag = false;
      })
    },

    goBackToTopicList() {
      this.isSubscriptionListVisible = true
      this.subscribedArticleList = [];
    },

    getSubscribedArticleDetail(subscribedArticle) {
      this.$router.push({name: 'ArticleDetails', params: {id: subscribedArticle.articleId}});

      // this.$http.get('/getSubscribeArticle', {
      //   params: {articleId: subscribedArticle.articleId},
      //   headers: {
      //     'Authorization': sessionStorage.getItem("token") // 替换 yourToken 为实际的 token 值
      //   }
      // }).then(res=>{
      //   console.log(res.data);
      //
      // }).catch(err=>{
      //
      // })
    },

    getArticleByTopicId(topic) {
      this.isSubscriptionListVisible = false;
      this.currentTopicName = topic.topicName;
      this.currentTopicDesc = topic.topicDesc;
      this.customArticleId = topic.topicId;

      this.$http.get('/getSubscribeArticles', {
        params: {topicId: this.customArticleId},
        headers: {
          'Authorization': sessionStorage.getItem("token") // 替换 yourToken 为实际的 token 值
        }
      }).then(res => {
        this.subscribedArticleList = res.data.data;
        console.log(this.subscribedArticleList);
      }).catch(err => {
        console.log(err);
      })
    },

    openCreateTopicDrawer() {
      this.addSubscriptionVisible = false;
      this.createTopicDrawVisible = true;
    },

    getTopicList() {
      const config = {
        headers: {
          'Authorization': sessionStorage.getItem("token") // 替换 yourToken 为实际的 token 值
        }
      }

      this.$http.get('/getTopicList', config).then(res => {
        console.log(res.data);
        this.topicList = res.data.topicList;
      }).catch(err => {
        console.error(err);
      })
    },

    addSubscription() {
      this.addSubscriptionFlag = true;

      const config = {
        headers: {
          'Authorization': sessionStorage.getItem("token") // 替换 yourToken 为实际的 token 值
        }
      }

      const requestData = {
        inviteCode: this.invitationCode
      }

      this.$http.post('/addSubscription', requestData, config).then(res => {
        if (res.data.code === 200) {
          console.log(res);
          this.$message.success(this.$t("basic.add_success_text").toString());
          this.addSubscriptionVisible = false;
          this.invitationCode = '';
          // 重新获取订阅信息
          this.getTopicList();
        } else if (res.data.code === 400) {
          this.isCodeValid = false;
        } else if (res.data.code === 500) {
          this.$message.error(this.$t("basic.add_failed_text").toString());
        }else if(res.data.code === 409){
          this.$message.error(this.$t("basic.topic_already_subscribe_text").toString());
        }
      }).catch(err => {
        this.$message.error(this.$t("basic.add_failed_text").toString());
        console.error(err);
      }).finally(() => {
        this.addSubscriptionFlag = false;
      })
    },

    addTopic() {
      this.addTopicFlag = true;

      const config = {
        headers: {
          'Authorization': sessionStorage.getItem("token") // 替换 yourToken 为实际的 token 值
        }
      }

      const requestData = {
        topicName: this.topicName,
        topicDesc: this.topicDesc
      }

      this.$http.post('addTopic', requestData, config).then(res => {
        if (res.data.code === 200) {
          this.$message.success(this.$t("basic.create_success_text").toString());
          this.createTopicDrawVisible = false;
          // 重新获取订阅专题列表
          this.getTopicList();
        }
      }).catch(err => {
        this.$message.error(this.$t("basic.create_failed_text").toString());
      }).finally(() => {
        this.addTopicFlag = false;
      })
    },

    updateUserNickname() {
      if (!this.newNickname) {
        this.illegalNicknameEnterTips = " * " + this.$t("basic.enter_new_nickname_tips").toString();
        return;
      }

      if (this.newNickname === this.nickname) {
        this.illegalNicknameEnterTips = " * " + this.$t("basic.duplicated_nickname_tips").toString();
        return;
      }

      this.updateNicknameFlag = true;

      const requestData = {
        nickname: this.newNickname,
      }

      const config = {
        headers: {
          'Authorization': sessionStorage.getItem("token") // 替换 yourToken 为实际的 token 值
        }
      }

      this.$http.post('/updateUserNickname', requestData, config).then(res => {
        if (res.data.code === 200) {
          this.nickname = this.newNickname;
          sessionStorage.setItem("nickname", this.newNickname);
          this.newNickname = "";
          this.$message.success(this.$t("basic.update_success_text").toString());

          this.updateUserNicknameDialogVisible = false;
        } else if (res.data.code === 400) {
          //   昵称已存在
          this.illegalNicknameEnterTips = " * " + this.$t("basic.nickname_already_exist_text").toString();
        } else {
          this.$message.error(this.$t("basic.update_failed_text").toString());
        }
      }).catch(err => {
        console.error(err);
        this.$message.error(this.$t("basic.update_failed_text").toString());
      }).finally(() => {
        this.updateNicknameFlag = false;
      })
    },

    confirmUpdatePassword() {
      if (this.newPassword !== this.newPasswordConfirm) {
        this.$message.warning(this.$t("basic.passwords_not_match_text").toString());
        return;
      }

      if (this.newPassword === '' || this.newPasswordConfirm === '') {
        this.$message.warning(this.$t("basic.form_filling_err").toString());
        return;
      }

      this.updatePasswordFlag = true;

      const requestData = {
        username: this.username,
        password: this.newPassword,
        tempToken: this.tempToken,
      }

      this.$http.post("/updatePassword", requestData).then(response => {
        if (response.data.code === "UPDATE_SUCCESS") {
          this.$message.success(this.$t("basic.password_update_success_text").toString())
          this.updatePasswordFormVisible = false;
        } else {
          this.$message.error(response.data.code);
        }
      }).catch(error => {
        console.error(error);
      }).finally(() => {
        this.updatePasswordFlag = false;
      })
    },

    deleteArticleConfirm(article) {
      this.confirmDeleteDialogVisible = true;
      this.currentArticleId = article.articleId;
    },

    deleteArticle() {
      this.deleteFlag = true;

      const requestData = {
        articleId: this.currentArticleId
      };

      const config = {
        headers: {
          'Authorization': sessionStorage.getItem("token") // 替换 yourToken 为实际的 token 值
        }
      }

      this.$http.post('/deleteArticleByArticleId', requestData, config).then(res => {
        if (res.data.code === 200) {
          this.$message.success(this.$t("basic.delete_success").toString());
          this.getMyArticle();
        }
      }).catch(err => {
        console.error(err)
      }).finally(() => {
        this.deleteFlag = false;
        this.confirmDeleteDialogVisible = false;
      })
    },

    showArticleManagement() {
      this.isEditArticle = !this.isEditArticle;
    },

    undercarriageArticleConfirm(article) {
      this.undercarriageArticleDialogVisible = true;
      this.currentArticleId = article.articleId;
    },

    undercarriageArticle() {
      this.undercarriageFlag = true;

      const config = {
        headers: {
          'Authorization': sessionStorage.getItem("token") // 替换 yourToken 为实际的 token 值
        }
      }

      const requestData = {
        articleId: this.currentArticleId,
        articleStatus: 3
      }

      this.$http.post('/updateArticle', requestData, config).then(res => {
        if (res.data.code === 200) {
          this.$message.success(this.$t("basic.undercarriage_success_text").toString());
          this.undercarriageArticleDialogVisible = false;
          this.$router.go(0);
        } else {
          this.$message.warning(this.$t("basic.undercarriage_failed_text").toString());
        }
      }).catch(err => {
        console.error(err);
        this.$message.warning(this.$t("basic.undercarriage_failed_text").toString());
      }).finally(() => {
        this.undercarriageFlag = false;
      })
    },

    showMyArticleDetail(article) {
      //  获取文章详细信息
      const articleId = article.articleId;
      // if (article.typeJudge === "") {
      //   //  当前文章状态为草稿，直接跳转到文章编辑界面
      //   this.$router.push({
      //     name: 'EditDraft', params: {
      //       id: String(article.articleId)
      //     }
      //   })
      // } else if (article.typeJudge === "success") {
      //   //  当前文章状态为已发布
      //   this.$router.push({
      //     name:'ArticleDetails',params:{
      //       id:String(article.articleId)
      //     }
      //   })
      // } else if (article.typeJudge === "warning") {
      //   //  当前文章状态为已下架
      // }

      // this.$router.push({name: 'ArticleDetails', params: {
      //   id: String(articleId)
      // }});
      //   先判断是否在文章管理状态
      if (this.isEditArticle) {
        //   点击进入修改文章界面
        this.$router.push({name: 'UpdateArticle', params: {id: articleId}})
      } else {
        //   点击进入文章详情界面
        this.$router.push({name: 'ArticleDetails', params: {id: articleId}})
      }
    },

    getMyArticle() {
      this.articleListLoading = true;
      this.$http.get('getMyArticle', {
        headers: {
          'Authorization': sessionStorage.getItem("token") // 替换 yourToken 为实际的 token 值
        }
      }).then(res => {
        console.log(res)
        this.articleList = res.data.data;

        this.articleList.forEach(article => {
          switch (article.articleStatus) {
            case 1:
              article.articleStatus = this.$t("basic.draft_text");
              article.typeJudge = "";
              break;
            case 2:
              article.articleStatus = this.$t("basic.published_text");
              article.typeJudge = "success";
              break;
            case 3:
              article.articleStatus = this.$t("basic.archived_text");
              article.typeJudge = "warning";
              break;
            default:
              break;
          }
        })
      }).catch(err => {
        console.error(err);
      }).finally(() => {
        this.articleListLoading = false;
      })
    },

    logout() {
      this.$http.get('/user/logout', {
        headers: {
          'Authorization': sessionStorage.getItem("token") // 替换 yourToken 为实际的 token 值
        }
      }).then((response) => {
        if (response.data.code === 200) {
          this.$message.success(this.$t("basic.quit_success_text").toString());
          sessionStorage.clear();
          console.log(sessionStorage.getItem("username"));
          this.$router.push("/home");
        } else {
          this.$message.error(this.$t("basic.quit_failed_text").toString());
        }
      }).catch(error => {
        console.error(error);
      })
    },

    sendEmail() {
      if (this.email === '') {
        this.$message.warning(this.$t("basic.form_filling_err").toString());
        return;
      }

      this.sendEmailFlag = true;

      const requestData = {
        username: this.username,
        email: this.email
      }

      this.$http.post('/sendVerifyCodeEmail', requestData).then(response => {

        console.log(response);
        if (response.data.code === "EMAIL_SEND_SUCCESS") {
          this.isSendEmail = true;
          this.$message.success(this.$t("basic.email_send_success").toString());
        }

      }).catch(err => {
        this.$message.error(this.$t("basic.email_send_err").toString());
        console.error(err);
      }).finally(() => {
        this.sendEmailFlag = false;
      })
      this.isSendEmail = true;
    },

    verifyEmail() {
      //  验证邮箱
      const requestData = {
        username: this.username,
        email: this.email,
        verificationCode: this.verificationCode
      }

      this.$http.post('/verifyCode', requestData).then(response => {
        console.log(response);
        if (response.data.code === "VERIFY_SUCCESS") {
          //  关闭当前dialog，打开更新密码dialog
          this.confirmEmailFormVisible = false;
          this.updatePasswordFormVisible = true;

          this.tempToken = response.data.tempToken;
        } else {
          this.$message.error(response.data.code);
        }
      }).catch(err => {
        console.error(err);
      })
    },

    updatePassword() {

    },

    getUserInfo() {
      this.$http.get('/getUserInfo', {
        headers: {
          'Authorization': sessionStorage.getItem("token") // 替换 yourToken 为实际的 token 值
        }
      }).then(response => {
        console.log(response);
        // this.uuid = response.data.uuid;
        this.email = response.data.email;
        this.activateTime = moment(response.data.activate_time).format('yyyy-MM-DD');
        this.articlePostings = response.data.article_postings;
      }).catch(err => {
        console.error(err)
      }).finally(() => {
        this.loading = false;
      })
    },

    collapseNav() {
      this.isCollapse = !this.isCollapse;
    },

    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },

    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },

    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

  },
}
</script>

<style scoped>
.image-nickname-container {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: x-large;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}

.toggle-button {
  width: 100%;
}

.header-style {
  background-color: #2c3e50;
  color: #42b983;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.personal-page-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 61px);
  min-width: 650px;
  overflow-y: auto;
  overflow-x: hidden;
}

.personal-info-container {
  border: 1px solid #ebebeb;
  border-radius: 15px;
  width: 100%;
  min-width: 640px;
}

.user-operation-container {
  padding: 20px;
  width: 100%;
  text-align: left;
}

.update-info-element-style {
  padding: 9px;
}

.fa-shield {
  color: dodgerblue;
}

.user-image-setting-btn {
  padding-top: 20px;
  padding-left: 30px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.user-info {
  display: flex;
  flex-direction: row;
}

.info-box {
  display: flex;
  flex-direction: row;
  padding: 10px;
  align-items: center;
  border-right: #eaeaea solid 2px;
  margin-bottom: 10px;
}

.info-label {
  font-size: small;
}

.info-context {
  font-size: large;
  font-weight: bold;
  padding: 0 5px;
}

.settings-btn-container {
  padding: 0 50px;
}

.settings-btn {
  margin-left: 15px;
  border-radius: 15px;
}

.all-article-container {
  height: calc(100vh - 60px);
  width: 100vw;
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}

.article-list {
  width: 50%;
  min-width: 780px;
  overflow-y: auto;
}

.article-list-container {
  margin-top: 10px;
  width: 100%;
}

.article-item-container {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.article-edit-btn {
  margin-left: auto;

}

.update-nickname-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.article-title-desc-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  font-weight: bold;
  font-size: large;
}

.subscriptions-container {
  width: 50%;
  overflow-y: auto;
  min-width: 780px;
}

.subscription-list {
  width: 50%;
  min-width: 780px;
  overflow-y: auto;
}

.subscription-list-container {
  margin-top: 10px;
  width: 100%;
  cursor: pointer;
}

.create-topic-form {
  padding: 0 20px
}

.subscribe-article-list {
  min-width: 780px;
}

.subscribe-article-list-container {
  margin-top: 10px;

}
</style>
