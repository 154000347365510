<template>
  <LayoutContainer>
    <template v-slot:my-header>
      <div>
        <el-dropdown trigger="click">
              <span class="el-dropdown-link" style="cursor: pointer;color:#42b983;font-weight: bold">
                {{ $t("basic.current_account") }}{{ username }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="signOut">{{ $t("basic.quit_account") }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </template>
    <div>
      <div class="customize-page-container">
        <h1 style="font-size: 20px">{{$t("basic.jump_to_customize")}}</h1>
        <el-descriptions class="customize-info-container" title="组合管理"
                         direction="vertical" :column="2" v-loading="">
          <el-descriptions-item label="我的组合">
            <el-tag size="small" v-for="(code,index) in customizedCodes" :key="index" style="margin-right: 10px">{{code}}</el-tag>
          </el-descriptions-item>

        </el-descriptions>
      </div>
    </div>
    <el-dialog :visible.sync="customizePortfolioVisible" :title="$t('basic.customize_portfolio')"
               style="overflow-y: hidden">
      <el-row>
        <span>已添加证券：</span>
        <el-tag v-for="(codeMsg,codeIndex) in customizeCodes" :key="codeIndex" style="margin-right: 5px" closable>
          {{ codeMsg.code }}
        </el-tag>
      </el-row>
      <div style="overflow-y: auto;height: 300px">
        <div>
          <div style="margin: 10px 0">
            <span style="margin-right: 10px" v-model="customizeCode">名称</span>
            <el-input style="width: 200px"></el-input>
          </div>

          <div v-for="index in dateAndValueCount" :key="index" style="margin-bottom: 10px;display: flex;">
            <span style="margin-right: 10px">日期</span>
            <el-date-picker type="date">
            </el-date-picker>

            <span style="margin: 0 10px">收盘价（净值）</span>
            <el-input style="width: 200px"></el-input>

            <div v-if="index === dateAndValueCount" style="display: flex;font-size: 50px;color: #cccccc">
              <i class="el-icon-circle-plus-outline" @click="plusDateAndValueCount"></i>
            </div>
          </div>
        </div>

        <el-button>添加至组合</el-button>

      </div>
      <span slot="footer" class="dialog-footer">
              <el-button>{{ $t("basic.cancel_button_text") }}</el-button>
              <el-button type="primary">{{ $t("basic.confirm_button_text") }}</el-button>
          </span>

    </el-dialog>
  </LayoutContainer>




</template>

<script>
import moment from "moment";
import LayoutContainer from "@/components/layout/LayoutContainer";

export default {
  name: "Customize",
  components: {LayoutContainer},
  data(){
    return{
      customizePortfolioVisible: false,
      isCollapse: true,
      loading:true,

      customizeCode: '',
      dateAndValueCount: 5,

      customizeCodes: [
        {code: 'googl', dates: [1, 2, 3], value: [3, 2, 1]}
      ],

      customizedCodes:['KALKA','GOOGLE','GREENWOODS'],
      username:sessionStorage.getItem("username"),
    }
  },

  created(){
  },

  methods:{
    plusDateAndValueCount() {
      this.dateAndValueCount += 1;
    },

    collapseNav(){
      this.isCollapse = !this.isCollapse;
    },

    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },


    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },

    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    signOut() {
      sessionStorage.setItem("username", '');
      sessionStorage.setItem("isLoggedIn", '');
      this.$message.success(this.$t("basic.sign_out_success").toString());
      this.$router.push('/login');
    },
  },
}
</script>

<style scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}

.toggle-button{
  width: 100%;
}

.header-style{
  background-color: #2c3e50;
  color: #42b983;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.customize-page-container {
  /*border: 1px solid #ebebeb;*/
  display: flex;
  flex-direction: column;
  width: 51%;
  min-width: 650px;
}

.customize-info-container {
  border: 1px solid #ebebeb;
  width: 50%;
  min-width: 640px;
  padding: 20px 20px 50px;
}
</style>
