<template>

  <div class="login-wrap" style="">
    <h1 class="sys-title">高方德基金组合优化系统</h1>
    <h3 style="color: gold;text-shadow: 16px 16px 32px rgba(0, 0, 0, 0.5);font-weight: bolder;font-size: 35px;text-align: center">
      GaoFund
      Portfolio Optimizer</h3>
    <el-form class="login-container">
      <div style="display: flex;flex-direction: column;align-items: flex-end">
        <h1 class="title" style="">{{ $t("basic.login_title") }}</h1>
        <el-select v-model="language" size="mini" @change="changeLang" placeholder="Language"
                   style="vertical-align: middle; width: 100px; margin-right: 20px;">
          <el-option label="简体中文" value="zh"></el-option>
          <el-option label="English" value="en"></el-option>
        </el-select>
      </div>
      <el-form-item :label="$t('basic.username')">
        <el-input type="text" v-model="uname" :placeholder="$t('basic.username_enter_tips')"
                  autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item :label="$t('basic.password')">
        <el-input type="password" v-model="password" :placeholder="$t('basic.password_enter_tips')" autocomplete="off"
                  show-password
                  v-on:keyup.native.enter="dosubmit"></el-input>
      </el-form-item>

      <el-form-item>
        <div>
          <el-checkbox v-model="isReadDisclaimer"></el-checkbox>
          <el-button type="text" size="small" style="margin-left: 5px" @click="disclaimerDialogVisible = true">
            {{ $t("basic.read_and_agree_disclaimer_text") }}
          </el-button>
        </div>
        <el-button v-loading="loginFlag" type="primary" style="width: 100%;" @click="dosubmit()"
                   :disabled="!isReadDisclaimer">{{ $t("basic.login_button") }}
        </el-button>

        <div style="display: flex;justify-content: space-between">
          <span class="registerButton" @click="toRegister">{{ $t("basic.sign_up_tips") }}</span>
          <span class="registerButton" @click="confirmEmailFormVisible = true">{{ $t("basic.forget_password") }}</span>
        </div>

      </el-form-item>
    </el-form>

    <!--            邮箱验证-->
    <el-dialog :title="$t('basic.update_password')"
               :visible.sync="confirmEmailFormVisible" width="400px"
               v-loading="verifyCodeFlag"
               :close-on-click-modal="false"
               :append-to-body="true"
    >
      <div>
        <div style="display: flex;flex-direction: column;align-items: center">
          <i class="fa fa-shield fa-5x"></i>
          <span style="font-size: small;color: #949797">{{ $t("basic.verify_email_tips") }}</span>
        </div>

        <div class="update-info-element-style">
          <span>{{$t("basic.username")}}</span>
          <el-input v-model="uname" ></el-input>
        </div>

        <div class="update-info-element-style">
          <span>{{ $t('basic.email') }}</span>
          <div style="display: flex">
            <el-input type="text" v-model="email" :placeholder="$t('basic.email_enter_tips')"></el-input>
            <el-button v-loading="sendEmailFlag" style="margin-left: 25px;color: dodgerblue;font-weight: bold"
                       @click="sendEmail">{{ $t("basic.get_verification_code_button") }}
            </el-button>
          </div>
        </div>

        <div class="update-info-element-style">
          <span>{{ $t('basic.verification_code') }}</span>
          <el-input v-model="verificationCode" v-on:keyup.native.enter="verifyEmail"></el-input>
        </div>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button @click="confirmEmailFormVisible = false">{{ $t("basic.cancel_button_text") }}</el-button>
        <!--              点击确定需要验证验证码是否正确，正确则跳转到更新密码界面-->
        <el-button type="primary" @click="verifyEmail" :disabled="!isSendEmail">{{
            $t("basic.confirm_button_text")
          }}
        </el-button>
      </div>
    </el-dialog>

    <!--          更新密码-->
    <el-dialog :title="$t('basic.update_password')"
               :visible.sync="updatePasswordFormVisible" width="400px"
               v-loading="updatePasswordFlag"
               :close-on-click-modal="false"
               :append-to-body="true"
    >
      <div>
        <div class="update-info-element-style">
          <span>{{ $t('basic.new_password') }}</span>
          <el-input v-model="newPassword" type="password" show-password></el-input>
        </div>
        <div class="update-info-element-style">
          <span>{{ $t('basic.new_password_confirm') }}</span>
          <el-input v-model="newPasswordConfirm" type="password" show-password v-on:keyup.native.enter="confirmUpdatePassword"></el-input>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="updatePasswordFormVisible = false">{{ $t("basic.cancel_button_text") }}</el-button>
        <el-button type="primary" @click="confirmUpdatePassword" :disabled="!isSendEmail">
          {{ $t("basic.confirm_button_text") }}
        </el-button>
      </div>
    </el-dialog>

<!--    免责声明-->
    <el-dialog :title="$t('basic.disclaimer_text')" :visible.sync="disclaimerDialogVisible" append-to-body
               width="450px">
      <div class="disclaimer-content">
        <span>{{ $t("basic.disclaimer_content") }}</span>
      </div>
      <div slot="footer" style="display: flex;justify-content: center">
        <el-button type="primary" @click="disclaimerDialogVisible = false,isReadDisclaimer = true">
          {{ $t("basic.agree_text") }}
        </el-button>
        <el-button @click="disclaimerDialogVisible = false,isReadDisclaimer = false">{{
            $t("basic.disagree_text")
          }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
import qs from "qs";

export default {
  name: 'login',
  data: function () {
    return {
      updatePasswordFlag: false,
      verifyCodeFlag: false,
      loading: false,
      loginFlag: false,
      confirmEmailFormVisible: false,
      updatePasswordFormVisible: false,
      sendEmailFlag: false,
      isSendEmail: false,
      disclaimerDialogVisible: false,
      isReadDisclaimer: false,

      uname: "",
      password: "",
      email: '',
      newPassword: '',
      newPasswordConfirm: '',
      verificationCode: '',

      language: sessionStorage.getItem("theLanguage"),
    }
  },
  created() {
    if (sessionStorage.getItem("theLanguage") === null) {
      // this.language = "zh";
    }
  },

  methods: {
    confirmUpdatePassword() {
      if (this.newPassword !== this.newPasswordConfirm) {
        this.$message.warning(this.$t("basic.passwords_not_match_text").toString());
        return;
      }

      this.updatePasswordFlag = true;

      const requestData = {
        username: this.uname,
        password: this.newPassword,
        tempToken: this.tempToken,
      }

      this.$http.post("/updatePassword", requestData).then(response => {
        if (response.data.code === "UPDATE_SUCCESS") {
          this.$message.success(this.$t("basic.password_update_success_text").toString())
          this.updatePasswordFormVisible = false;
        } else {
          this.$message.error(response.data.code);
        }
      }).catch(error => {
        console.error(error);
      }).finally(() => {
        this.updatePasswordFlag = false;
      })
    },

    sendEmail() {
      if(this.email === '' || this.uname === ''){
        this.$message.warning(this.$t("basic.form_filling_err").toString());
        return;
      }

      this.sendEmailFlag = true;

      const requestData = {
        username: this.uname,
        email: this.email
      }

      this.$http.post('/sendVerifyCodeEmail', requestData).then(response => {

        console.log(response);
        if (response.data.code === "EMAIL_SEND_SUCCESS") {
          this.isSendEmail = true;
          this.$message.success(this.$t("basic.email_send_success").toString());
        }

      }).catch(err => {
        this.$message.error(this.$t("basic.email_send_err").toString());
        console.error(err);
      }).finally(() => {
        this.sendEmailFlag = false;
      })


      this.isSendEmail = true;

    },

    verifyEmail() {
      //  验证邮箱
      const requestData = {
        username: this.uname,
        email: this.email,
        verificationCode: this.verificationCode
      }

      this.$http.post('/verifyCode', requestData).then(response => {
        console.log(response);
        if (response.data.code === "VERIFY_SUCCESS") {
          //  关闭当前dialog，打开更新密码dialog
          this.confirmEmailFormVisible = false;
          this.updatePasswordFormVisible = true;

          this.tempToken = response.data.tempToken;
        } else {
          this.$message.error(response.data.code);
        }
      }).catch(err => {
        console.error(err);
      })
    },

    changeLang() {
      sessionStorage.setItem("theLanguage", this.language);
      this.$router.go(0); //刷新当前页面
    },

    toRegister() {
      this.$router.push('/register')
    },
    dosubmit: function () {
      if (!this.uname || !this.password) {
        this.$message.warning(this.$t("basic.no_username_or_password_err").toString());
        return;
      } else if (!this.isReadDisclaimer) {
        this.$message.warning(this.$t("basic.read_disclaimer_text").toString());
        return;
      } else {
        this.loginFlag = true;

        const userData = {
          username: this.uname,
          password: this.password
        };

        this.$http.post('/user/login', userData
            // this.$http.post('/userLogin',userData
        ).then(response => {
          if (response.data.code === 200) {
            //登录成功
            // 返回上一个界面
            this.$router.go(-1);
            console.log(response);
            sessionStorage.setItem("isLoggedIn", "yes");
            sessionStorage.setItem("username", response.data.username);
            sessionStorage.setItem("nickname", response.data.nickname);
            sessionStorage.setItem("userImage", response.data.userImage);
            sessionStorage.setItem("token", response.data.token);
            sessionStorage.setItem("isShowMenu", response.data.isShowMenu);
            // alert("登录成功")
            this.$message({
              type: 'success',
              message: this.$t("basic.login_success").toString()
            })
            this.$router.push('/home')
          } else {
            //登陆失败
            console.log(response.data)
            if (response.data.code === 'NO_EXIST_ERR') {
              this.$message.error(this.$t("basic.account_not_exist").toString())
            } else if (response.data.code === 'LOGIN_ERR') {
              this.$message.error(this.$t("basic.incorrect_username_or_password").toString())
            } else
              this.$message.error(response.data.msg)
          }
        }).catch((error) => {
          console.log(error)
          // alert('账号或密码错误');
          this.$message({
            type: "error",
            message: this.$t("basic.login_failed").toString()
          })
        }).finally(() => {
          this.loginFlag = false;
        })
      }

    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.sys-title {
  text-align: center;
  color: gold;
  font-size: 50px;
  font-weight: bold;
  /*font-size: 30px;*/
  /*margin-left: 43%;*/
  margin-top: 0;
  text-shadow: 8px 8px 16px rgba(0, 0, 0, 0.5); /* 可根据需要调整阴影的偏移和模糊程度 */
}

.login-wrap {
  width: 100%;
  height: 100vh;

  background-image: url('../bg.jpeg');
  background-size: 100% 100%;
  position: fixed;

  /*
   background-position: center right;
  background-size: 100%; */
}

.login-container {
  border-radius: 10px;
  margin: 0px auto;
  width: 350px;
  padding: 30px 35px 15px 35px;
  background: #fff;
  border: 1px solid #eaeaea;
  text-align: left;
  box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.1);
}

.title {
  margin: 0px auto 40px auto;
  text-align: center;
  color: #505458;
}

.registerButton {
  margin-top: 1%;
  cursor: pointer;
  user-select: none;
  font-size: small;
}

/* 鼠标悬停时改变背景颜色 */
.registerButton:hover {
  background-color: lightgray; /* 悬停时的背景颜色 */
}

.update-info-element-style {
  padding: 9px;
}

.fa-shield {
  color: dodgerblue;
}

.disclaimer-content {
  width: 400px;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  line-height: 1.8;
}
</style>
