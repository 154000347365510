export default {
    basic: {
        //登录界面
        login_title: 'Sign In to GaoFund',
        username: 'Username',
        username_enter_tips: 'Enter your username',
        password: 'Password',
        password_enter_tips: 'Enter your password',
        login_button: 'Sign In',
        sign_up_tips: 'New to GaoFund? Create an account',
        forget_password: 'Forget Password',
        no_username_or_password_err: 'Please enter a username and password',
        login_success: 'Welcome to the GaoFund system',
        login_failed: 'Failed to sign in',
        incorrect_username_or_password: 'Incorrect username or password',
        account_not_exist: 'The account does not exist',
        read_and_agree_disclaimer_text: 'Read and agree to the disclaimer',
        disclaimer_text: 'Disclaimer',
        disclaimer_content: 'Disclaimer Agreement: \nThe stock prices are influenced by various factors, ' +
            'and the optimization of Sharpe ratio is merely a recommendation based on the historical data of individual stocks within a portfolio, ' +
            'without predicting the future rise or fall of stock prices. This recommendation is based on a portfolio disclosed by the manager at a specific time on the U.S. ' +
            'Securities and Exchange Commission website and does not represent the current status of that portfolio. ' +
            'The information provided is solely an internal research conducted by the system to assess the applicability of the Sharpe ratio. Errors may occur during the data processing and output. ' +
            '\nThe managers of the portfolio and any other institutions or individuals using this system or referring to any conclusions and recommendations generated by this system do so for learning and research purposes only. ' +
            'Such information should not be considered as a specific basis for their investments. The company and the inventor of this system are not responsible for any consequences arising from the use of this information.',
        agree_text:'Agree',
        disagree_text:'Disagree',
        read_disclaimer_text:'Please Read And Agree To The Disclaimer.',

        //注册界面
        register_title: 'Welcome to GaoFund',
        email: 'Email',
        email_enter_tips: 'Enter your email',
        get_verification_code_button: 'Get Verification Code',
        verification_code: 'Verification Code',
        verification_code_enter_tips: 'Enter the verification code',
        register_button: 'Sign Up',
        sign_in_tips: 'Already have an account? Sign in',
        incomplete_registration_information: 'Please complete the following information',
        register_success: 'Account created successfully',
        register_failed: 'Failed to create an account',
        username_already_exist: 'Username already taken',
        email_no_exist_err: 'The target email address does not exist',
        email_send_success: 'Email sent successfully',
        email_send_err: 'Failed to send the email',
        incorrect_verification_code: 'Incorrect verification code',
        verification_timeout: 'The code has expired. Please reapply',

        //主界面
        system_title: 'GaoFund Portfolio Optimizer',
        login_to_choice: 'Reauthenticate via Choice Interface',
        quit_choice: 'Quit Choice Interface',
        search_record_management: 'Search Record Management',
        current_account: 'Current Account: ',
        quit_account: 'Sign Out',
        language_tips: 'Language',

        //表单信息
        stock_codes: 'Securities Codes',
        stock_codes_enter_tips: 'Enter securities code',
        stock_count: 'Entered codes: ',
        stock_enter_err_tips: 'Input error, please check comma usage',
        portfolio_type: 'Portfolio Type',
        portfolio_type_tips: 'Select the portfolio type',
        date_range: 'Date Range',
        start_date_enter_tips: 'Start Date',
        end_date_enter_tips: 'End Date',
        weight_limit: 'Weight Limit (%)',
        //表单功能信息描述
        input_code_notification: '* System doesn\'t currently support cross market combination calculation.',
        chinese_mutual_fund_only_text:'* Chinese Mutual Fund Only.',
        input_code_description: 'Enter securities codes of the same currency. If there are securities with different portfolio types selected, please ensure that the security code is accompanied by suffix information.',
        portfolio_type_description: 'Select the type to which the portfolio belongs.',
        date_range_description: 'Select the date range for which this portfolio needs to be calculated (you can use shortcuts on the left side of the calendar to quickly fill in the date range).',
        weight_limit_description: "Drag the slider or directly enter a number to specify the weight limit.",
        date_period_description: 'Select the date period for calculating data in the portfolio.',
        adjustment_description: 'Select the adjustment method for calculating data in the portfolio.',
        //日期快捷键
        five_years_prev_trading: '5 Years Ago (Prev Trading)',
        three_years_prev_trading: '3 Years Ago (Prev Trading)',
        one_year_prev_trading: '1 Year Ago (Prev Trading)',
        three_years_prev_month: '3 Years Ago (Prev Month)',
        one_year_prev_month: '1 Year Ago (Prev Month)',
        entire_five_years: 'Last 5 Years',
        entire_three_years: 'Last 3 Years',
        entire_one_year: 'Last Year',
        year_to_date: 'Year-to-Date',
        //组合类型
        portfolio_type_stock: 'Stock',
        portfolio_type_bond: 'Bond',
        portfolio_type_fund: 'Fund',
        portfolio_type_index: 'Index',
        //收盘价
        date_period: 'Date Period',
        daily_closing_price: 'Daily Closing Price',
        weekly_closing_price: 'Weekly Closing Price',
        monthly_closing_price: 'Monthly Closing Price',
        yearly_closing_price: 'Yearly Closing Price',
        //复权方式
        adjustment: 'Adjustment Method',
        forward_adjustment: 'Forward Adjustment',
        backward_adjustment: 'Backward Adjustment',
        no_adjustment: 'No Adjustment',
        //币种
        currency: 'Currency',
        original_currency: 'Original Currency',
        RMB: 'RMB',
        HKD: 'HKD',
        USD: 'USD',

        //市场类型
        market: 'market',
        CNSESH: 'Shanghai Stock Exchange',
        CNSESZ: 'Shenzhen Stock Exchange',
        HKSE00: 'Hong Kong Stock Exchange',
        USSE00: 'U.S. Securities Exchange',
        USSEND: 'U.S. NASDAQ Market',
        USSENY: 'New York Stock Exchange',
        CNFEBC: 'Bohai Commodity Exchange',
        CNFEDC: 'Dalian Commodity Exchange',
        CNFESF: 'Shanghai Futures Exchange',
        CNFEZC: 'Zhengzhou Commodity Exchange',
        INE000: 'Shanghai International Energy Exchange',
        CNGCSH: 'Shanghai Gold Exchange',
        HKME00: 'Hong Kong Mercantile Exchange',
        nature_day: 'Calendar Day',
        all_market_trading_days: 'All Market Trading Days',
        CNSH00: 'Shanghai-Hong Kong Stock Connect Trading Day',
        CNSHHK: 'Shanghai-Hong Kong Stock Connect Trading Day',
        CNSZ00: 'Shenzhen-Hong Kong Stock Connect Trading Day',
        CNSZHK: 'Shenzhen-Hong Kong Stock Connect Trading Day',
        NYMEX0: 'New York Mercantile Exchange',
        USFENY: 'U.S. Commodity Exchange',
        CME000: 'Chicago Mercantile Exchange',
        LDMETL: 'London Metal Exchange',
        LDEXCH: 'London Stock Exchange',
        SGSE00: 'Singapore Exchange',

        //按钮
        cal_weight_button: 'Calculate Optimized Weights',
        calculating_button: 'Calculating...',
        clear_all_button: 'Clear Data',
        one_click_generation_button: 'One-Click Generation',
        one_click_tips: '\n' +
            'One-click Generate Sharpe Ratios and Optimized Weights for Specified Date\'s Previous One Year, Three Years, and Five Years',
        wait_a_minute_tips: 'Loading...',
        customize_portfolio: 'Customize Portfolio',
        operation_guidance: 'Operation Guidance',
        generate_portfolio_sharpe_button: 'calculate Sharpe Ratios',
        portfolio_sharpe_list: 'Individual Stocks Sharpe Ratio List',
        security_listing_date: 'Listing Date',
        security_sharpe_since_listing: 'Since Listing',
        security_five_year_sharpe: 'Five-Year',
        security_three_year_sharpe: 'Three-Year',
        security_one_year_sharpe: 'One-Year',
        menu_text: 'MENU',

        //数据区域
        system_recommended_weight_allocation:'Optimized Weights',
        mean_weighted_interval_total_return: 'Mean with Total',
        weighted_return: 'Mean Return',
        weighted_volatility: 'Mean Annualized Volatility',
        weighted_sharpe: 'Mean Sharpe Ratio',
        weighted_interval_total_return: 'Optimize with Total',
        weighted_return_with_premium: 'Weighted Return',
        weighted_volatility_with_premium: 'Weighted Annualized Volatility',
        sharpe_with_premium: 'Weighted Sharpe Ratio',
        asc_sort_button:'ASC',
        desc_sort_button:'DESC',

        //表格区域
        table_stock: 'Stock Code',
        table_weight: 'Weight Allocation',
        cover_table: 'Select a Table to Cover',
        cover_table1: 'Table 1',
        cover_table2: 'Table 2',
        cover_table3: 'Table 3',
        cover_table4: 'Table 4',
        cover_table5: 'Table 5',
        cover_table_button: 'Confirm',

        //回测功能区域
        compare_title: 'Mean-Weighted vs. Premium-Weighted Return Backtest Comparison',
        choose_table: 'Select Data Table',
        choose_table_tips: 'Please select a table',
        base_on_table1: 'Calculate Portfolio Return based on Table 1 Data',
        base_on_table2: 'Calculate Portfolio Return based on Table 2 Data',
        base_on_table3: 'Calculate Portfolio Return based on Table 3 Data',
        base_on_table4: 'Calculate Portfolio Return based on Table 4 Data',
        base_on_table5: 'Calculate Portfolio Return based on Table 5 Data',
        choose_new_date: 'Select New Date',
        choose_start_date_tips: 'Start Date',
        choose_end_date_tips: 'End Date',
        choose_date_notice: 'Note: Please consider the closing price time for the selected date. If it is before the market closes, adjust the end date one day earlier.',
        no_weight_compare_button: 'Calculate Weights First',
        compare_button: 'Calculate',
        weight_limit_tips: 'Weight Limit: ',
        compare_date: 'Date',
        compare_mean_weighted_return: 'Mean-Weighted Return',
        compare_weighted_return: 'Weighted Return',
        //回测功能区域描述
        table_choose_description: 'Select weight data from a table for backtesting.',
        new_date_range_description: "Choose the date range for the backtesting period.",
        customize_weight: 'Customize weights',
        remain_weight: 'Remaining assignable weights',
        reset_text: 'Reset',
        portfolio_yield_detail: 'Portfolio Yield Details',
        total_returns: 'Returns',
        total_weighted_returns: 'After-weight Returns',
        view_details: 'View Details',
        stock_yield_detail: 'Individual Stock Yield Details',
        date_text: 'Date',
        actual_returns: 'Actual Returns (%)',
        after_weight_returns: 'Returns After Weighting (%)',
        custom_weight_returns_title:'Custom Weighted returns',
        custom_weight_volatility_title:'Custom Weighted Annualized Volatility',
        custom_weight_sharpe_ratio_title:'Custom Weighted Sharpe Ratio',


        //弹窗提示消息
        login_choice_success: 'Login successful',
        sign_out_success: 'Logout successful',
        form_filling_err: 'Please fill in all required fields',
        date_filling_err: 'Incorrect date format. Please re-enter',
        no_data_err: 'Some securities have no data: ',
        unrecognized_code_err: ' is not recognized. Please add suffix or check spelling',
        no_login_err: 'Session expired! Please log in to the Choice interface again',
        code_invalid_err: 'Invalid stock code entered',
        no_data_msg: 'No data available',
        cal_success: 'Calculation completed',
        form_filling_except_date_err: 'Please complete all fields except the date',
        date_and_table_data_err: 'Select the time period for calculation and choose table data',

        //侧边栏
        jump_to_home: 'Home',
        jump_to_kdj: 'KDJ Index',
        jump_to_bookmarks: 'Bookmark',
        jump_to_customize: 'Customize',
        jump_to_record: 'Record',
        jump_to_settings: 'Settings',
        jump_to_user: 'Personal',
        jump_to_help: 'F&Q',

        //收藏界面
        portfolio_compare_title: 'Compare Portfolio Data',
        bookmark_name: 'Bookmark Name',
        bookmark_name_tips: 'Enter the bookmark name',
        bookmark_content: 'Bookmark Content',
        bookmark_date: 'Bookmark Date',
        operation: 'Operation',
        add_bookmark_button_text: 'Add Portfolio',
        add_bookmark_text: 'Add Bookmark',
        add_text: 'Add',
        view_portfolio_changes_button: 'View',
        add_bookmark_title: 'Add Portfolio Bookmark',
        enter_code_tips: 'Enter relevant security codes',
        available_options_list: 'Available',
        selected_options_list: 'Selected',
        delete_button_text: 'Delete',
        add_success_text: 'Added successfully',
        add_failed_text: 'Failed to add',
        recalculate_button_text: 'Recalculate',
        generate_report: 'Generate Report',
        portfolio_performance_changes: 'Portfolio Performance Changes',
        mean_with_total_change: 'Mean Total Return Change',
        mean_return_change: 'Mean Annualized Return Change',
        mean_annualized_volatility_change: 'Mean Annualized Volatility Change',
        mean_sharpe_change: 'Mean Sharpe Ratio Change',
        optimize_with_total_change: 'Optimized Total Return Change',
        weighted_return_change: 'Weighted Annualized Return Change',
        weighted_annualized_volatility_change: 'Weighted Annualized Volatility Change',
        weighted_sharpe_change: 'Weighted Sharpe Ratio Change',
        weight_allocation_change: 'Weight Allocation Changes',
        weight_increase_code: 'Securities with Increased Weight:',
        weight_decrease_code: 'Securities with Decreased Weight:',
        KDJ_index_performance: 'Performance of KDJ Index',
        K_index_performance: 'Performance of K Value',
        D_index_performance: 'Performance of D Value',
        J_index_performance: 'Performance of J Value',
        J_value_daily: 'J Value Daily',
        J_value_weekly: 'J Value Weekly',
        J_value_monthly: 'J Value Monthly',

        //搜索记录管理界面
        record_management_title: 'Search Record Manage',
        create_time: 'Date',
        record_content: 'Record Content',
        delete_button: 'Delete',
        //弹窗提示信息
        no_record_choose_err: 'Please select the records to delete',
        delete_confirm: 'Are you sure you want to delete these records?',
        delete_confirm_title: 'Confirmation',
        confirm_button_text: 'Confirm',
        cancel_button_text: 'Cancel',
        delete_success: 'Deletion successful',
        delete_failed: 'Deletion failed',
        delete_cancel: 'Deletion canceled',

        //    用户中心界面
        personal_information: 'Personal Account Information',
        personal_id: 'ID',
        activate_time: 'Registration Time',
        update_password: 'Update Password',
        verify_email_tips: 'Verify email to ensure account security',
        new_password: 'New Password',
        new_password_confirm: 'Confirm Password',

        //    引导内容
        back_text: 'Back',
        next_text: 'Next',
        skip_text: 'Skip',
        done_text: 'Finish',
        code_input_text: 'Security Code Input',
        code_input_description: 'Enter stock codes of the same trading currency, separated by commas. For example: BABA, GOOGL, NVDA.<br>If the portfolio includes indices, ensure index codes have the proper suffix. For instance, Hang Seng Index (HSI) should be written as HSI.HI.',

        portfolio_type_choose_text: 'Portfolio Type Selection',
        portfolio_type_choose_description: 'Choose between stock and fund portfolios. For stock portfolios, it enables the combination of stocks and indices; for fund portfolios, it facilitates the combination of domestic Chinese funds.',

        date_range_choose_text: 'Date Range Selection',
        date_range_choose_description: 'The system provides optimization suggestions based on historical data. Customize the time period for historical data selection.',

        weight_limit_text: 'Weight Limit',
        weight_limit_text_description: 'Set weight limits for each stock. Ensure the product of the number of stocks and the weight limit is equal to or greater than 100%.',

        backtesting_text: 'Backtesting Area',
        backtesting_description: 'After calculating the system\'s optimization suggestions for the portfolio above, perform backtesting on the suggested optimization for the current returns.',

        data_and_date_range_choose_text: 'Data and Date Range Selection',
        data_and_date_range_choose_description: '1. Choose one set of weight allocation suggestions from the top five tables for backtesting decisions.<br>2. Select the portfolio to backtest and observe the period of returns.',

        customize_weight_text: 'Customize Weight',
        customize_weight_description: 'Customize the weight distribution of each stock in the portfolio. Ensure there is an existing portfolio in the security code input and at least one optimization calculation is completed before backtesting with custom weights.',

        view_yield_detail_text: 'View Yield Details',
        view_yield_detail_description: 'Click on the magnifying glass icon to view the actual returns, post-weight returns, and monthly returns details for each stock in the portfolio.',

        reboot_text: 'Reboot',
        reboot_description: 'Click here to restart the guided process.',

        // 文章区域
        enter_article_title_tips: 'Please enter a title (5-100 characters)',
        enter_article_content_tips: 'Please enter article content',
        article_source_text: 'Article Source',
        original_text: 'Original',
        repost_text: 'Repost',
        translate_text: 'Translation',
        article_topic_text: 'Article Topic',
        market_info_text: 'Market Information',
        research_report_text: 'Research Report',
        perspectives_and_interviews_text: 'Perspectives and Interviews',
        publish_text: 'Publish Article',
        topic_content_empty_error: 'Please complete the article topic or content',
        publish_success_text: 'Publish Successful',
        more_choice_text: 'More',
        unknown_text: 'Unknown',
        browsing_history_text: 'Browsing History',
        my_article_text: 'My Articles',
        my_bookmark_text: 'My Bookmarks',
        published_text: 'Published',
        draft_text: 'Draft',
        archived_text: 'Archived',
        published_on_text: 'Published on',
        confirm_save_draft_text: 'Save as draft?',
        yes_text: 'Yes',
        discard_text: 'No',
        save_success_text: 'Save Successful',
        save_failed_text: 'Save Failed',
        update_article_text: 'Update Article',
        confirm_quit_update_text: 'Are you sure you want to quit editing?',
        publish_failed_text: 'Publish Failed',
        confirm_delete_text: 'Are you sure you want to delete this article?',
        quit_success_text: 'Exit Successful',
        quit_failed_text: 'Exit Failed',
        manage_article_text: 'Manage Article',
        quit_manage_text: 'Exit Management',
        undercarriage_text: 'Unpublish',
        edit_text: 'Edit',
        confirm_undercarriage_text: 'Are you sure you want to unpublish this article?',
        undercarriage_success_text: 'Unpublish Successful',
        undercarriage_failed_text: 'Unpublish Failed',
        update_nickname_text: 'Update Nickname',
        new_nickname_text: 'New Nickname',
        enter_new_nickname_tips: 'Please enter a new nickname',
        submit_text: 'Submit',
        duplicated_nickname_tips: 'New nickname cannot be the same as the old one',
        update_success_text: 'Update Successful',
        update_failed_text: 'Update Failed',
        nickname_already_exist_text: 'Nickname already exists, please enter another',
        comment_section_text: 'Comment Section',
        enter_comment_tips: 'Share your thoughts on this article!',
        publish_btn_text: 'Publish',
        delete_comment_confirm_text: 'Are you sure you want to delete this comment?',
        reply_text: 'Reply',
        commentable_after_login: 'Log in to comment',
        total_reply_count_text: 'Total Replies:',
        check_all_text: 'Check all',

        click_to_comment_text: 'Click to comment',
        transmit_text: 'Share',
        like_text: 'Like',
        like_failed_text: 'Like failed',
        copy_url_tips: 'Article link copied to clipboard',
        copy_url_error_tips: 'Failed to get article link',
        content_text: 'Content',
        my_subscriptions_text: 'My subscriptions',
        add_subscription_text: 'Add subscription',
        topic_already_subscribe_text: 'Already subscribed to this topic, cannot subscribe again',
        invitation_code_text: 'Invitation code',
        enter_invitation_code_text: 'Please enter topic invitation code',
        invalid_invitation_code_text: '* Invalid invitation code',
        create_topic_text: 'Create topic',
        topic_name_text: 'Topic name',
        enter_topic_name_text: 'Please enter topic name (required)',
        topic_desc_text: 'Topic description',
        enter_topic_desc_text: 'Please enter topic description (required)',
        create_success_text: 'Creation successful',
        create_failed_text: 'Creation failed',
        publish_article_to_managed_topic: 'Publish to topics I manage',
        my_topic_text: 'My topics',
        user_interface: 'User interface',
        unsubscribe_text: 'Unsubscribe',
        manage_topic_text: 'Manage topics',
        unsubscribed_topic_text: 'Unsubscribed from topic:',
        confirm_unsubscribe_text: 'Are you sure you want to unsubscribe from this topic?',
        unsubscribe_success_text: 'Unsubscription successful',
        unsubscribe_failed_text: 'Unsubscription failed',
        get_invitation_code_text: 'Get invitation code',
        copy_invitation_code_success_text: 'Invitation code copied to clipboard',
        copy_invitation_code_failed_text: 'Failed to get invitation code',


    }
}
