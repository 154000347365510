<template>
  <el-container>
    <el-header height="80px" class="header-style">
      <div style="flex: 1;text-align: center">
        <span style="color: #42b983;font-size: 40px;font-weight: bold;">{{ $t("basic.system_title") }}</span>
      </div>

      <slot name="my-header"></slot>

    </el-header>
    <el-container>
      <el-aside width="" style="">
        <el-radio-group v-model="isCollapse" style="width: 100%">
          <el-button class="toggle-button" style="color: #000000" type="" icon="el-icon-s-unfold" v-if="isCollapse"
                     @click="collapseNav"></el-button>
          <el-button class="toggle-button" style="color: #42b983" icon="el-icon-s-fold" v-if="!isCollapse"
                     @click="collapseNav"></el-button>
        </el-radio-group>
        <el-menu router :default-active="$route.path" class="el-menu-vertical-demo" @open="handleOpen"
                 @close="handleClose" :collapse="isCollapse" background-color="" text-color="#000000"
                 active-text-color="#42b983">
          <el-menu-item index="/home" >
            <i class="el-icon-s-home"></i>
            <span slot="title">{{$t("basic.jump_to_home")}}</span>
          </el-menu-item>
          <el-menu-item index="/kdjIndex">
            <i class="el-icon-finished"></i>
            <span slot="title">{{ $t("basic.jump_to_kdj") }}</span>
          </el-menu-item>
          <el-menu-item index="/bookmarks">
            <i class="el-icon-star-on"></i>
            <span slot="title">{{ $t("basic.jump_to_bookmarks") }}</span>
          </el-menu-item>
          <el-menu-item index="/customize">
            <i class="el-icon-edit-outline"></i>
            <span slot="title">{{ $t("basic.jump_to_customize") }}</span>
          </el-menu-item>
          <el-menu-item index="/record" >
            <i class="el-icon-timer"></i>
            <span slot="title">{{$t("basic.jump_to_record")}}</span>
          </el-menu-item>
          <el-menu-item index="/help" >
            <i class="el-icon-help"></i>
            <span slot="title">{{$t("basic.jump_to_help")}}</span>
          </el-menu-item>
          <el-menu-item index="/user" >
            <i class="el-icon-user"></i>
            <span slot="title">{{$t("basic.jump_to_user")}}</span>
          </el-menu-item>
          <el-menu-item index="/settings">
            <i class="el-icon-setting"></i>
            <span slot="title">{{$t("basic.jump_to_settings")}}</span>
          </el-menu-item>

        </el-menu>
      </el-aside>
      <el-main>

        <slot></slot>

      </el-main>
    </el-container>
  </el-container>
</template>

<script>
export default {
  name: "LayoutContainer",

  data(){
    return{
      isCollapse: true,
      username: sessionStorage.getItem("username"),
    }
  },

 methods:{
   signOut() {
     sessionStorage.setItem("username", '');
     sessionStorage.setItem("isLoggedIn", '');
     this.$message.success(this.$t("basic.sign_out_success").toString());
     this.$router.push('/login');
   },

   collapseNav() {
     this.isCollapse = !this.isCollapse;
   },

   handleOpen(key, keyPath) {
     console.log(key, keyPath);
   },
   handleClose(key, keyPath) {
     console.log(key, keyPath);
   },

 }
}
</script>

<style scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}

.toggle-button {
  width: 100%;
}

.header-style {
  background-color: #2c3e50;
  color: #42b983;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

</style>
