<template>
  <div>
    <div class="topic-style">
      <el-menu
          style="width: 70%;min-width: 540px"
          :default-active="activeIndex"
          class=""
          mode="horizontal"
          @select="handleSelect"
          background-color="#545c64"
          text-color="#fff"
          active-text-color="#42b983">
        <el-menu-item index="1" style="">{{$t("basic.market_info_text")}}</el-menu-item>
        <el-menu-item index="2" style="">{{$t("basic.research_report_text")}}</el-menu-item>
        <el-menu-item index="3" style="">{{$t("basic.perspectives_and_interviews_text")}}</el-menu-item>
        <el-menu-item index="6">
          <router-link to="/gaoFundSystem" class="jump-link-style">
            <div>高方德组合优化系统</div>
          </router-link>
        </el-menu-item>
      </el-menu>

      <el-menu
          style="width: 30%;min-width: 345px"
          mode="horizontal"
          background-color="#545c64"
          text-color="#fff"
          active-text-color="#42b983">
        <el-menu-item style="">
          <router-link to="/user"  class="jump-link-style">
            <div>
              <el-image
                  style="width: 45px; height: 45px;border-radius: 50%"
                  :src="imageUrl ? imageUrl : require('../default_image.jpg')"
                  fit="cover"
              ></el-image>

              <span style="margin-left: 5px">{{nickname}}</span>
            </div>
          </router-link>
        </el-menu-item>

        <el-menu-item style="" v-if="isShowMenu === 'true'">
          <el-button class="submit-btn" @click="$router.push('/editArticle')">
            {{$t("basic.publish_text")}}</el-button>
        </el-menu-item>
      </el-menu>
    </div>

    <div class="main-container">

      <el-breadcrumb separator="/" style="padding: 10px 20px">
        <el-breadcrumb-item>{{$t("basic.jump_to_home")}}</el-breadcrumb-item>
        <el-breadcrumb-item>{{currentMenu}}</el-breadcrumb-item>
      </el-breadcrumb>

      <div class="article-list" v-loading="articleListLoading">
        <div class="article-list-container" v-if="articleList.length !== 0">
          <div  class="article-item-container" v-for="(article,index) in articleList" :key="index" @click="showArticleDetail(article.articleId)">

            <mobile-article-card
                :article-title="article.articleTitle"
                :article-brief-content="article.articleBriefContent"
                :release-time="moment(article.releaseTime).format('yyyy-MM-DD HH:mm')"
                :article-like="article.articleLike"
                :article-views="article.articleViews"
                :article-comments="article.articleComments"
            >
            </mobile-article-card>
          </div>
        </div>

        <!--      暂无内容提示-->
        <div v-else style="color: #949797;display: flex;align-items: center;height: 80%;justify-content: center">
          <h1>{{$t("basic.no_data_msg")}}</h1>
        </div>
      </div>
    </div>



  </div>

</template>

<script>
import MobileArticleCard from "@/components/component/MobileArticleCard";
import FloatingMenu from "@/components/menu/FloatingMenu";
import moment from "moment";

export default {
  name: "ComputerHome",
  components: {FloatingMenu, MobileArticleCard},
  data() {
    return {
      articleListLoading:true,
      isShowMenu: sessionStorage.getItem("isShowMenu"),
      imageUrl: sessionStorage.getItem('userImage'),
      username: sessionStorage.getItem('username') || "",
      nickname: sessionStorage.getItem('nickname') || "",
      activeIndex: "",
      currentMenu:"",

      articleList: [],

    }
  },

  created() {
    this.activeIndex = this.$route.params.menuIndex || sessionStorage.getItem("homeActiveIndex") || "1";
    this.getArticlesByParams(this.activeIndex);
    this.moment = moment; // 挂载 moment 到组件实例
  },

  mounted() {
    this.getMenuDesc(this.activeIndex);
  },

  methods: {

    getArticlesByParams(articleTopic){
      this.articleListLoading = true;
      sessionStorage.setItem("homeActiveIndex", articleTopic);

      const requestData = {
        articleTopic: parseInt(articleTopic, 10),
        // 仅获取文章状态为已发布的文章
        articleStatus: 2,
      }

      this.$http.get('/getArticleByParams',{
        params: requestData
      }).then(res=>{
        console.log(res)
        this.articleList = res.data.data;
      }).catch(err=>{
        console.error(err)
      }).finally(()=>{
        this.articleListLoading = false;
      })
    },

    showArticleDetail(articleId) {
      //  获取文章详细信息
      this.$router.push({name: 'ArticleDetails', params: {id: articleId}});
    },

    handleSelect(key, keyPath) {
      // console.log(key, keyPath);
      this.getArticlesByParams(key);

      this.getMenuDesc(key);
    },

    getMenuDesc(key){
      const menuItems = {
        '1': this.$t("basic.market_info_text"),
        '2': this.$t("basic.research_report_text"),
        '3': this.$t("basic.perspectives_and_interviews_text")
      };


      this.currentMenu = menuItems[key];
    },

    truncateText(text, limit) {
      return text.length > limit ? text.slice(0, limit) + "..." : text;
    },
  }
}
</script>

<style scoped>
.main-container{
  height: calc(100vh - 61px);
  width: 100%;
  overflow-y: auto;
}

.topic-style {
  height: 61px;
  width: 100vw;
  display: flex;
  flex-wrap: nowrap;
  /*overflow-x: auto; !* 水平方向超出部分显示滚动条 *!*/
  /*-webkit-overflow-scrolling: touch; !* 兼容iOS的滚动效果 *!*/
}

.article-list {
  display: flex;
  justify-content: center;
  height: calc(100vh - 60px);
  width: 70vw;
}

.article-list-container {
  margin-top: 10px;
  width: 100%;
}

.article-item-container {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center
}

.submit-btn{
  background: #42b983;
  color: white;
  font-weight: bold;
  border-radius: 10px;
}

.jump-link-style{
  text-decoration: none;
}
</style>
