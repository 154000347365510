<template>
  <div class="page-container">

    <ComputerArticleNavMenu></ComputerArticleNavMenu>

    <div style="width:100%;" v-show="!articleLoading">
      <el-breadcrumb separator="/" style="padding: 10px 20px;">
        <el-breadcrumb-item :to="{path:'/'}">{{ $t("basic.jump_to_home") }}</el-breadcrumb-item>
        <el-breadcrumb-item @click.native="navigateToCurrentMenu" style="cursor: pointer;font-weight: bolder">
          {{ currentMenu }}
        </el-breadcrumb-item>
        <el-breadcrumb-item>{{ $t("basic.content_text") }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>


    <div class="under-navigate-container">
      <div class="article-container" v-loading="articleLoading">
        <div class="article-title">
          <span>{{ articleTitle }}</span>
        </div>

        <div class="author-container">
          <el-image
              style="width: 50px; height: 50px;border-radius: 50%"
              :src="authorImageUrl"
              fit="cover"
          ></el-image>

          <div style="display: flex;flex-direction: column;align-items: flex-start">
            <div style="display: flex;flex-direction: row;align-items: center">
              <span style="padding: 10px 10px">{{ authorName }}</span>
              <el-tag :type="typeJudge" size="small">{{ articleSource }}</el-tag>
            </div>

            <span
                style="font-weight: normal;font-size: 12px;padding: 5px 10px">{{
                $t('basic.published_on_text') + ' ' + releaseTime
              }}</span>
          </div>
        </div>

        <div class="article-content-container">
          <!--            <span>{{ articleContent }}</span>-->
          <div v-html="contentWithStyle" class="ql-editor" v-viewer></div>
        </div>

        <div class="comment-section-container">
          <div class="comment-title">
            <h2 style="margin: 20px 5px 20px 20px">{{ $t("basic.comment_section_text") }}</h2>
            <span style="font-size: large;margin-left: 10px">{{ articleComments }}</span>
          </div>

          <div class="submit-comment-container">
            <!--            登陆后显示评论输入框-->
            <div style="display: flex;flex-direction: row;align-items: center" v-if="nickname !== ''">
              <el-image
                  style="width: 50px; height: 50px;border-radius: 50%;margin-right: 10px"
                  :src="imageUrl"
                  fit="cover"
              ></el-image>

              <el-input
                  v-model="commentEdit"
                  :placeholder="$t('basic.enter_comment_tips')"
                  maxlength="10000"
                  type="textarea"
                  show-word-limit
                  :autosize="{ minRows: 5, maxRows: 20}"
              ></el-input>
            </div>

            <!--            未登录显示提示框-->
            <div v-else
                 style="width: 100%;height: 100px; display: flex; justify-content: center;align-items: center; background-color: #f8f9fa;">
              <span
                  style="color: #949797;font-size: large;font-weight: bold">{{
                  $t("basic.commentable_after_login")
                }}</span>
            </div>

            <div style="display: flex;flex-direction: column;align-items: flex-end;margin-top: 10px">
              <el-button type="primary" @click="submitComment" :disabled="commentEdit === ''"
                         v-loading="publishCommentFlag">{{ $t("basic.publish_btn_text") }}
              </el-button>
            </div>

          </div>

          <div class="published-comment-container">
            <div class="published-comment-card" v-for="(comment,index) in commentList" :key="index">
              <div class="all-comment-container">
                <div class="root-comment-container">
                  <div class="root-comment-user-msg">
                    <el-image
                        style="width: 50px; height: 50px;border-radius: 50%"
                        :src="comment.rootComment.userImage"
                        fit="cover"
                    ></el-image>

                    <span style="margin-left: 10px">{{ comment.rootComment.nickname }}</span>
                  </div>

                  <div class="root-comment-content">
                    <span>{{ comment.rootComment.commentContent }}</span>
                  </div>

                  <div class="comment-msg">
                    <span
                        style="font-size: small;margin: 20px 20px 20px 60px">{{
                        moment(comment.rootComment.createdAt).format('yyyy-MM-DD HH:mm:ss')
                      }}</span>

                    <div v-if="nickname !== ''">
                      <el-button type="text"
                                 style="font-size: small; color: #949797"
                                 @click="toggleReplyContainer(index)"
                      >
                        {{ $t("basic.reply_text") }}
                      </el-button>
                    </div>

                    <el-dropdown trigger="click" :hide-on-click="false"
                                 v-if="comment.rootComment.nickname === nickname">
                      <span class="el-icon-more" style="cursor: pointer">
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item>
                          <el-popover
                              placement="top"
                              width="160"
                              v-model="confirmDeleteCommentVisible[index]">
                            <p>{{ $t("basic.delete_comment_confirm_text") }}</p>
                            <div style="text-align: right; margin: 0">
                              <el-button size="mini" type="text" @click="confirmDeleteCommentVisible[index] = false">
                                {{ $t("basic.discard_text") }}
                              </el-button>
                              <el-button type="primary" size="mini"
                                         @click="deleteRootComment(comment.rootComment.rootCommentId,index)">
                                {{ $t("basic.yes_text") }}
                              </el-button>
                            </div>
                            <el-button slot="reference" type="text">{{ $t("basic.delete_button_text") }}</el-button>
                          </el-popover>
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>

                  <div class="sub-comment-container">
                    <div class="sub-comment-card" v-for="(subComment,subIndex) in comment.subCommentList"
                         :key="subIndex">
                      <!--                    子评论-->
                      <div class="sub-comment-user-msg">
                        <el-image
                            style="width: 30px; height: 30px;border-radius: 50%"
                            :src="subComment.userImage"
                            fit="cover"
                        ></el-image>

                        <span style="margin-left: 10px">{{ subComment.nickname }}</span>
                      </div>

                      <div class="sub-comment-content">
                        <span>{{ subComment.commentContent }}</span>
                      </div>

                      <div class="comment-msg">
                      <span
                          style="font-size: small;margin: 0 20px 0 40px">{{
                          moment(subComment.createdAt).format('yyyy-MM-DD HH:mm:ss')
                        }}
                      </span>

                        <div>
                          <el-button type="text"
                                     style="font-size: small; color: #949797"
                                     @click="toggleSubReplyContainer(index,subIndex)"
                          >
                            {{ $t("basic.reply_text") }}
                          </el-button>
                        </div>

                        <el-dropdown trigger="click" :hide-on-click="false"
                                     v-if="subComment.nickname === nickname">
                      <span class="el-icon-more" style="cursor: pointer">
                      </span>
                          <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item>
                              <el-popover
                                  placement="top"
                                  width="160"
                                  v-model="confirmDeleteSubCommentVisible[index][subIndex]">
                                <p>{{ $t("basic.delete_comment_confirm_text") }}</p>
                                <div style="text-align: right; margin: 0">
                                  <el-button size="mini" type="text" @click="updateSubVisibleArray(index,subIndex)">
                                    {{ $t("basic.discard_text") }}
                                  </el-button>
                                  <el-button type="primary" size="mini"
                                             @click="deleteSubComment(subComment,index,subIndex)">
                                    {{ $t("basic.yes_text") }}
                                  </el-button>
                                </div>
                                <el-button slot="reference" type="text">{{ $t("basic.delete_button_text") }}</el-button>
                              </el-popover>
                            </el-dropdown-item>
                          </el-dropdown-menu>
                        </el-dropdown>
                      </div>

                      <!--                  回复子评论-->
                      <div class="reply-container"
                           v-show="activeSubReplyIndex.parentIndex === index && activeSubReplyIndex.subIndex === subIndex">
                        <el-input :placeholder="$t('basic.reply_text') + ' ' + subComment.nickname + ': '"
                                  v-model="replyCommentContainer"
                        ></el-input>

                        <el-button style="margin: 5px 0"
                                   type="primary"
                                   :disabled="replyCommentContainer === ''"
                                   @click="replyComment(subComment)"
                                   v-loading="replyFlag"
                        >{{ $t("basic.reply_text") }}
                        </el-button>
                      </div>

                    </div>


                  </div>

                  <!--              回复根评论    -->
                  <div class="reply-container" v-show="activeReplyIndex === index">
                    <el-input :placeholder="$t('basic.reply_text') + ' ' + comment.rootComment.nickname + ': '"
                              v-model="replyCommentContainer"
                    ></el-input>

                    <el-button style="margin: 5px 0"
                               type="primary"
                               :disabled="replyCommentContainer === ''"
                               @click="replyComment(comment.rootComment)"
                               v-loading="replyFlag"
                    >{{ $t("basic.reply_text") }}
                    </el-button>
                  </div>

                </div>

                <!--                当前根评论总回复数量统计-->
                <div class="root-comment-reply-count"
                     v-if="!checkAllSubCommentFlag[index] && comment.rootComment.rootCommentReplyCount > 0">
                  <span>{{ $t('basic.total_reply_count_text') + comment.rootComment.rootCommentReplyCount }}</span>
                  <el-button type="text" v-if="comment.rootComment.rootCommentReplyCount > 3"
                             @click="selectAllSubCommentByRootCommentId(comment.rootComment.id,index)">
                    {{ $t("basic.check_all_text") }}
                  </el-button>
                </div>

              </div>

            </div>
          </div>
        </div>

        <div class="bottom-white-block"></div>
      </div>

    </div>

  </div>
</template>

<script>
import "quill/dist/quill.core.css"
import moment from "moment";
import ComputerArticleNavMenu from "@/components/component/ComputerArticleNavMenu.vue";

export default {
  name: "ComputerArticleDetails",
  components: {ComputerArticleNavMenu},

  created() {
    this.getArticleById();
    // this.getArticleComments();
    this.moment = moment;
  },

  mounted() {
    // // 获取articleId
    // const articleId = this.$route.params.id;
    // // 使用articleId进行相关操作
    // this.$message.success(articleId);
  },

  data() {
    return {
      publishCommentFlag: false,
      articleLoading: true,
      replyFlag: false,

      nickname: sessionStorage.getItem('nickname') || "",
      username: sessionStorage.getItem('username') || "",
      isShowMenu: sessionStorage.getItem("isShowMenu"),
      imageUrl: sessionStorage.getItem("userImage"),

      articleComments: 0,
      activeReplyIndex: -1, // 存储当前显示输入框的索引，-1表示没有输入框显示
      activeSubReplyIndex: {
        parentIndex: -1,
        subIndex: -1
      }, // 存储当前显示输入框的索引，-1表示没有输入框显示

      articleId: "",
      articleTitle: "",
      contentWithStyle: "",
      articleContent: "",
      articleSource: "",
      articleTopic: "",
      releaseTime: "",
      currentMenu: "",

      authorImageUrl: "",
      authorName: "",
      typeJudge: "",
      commentEdit: "",
      replyCommentContainer: "",

      confirmDeleteCommentVisible: {},
      confirmDeleteSubCommentVisible: {},
      replyIndexContainer: {},
      checkAllSubCommentFlag: {},

      commentList: [],
    }
  },

  methods: {
    navigateToCurrentMenu() {
      if(this.currentMenu === this.$t("basic.user_interface")){
        this.$router.push({name:'user'});
      }else{
        this.$router.push({name: 'home', params: {menuIndex: this.articleTopic.toString()}});
      }
    },

    selectAllSubCommentByRootCommentId(id, index) {
      const requestData = {
        rootCommentId: id
      }

      this.$http.get('/selectAllSubCommentByRootCommentId', {
        params: requestData
      }).then(res => {
        console.log(res)
        this.$set(this.commentList[index], 'subCommentList', res.data.subCommentList);
        this.checkAllSubCommentFlag[index] = true;
      }).catch(err => {
        console.error(err);
      })
    },

    updateSubVisibleArray(index, subIndex) {
      this.$set(this.confirmDeleteSubCommentVisible[index], subIndex, false);
    },

    replyComment(comment) {
      const requestData = {
        articleId: this.articleId,
        commentContent: ' ' + this.$t("basic.reply_text") + ' ' + comment.nickname + ': ' + this.replyCommentContainer,
        commentType: 2,
        parentCommentId: comment.id,
        repliedCommentId: comment.id,
        rootCommentId: comment.rootCommentId,
      }

      const config = {
        headers: {
          'Authorization': sessionStorage.getItem("token") // 替换 yourToken 为实际的 token 值
        }
      }

      this.replyFlag = true;

      this.$http.post('/insertComment', requestData, config).then(res => {
        if (res.data.code === 200) {
          this.$message.success(this.$t("basic.publish_success_text").toString());
          this.replyCommentContainer = "";

          //   重新获取当前文章的所有评论
          this.getArticleComments();
          //   评论数+1
          this.articleComments += 1;
          //   关闭评论输入框
          this.activeSubReplyIndex.subIndex = -1;
          this.activeSubReplyIndex.parentIndex = -1;
          this.activeReplyIndex = -1;
        } else {
          this.$message.error(this.$t("basic.publish_failed_text").toString());
        }
      }).catch(err => {
        console.error(err);
        this.$message.error(this.$t("basic.publish_failed_text").toString());
      }).finally(() => {
        this.replyFlag = false;
      })
    },

    toggleReplyContainer(index) {
      // 重置子回复状态
      this.activeSubReplyIndex = {parentIndex: -1, subIndex: -1};

      if (this.activeReplyIndex === index) {
        this.activeReplyIndex = -1; // 点击同一个按钮时，隐藏输入框
      } else {
        this.activeReplyIndex = index; // 显示点击按钮对应的输入框
      }
    },

    toggleSubReplyContainer(index, subIndex) {
      // 重置根回复状态
      this.activeReplyIndex = -1;

      if (this.activeSubReplyIndex.parentIndex === index && this.activeSubReplyIndex.subIndex === subIndex) {
        this.activeSubReplyIndex = {parentIndex: -1, subIndex: -1};
      } else {
        this.activeSubReplyIndex = {parentIndex: index, subIndex: subIndex};
      }
    },

    deleteSubComment(subComment, index, subIndex) {
      const config = {
        headers: {
          'Authorization': sessionStorage.getItem("token") // 替换 yourToken 为实际的 token 值
        }
      }

      console.log(subComment.commentContent)

      const requestData = {
        id: subComment.id,
        articleId: this.articleId,
      }

      this.$http.post("/deleteSubCommentById", requestData, config).then(res => {
        console.log(res);
        if (res.data.code === 200) {
          this.$message.success(this.$t("basic.delete_success").toString());
          this.$set(this.confirmDeleteSubCommentVisible[index], subIndex, false);
          // 重新获取评论区
          this.getArticleComments();
          //   评论数 - 1
          this.articleComments -= res.data.deleteCommentCount;
          //   重置评论区子评论详情
          this.checkAllSubCommentFlag = {};
        } else {
          this.$message.warning(this.$t("basic.delete_failed").toString());
        }
      }).catch(err => {
        console.error(err);
        this.$message.warning(this.$t("basic.delete_failed").toString());
      })
    },

    deleteRootComment(rootCommentId, index) {
      const config = {
        headers: {
          'Authorization': sessionStorage.getItem("token") // 替换 yourToken 为实际的 token 值
        }
      }

      const requestData = {
        rootCommentId: rootCommentId,
        articleId: this.articleId,
      }

      this.$http.post("/deleteRootComment", requestData, config).then(res => {
        console.log(res);
        if (res.data.code === 200) {
          this.$message.success(this.$t("basic.delete_success").toString());
          this.confirmDeleteCommentVisible[index] = false;
          // 重新获取评论区
          this.getArticleComments();
          //   评论数 - n
          this.articleComments -= res.data.deleteCommentCount;
          //   重置评论区子评论详情
          this.checkAllSubCommentFlag = {};
        } else {
          this.$message.warning(this.$t("basic.delete_failed").toString());
        }
      }).catch(err => {
        console.error(err);
        this.$message.warning(this.$t("basic.delete_failed").toString());
      })
    },

    getArticleComments() {
      const requestData = {
        articleId: this.articleId
      }

      console.log(requestData)

      this.$http.get('/selectCommentsByArticleId', {
            params: requestData
          }
      ).then(res => {
        console.log(res);
        this.commentList = res.data.commentList;
        //   重置评论区子评论详情
        this.checkAllSubCommentFlag = {};

        // 初始化 confirmDeleteSubCommentVisible
        this.commentList.forEach((comment, index) => {
          this.$set(this.confirmDeleteSubCommentVisible, index, []);
          comment.subCommentList.forEach((subComment, subIndex) => {
            this.$set(this.confirmDeleteSubCommentVisible[index], subIndex, false);
          });
        });
      }).catch(err => {
        console.error(err);
      })
    },

    submitComment() {
      this.publishCommentFlag = true;

      const requestData = {
        articleId: this.articleId,
        commentContent: this.commentEdit,
        commentType: 1,
      }

      const config = {
        headers: {
          'Authorization': sessionStorage.getItem("token") // 替换 yourToken 为实际的 token 值
        }
      }

      this.$http.post('/insertComment', requestData, config).then(res => {
        if (res.data.code === 200) {
          this.$message.success(this.$t("basic.publish_success_text").toString());
          this.commentEdit = "";

          //   重新获取当前文章的所有评论
          this.getArticleComments();
          //   评论数+1
          this.articleComments += 1;
        } else {
          this.$message.error(this.$t("basic.publish_failed_text").toString());
        }
      }).catch(err => {
        console.error(err);
        this.$message.error(this.$t("basic.publish_failed_text").toString());
      }).finally(() => {
        this.publishCommentFlag = false;
      })
    },

    getArticleById() {
      this.articleLoading = true;

      debugger

      const requestData = {
        articleId: this.$route.params.id
      }

      this.$http.get('getArticleByArticleId', {
        params: requestData
      }).then(res => {
        console.log(res)

        this.articleId = res.data.data.articleId;
        this.articleTitle = res.data.data.articleTitle;
        this.authorName = res.data.nickname;
        this.authorImageUrl = res.data.userImage;
        this.articleComments = res.data.data.articleComments;
        this.contentWithStyle = res.data.data.contentWithStyle;
        this.articleTopic = res.data.data.articleTopic;
        // this.articleContent = res.data.data.articleContent;
        this.releaseTime = moment(res.data.data.releaseTime).format('yyyy-MM-DD HH:mm');

        const menuItems = {
          1: this.$t("basic.market_info_text"),
          2: this.$t("basic.research_report_text"),
          3: this.$t("basic.perspectives_and_interviews_text")
        };

        this.currentMenu = menuItems[res.data.data.articleTopic];

        switch (res.data.data.articleTopic) {
          case 1:
            this.currentMenu = this.$t("basic.market_info_text");
            break;
          case 2:
            this.currentMenu = this.$t("basic.research_report_text");
            break;
          case 3:
            this.currentMenu = this.$t("basic.perspectives_and_interviews_text");
            break;
          default:
            this.currentMenu = this.$t("basic.user_interface");
            break;
        }

        switch (res.data.data.articleSource) {
          case 1:
            this.articleSource = this.$t("basic.original_text").toString();
            this.typeJudge = "danger";
            break;
          case 2:
            this.articleSource = this.$t("basic.repost_text").toString();
            this.typeJudge = "success";
            break;
          case 3:
            this.articleSource = this.$t("basic.translate_text").toString();
            this.typeJudge = "warning";
            break;
          default:
            this.articleSource = this.$t("basic.unknown_text").toString();
            this.typeJudge = "info";
            break;
        }

      }).catch(err => {
        console.error(err);
      }).finally(() => {
        this.articleLoading = false;
        // 获取文章详情后再发送请求获取评论详情
        this.getArticleComments();
      })
    },

  },
}
</script>

<style scoped>

.page-container {
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.topic-navigate {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #545c64;
  height: 50px;
}

.under-navigate-container {
  background: #f8f9fa;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.article-container {
  background: white;
  height: auto;
  width: 1000px;
}

.article-title {
  /*text-align: center;*/
  padding: 10px 10px;
  font-size: 40px;
  font-weight: bold;
}

.author-container {
  display: flex;
  flex-direction: row;
  padding: 10px 10px;
  align-items: center;
  font-weight: bold;
}

.article-content-container {
  white-space: pre-wrap;
  text-align: justify;
  word-break: break-word;
  padding: 10px 20px;
  line-height: 1.5;
}

.ql-editor >>> img {
  max-width: 100%;
}

.bottom-white-block {
  height: 200px;
}

.multi-button-container {
  border-top: 1px solid #ebebeb;
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.function-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
}

.comment-title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.comment-section-container {
  width: 100%;
}

.submit-comment-container {
  display: flex;
  flex-direction: column;
  padding: 5px 20px;
}

.published-comment-container {

}

.published-comment-card {
  border-bottom: 1px solid #ebebeb;
  margin: 20px 20px
}

.all-comment-container {

}

.root-comment-container {

}

.root-comment-user-msg {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.root-comment-content {
  margin: 20px 20px 20px 60px;
}

.comment-msg {
  color: #949797;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.reply-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.sub-comment-container {
  margin-left: 60px;
}

.sub-comment-card {
  margin-top: 5px;
}

.sub-comment-user-msg {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.sub-comment-content {
  padding: 15px 0;
  margin-left: 40px;
}

.root-comment-reply-count {
  height: 40px;
  font-size: small;
  color: #949797;
  font-weight: normal;
  margin-left: 100px;
  display: flex;
  align-items: center;
}
</style>
