<template>
  <div class="compare-data-style">
    <el-col :span=".35">
      <div style="display: flex;">
        <!--                均权数据-->
        <div class="equity_data_style">
          <!--                八个数据，四个为一组，一组数据以列的形式进行排列-->
          <el-col :span="12.5">
            <el-statistic
                :title="sum_equity_yield_title"
                :precision="1"
                :value="sumEquityYieldData"
                suffix="%"
            >
            </el-statistic>
          </el-col>

          <el-col :span="12.5">
            <el-statistic
                group-separator=","
                :precision="1"
                :value="equityReturn"
                :title="equity_return_title"
                suffix="%"
            ></el-statistic>
          </el-col>

          <el-col :span="12.5">
            <el-statistic
                group-separator=","
                :precision="1"
                :value="equityVolatility"
                :title="equity_volatility_title"
                suffix="%"
            ></el-statistic>
          </el-col>

          <el-col :span="12.5">
            <el-statistic
                group-separator=","
                :precision="3"
                decimal-separator="."
                :value="equitySharpeRatio"
                :title="equity_sharpe_title"
            >
              <template slot="prefix">
                <i class="el-icon-s-flag" style="color: red"></i>
              </template>
              <template slot="suffix">
                <i class="el-icon-s-flag" style="color: red"></i>
              </template>
            </el-statistic>
          </el-col>
        </div>
        <!--              优权数据-->
        <div class="weighted_data_style">
          <el-col :span="12.5">
            <el-statistic
                group-separator=","
                :precision="1"
                :value="sumWeightedYieldData"
                :title="weighted_yield_title"
                suffix="%"
            ></el-statistic>
          </el-col>

          <el-col :span="12.5">
            <el-statistic
                group-separator=","
                :precision="1"
                :value="weightedReturn"
                :title="weighted_return_title"
                suffix="%"
            ></el-statistic>
          </el-col>

          <el-col :span="12.5">
            <el-statistic
                :title="weighted_volatility_title"
                :precision="1"
                :value="weightedVolatility"
                suffix="%"
            >
            </el-statistic>
          </el-col>

          <el-col :span="12.5">
            <el-statistic
                group-separator=","
                :precision="3"
                decimal-separator="."
                :value="weightedSharpeRatio"
                :title="weighted_sharpe_title"
            >
              <template slot="prefix">
                <i class="el-icon-s-flag" style="color: blue"></i>
              </template>
              <template slot="suffix">
                <i class="el-icon-s-flag" style="color: blue"></i>
              </template>
            </el-statistic>
          </el-col>
        </div>
      </div>
    </el-col>

    <!--表格-->
    <div class="table-width-style">
      <el-table
          class="tableDataTwo"
          :data="tableData"
          stripe
          :cell-style="cellStyle"
          style="">
        <el-table-column
            prop="stockCode"
            :label="numberOfTable + $t('basic.table_stock')"
            width="115">
        </el-table-column>
        <el-table-column
            prop="weights"
            :label="$t('basic.table_weight') + startDate + '~~' + endDate"
            width="150"
            show-overflow-tooltip
        >
        </el-table-column>
      </el-table>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    weightedSharpeRatio: Number,
    weightedVolatility: Number,
    weightedReturn: Number,
    sumWeightedYieldData: Number,
    equitySharpeRatio: Number,
    equityVolatility: Number,
    sumEquityYieldData: Number,
    equityReturn: Number,
    numberOfTable: String,
    startDate:String,
    endDate:String,

    tableData: Array,


  },
  data() {
    return {
      sum_equity_yield_title: this.$t("basic.mean_weighted_interval_total_return"),
      equity_return_title: this.$t("basic.weighted_return"),
      equity_volatility_title: this.$t("basic.weighted_volatility"),
      equity_sharpe_title: this.$t("basic.weighted_sharpe"),
      weighted_yield_title: this.$t("basic.weighted_interval_total_return"),
      weighted_return_title: this.$t("basic.weighted_return_with_premium"),
      weighted_sharpe_title: this.$t("basic.sharpe_with_premium"),
      weighted_volatility_title: this.$t("basic.weighted_volatility_with_premium"),
      equityTitle: this.$t("basic.mean_weighted_interval_total_return"),
      weightedTitle: this.$t("basic.weighted_interval_total_return"),
    }
  },

  methods:{
    cellStyle({ row, column, rowIndex, columnIndex }){
      if (column.property === 'stockCode') {
        return 'font-weight: bold;'; // 这里可以设置具体的样式
      } else {
        return '';
      }
    },
  }
};
</script>

<style scoped>
.equity_data_style {
  display: flex;
  flex-direction: column;
  background-color: lightpink;
  width: 140px;
}

.weighted_data_style {
  display: flex;
  flex-direction: column;
  background-color: lightblue;
  width: 140px;
}

.compare-data-style {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.el-table /deep/ .el-table__body-wrapper {
  /*overflow-x: scroll !important;*/
  max-height: 490px;
  overflow-y: auto;
}

</style>
