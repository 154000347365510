<template>
  <div class="edit-container">
    <div class="article-content-edit" v-show="isEditContent">
      <div class="edit-navigation">
        <el-button type="text" style="padding: 3vw;color: white;font-weight: bold" @click="jumpToHome">
          {{ $t("basic.cancel_button_text") }}
        </el-button>
        <el-button type="text" style="padding: 3vw;color: white;font-weight: bold" @click="jumpToArticleSettings">
          {{ $t("basic.next_text") }}
        </el-button>
      </div>

      <div class="edit-title">
        <el-input :placeholder="$t('basic.enter_article_title_tips')"
                  v-model="articleTitle"
                  style="font-size: 17px;font-weight: bold"
        ></el-input>
      </div>

      <div class="edit-content">
        <multi-text-editor v-if="contentWithStyle" v-model="contentWithStyle"></multi-text-editor>
      </div>
    </div>

    <div class="article-message-settings" v-show="!isEditContent">
      <div class="edit-navigation">
        <el-button type="text" style="padding: 3vw;color: white;font-weight: bold" @click="isEditContent = true">
          {{ $t("basic.back_text") }}
        </el-button>
      </div>

      <div class="edit-article-source">
        <span class="article-setting-label">{{ $t("basic.article_source_text") }}</span>

        <el-radio-group v-model="articleSource" size="small" fill="#42b983">
          <el-radio-button :label="$t('basic.original_text').toString()"></el-radio-button>
          <el-radio-button :label="$t('basic.repost_text').toString()"></el-radio-button>
          <el-radio-button :label="$t('basic.translate_text').toString()"></el-radio-button>
        </el-radio-group>
      </div>

      <div class="edit-article-topic">
        <span class="article-setting-label">{{ $t("basic.article_topic_text") }}</span>

        <el-radio-group v-model="articleTopic"
                        size="small"
                        fill="#42b983"
                        style="display: flex;
                      flex-direction: row;
                      overflow-x: auto;
                      width: 60vw;"
        >
          <el-radio-button :label="$t('basic.market_info_text').toString()"></el-radio-button>
          <el-radio-button :label="$t('basic.research_report_text').toString()"></el-radio-button>
          <el-radio-button :label="$t('basic.perspectives_and_interviews_text').toString()"></el-radio-button>
        </el-radio-group>
      </div>

      <div class="submit-article">
        <el-button @click="publishArticle"
                   v-loading="isPublished"
                   style="width: 80vw;border-radius: 20px;background-color: #42b983;
                 color: white;font-weight: bold;font-size: 18px;"
        >{{ $t("basic.publish_text") }}
        </el-button>

      </div>
    </div>

    <el-dialog
        :visible.sync="saveDraftDialogVisible"
        width="75%"
        v-loading="saveDraftLoading"
    >
      <span>{{ $t("basic.confirm_save_draft_text") }}</span>
      <span slot="footer" class="dialog-footer" style="display: flex;align-items: center;justify-content: space-around">
    <el-button @click="$router.push('/home')">{{ $t("basic.discard_text") }}</el-button>
    <el-button type="primary" @click="saveDraft">{{ $t("basic.yes_text") }}</el-button>
  </span>
    </el-dialog>

  </div>
</template>

<script>
import moment from "moment";
import MultiTextEditor from "@/components/component/MultiTextEditor.vue";

export default {
  name: "MobileEditDraft",
  components: {MultiTextEditor},

  created() {
    this.readDraft();
  },

  data() {
    return {
      isEditContent: true,
      isPublished: false,
      saveDraftDialogVisible: false,
      saveDraftLoading: false,

      articleId:"",
      originalTitle:"",
      originalContent:"",
      originalContentWithStyle:"",

      articleTitle: "",
      articleContent: "",
      contentWithStyle: "",
      articleSource: "",
      articleTopic: "",
      articleStatus: -1,
      articleTopicNumber: -1,
      articleSourceNumber: -1,
    }
  },

  methods: {
    readDraft() {
      this.getArticleById();
    },

    getArticleById() {
      this.articleLoading = true;

      const requestData = {
        articleId: this.$route.params.id
      }

      this.$http.get('/getUserDraftByArticleId', {
        headers: {
          'Authorization': sessionStorage.getItem("token") // 替换 yourToken 为实际的 token 值
        },
        params: requestData
      }).then(res => {
        console.log(res)
        this.articleId = res.data.data.articleId;
        this.originalTitle = res.data.data.articleTitle;
        this.articleTitle = res.data.data.articleTitle;
        this.authorName = res.data.username;
        this.originalContent = res.data.data.articleContent;
        this.originalContentWithStyle = res.data.data.contentWithStyle;
        this.articleContent = res.data.data.articleContent;
        this.contentWithStyle = res.data.data.contentWithStyle;
        this.releaseTime = moment(res.data.data.releaseTime).format('yyyy-MM-DD HH:mm');

        switch (res.data.data.articleSource) {
          case 1:
            this.articleSource = this.$t("basic.original_text").toString();
            this.typeJudge = "danger";
            break;
          case 2:
            this.articleSource = this.$t("basic.repost_text").toString();
            this.typeJudge = "success";
            break;
          case 3:
            this.articleSource = this.$t("basic.translate_text").toString();
            this.typeJudge = "warning";
            break;
          default:
            this.articleSource = this.$t("basic.unknown_text").toString();
            this.typeJudge = "info";
            break;
        }

      }).catch(err => {
        console.error(err);
      }).finally(() => {
        this.articleLoading = false;
      })
    },

    /**
     * 保存草稿的修改
     */
    saveDraft() {
      this.saveDraftLoading = true;
      // 文章状态设为草稿类型
      this.articleStatus = 1;

      const requestData = {
        articleId: this.articleId,
        articleTitle: this.articleTitle,
        articleContent: this.articleContent,
        contentWithStyle: this.contentWithStyle,
        articleSource: 0,
        articleTopic: 0,
        articleStatus: this.articleStatus
      };

      const config = {
        headers: {
          'Authorization': sessionStorage.getItem("token") // 替换 yourToken 为实际的 token 值
        },
      };

      this.$http.post('/updateArticle', requestData, config).then(res => {
        console.log(res);
        if (res.data.code === 200) {
          this.$message.success(this.$t("basic.save_success_text").toString());
          this.$router.push('/myArticle');
        } else {
          this.$message.warning(this.$t("basic.save_failed_text").toString());
        }
      }).catch(err => {
        console.error(err);
      }).finally(() => {
        this.saveDraftLoading = false;
      })
    },

    jumpToArticleSettings() {
      if (this.articleTitle === "" || this.contentWithStyle === "") {
        this.$message.warning(this.$t("basic.topic_content_empty_error").toString());
        return;
      }

      const parser = new DOMParser();
      const doc = parser.parseFromString(this.contentWithStyle, "text/html");
      this.articleContent = doc.body.textContent || '';

      this.isEditContent = false;
    },

    jumpToHome() {
      if (this.articleTitle !== this.originalTitle || this.contentWithStyle !== this.originalContentWithStyle) {
        // 确认是否保存草稿
        this.saveDraftDialogVisible = true;
        return;
      }
      this.$router.push('/myArticle');
    },

    publishArticle() {
      if (this.articleSource === "" || this.articleTopic === "") {
        this.$message.warning(this.$t("basic.incomplete_registration_information").toString());
        return;
      }

      this.getSourceAndTopicNumber();
      this.isPublished = true;
      // 文章状态设为已发布类型
      this.articleStatus = 2;

      const requestData = {
        articleId: this.articleId,
        articleTitle: this.articleTitle,
        articleContent: this.articleContent,
        contentWithStyle: this.contentWithStyle,
        articleSource: this.articleSourceNumber,
        articleTopic: this.articleTopicNumber,
        articleStatus: this.articleStatus
      };

      const config = {
        headers: {
          'Authorization': sessionStorage.getItem("token") // 替换 yourToken 为实际的 token 值
        },
      };

      this.$http.post('/updateArticle', requestData, config).then(res => {
        if (res.data.code === 200) {
          this.$message.success(this.$t("basic.publish_success_text").toString());
          this.$router.push('/home');
        }
      }).catch(err => {
        console.error(err);
      }).finally(() => {
        this.isPublished = false;
      })

    },

    getSourceAndTopicNumber() {
      switch (this.articleTopic) {
        case "市场资讯":
          this.articleTopicNumber = 1;
          break;
        case "研究报告":
          this.articleTopicNumber = 2;
          break;
        case "观点与访谈":
          this.articleTopicNumber = 3;
          break;
        default:
          this.articleTopicNumber = -1; // 如果不是以上任何一个主题，则设置为-1或其他默认值
          break;
      }

      switch (this.articleSource) {
        case "原创":
          this.articleSourceNumber = 1;
          break;
        case "转载":
          this.articleSourceNumber = 2;
          break;
        case "翻译":
          this.articleSourceNumber = 3;
          break;
        default:
          this.articleSourceNumber = -1; // 如果不是以上任何一种来源，则设置为-1或其他默认值
          break;
      }
    }
  }

}
</script>

<style scoped>
.edit-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
}

.article-content-edit{
  width: 100%;
  height: 100%;
  overflow-y: hidden;
}

.edit-navigation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #ebebeb;
  width: 100%;
  height: 50px;
  background-color: #545c64;
  text-color: #42b983;
}

.edit-title {
  padding: 0 5px;
  width: 95%;
}

.edit-title >>> .el-input__inner {
  border: 0;
  border-bottom: 2px solid #ebebeb;
}

.edit-content {
  width: 100%;
  height: 85%;
}

.edit-content >>> .el-textarea__inner {
  border: 0;
  height: 100%;
}

.edit-article-source {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 15px 15px;
}

.article-message-settings {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.article-setting-label {
  padding: 10px;
}

.edit-article-topic {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 15px 15px;
}

.edit-article-topic >>> ::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.submit-article {
  margin-top: auto;
  padding: 15px 0;
}
</style>

