import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/Home.vue'
import login from "@/components/login";
import Register from "@/components/Register";
import RecordManage from "@/components/RecordManage";
import Settings from "@/components/Settings";
import User from "@/components/User";
import Help from "@/components/Help";
import KdjIndex from "@/components/KdjIndex";
import Bookmarks from "@/components/Bookmarks";
import Customize from "@/components/Customize";
import MobileLogin from "@/mobile_components/MobileLogin";
import MobileRegister from "@/mobile_components/MobileRegister";
import MobileHome from "@/mobile_components/MobileHome";
import ArticleDetails from "@/mobile_components/MobileArticleDetails";
import MobileArticleDetails from "@/mobile_components/MobileArticleDetails";
import MobileArticleEdit from "@/mobile_components/MobileArticleEdit";
import MobileUser from "@/mobile_components/MobileUser";
import MobileBrowsingHistory from "@/mobile_components/MobileBrowsingHistory";
import MobileMyArticle from "@/mobile_components/MobileMyArticle";
import MobileMyBookmark from "@/mobile_components/MobileMyBookmark";
import MobileEditDraft from "@/mobile_components/MobileEditDraft";
import MobileUpdateArticle from "@/mobile_components/MobileUpdateArticle";
import ComputerHome from "@/computer_components/ComputerHome.vue";
import ComputerArticleEdit from "@/computer_components/ComputerArticleEdit.vue";
import ComputerArticleDetails from "@/computer_components/ComputerArticleDetails.vue";
import ComputerUpdateArticle from "@/computer_components/ComputerUpdateArticle.vue";

Vue.use(VueRouter)

const routes = [
    // 重定向，访问首页时跳转到某个页面
    {path: '/', redirect: '/home'},
    {
        path: '/gaoFundSystem',
        name: 'GaoFundSystem',
        component: resolve => {
            // 根据设备类型动态选择组件
            const component = sessionStorage.getItem("device_type") === "mobile" ? Home : Home;
            resolve(component);
        },
        meta: {
            showNav: false,
            keepAlive: true,
        }
    },

    {
        path: '/home',
        name: 'home',
        component: resolve => {
            // 根据设备类型动态选择组件
            const component = sessionStorage.getItem("device_type") === "mobile" ? MobileHome : ComputerHome;
            resolve(component);
        },
        meta: {
            showNav: false,
            keepAlive: true,
        }
    },

    {
        path: '/kdjIndex',
        name: 'kdj',
        component: KdjIndex,
        meta: {
            keepAlive: true,
        }
    },

    {
        path: '/bookmarks',
        name: 'bookmarks',
        component: Bookmarks,
        meta: {
            keepAlive: true,
        }
    },

    {
        path: '/customize',
        name: 'customize',
        component: Customize,
        meta: {
            keepAlive: true,
        }
    },

    {
        path: '/login',
        name: 'login',
        component: resolve => {
            // 根据设备类型动态选择组件
            const component = sessionStorage.getItem("device_type") === "mobile" ? MobileLogin : login;
            resolve(component);
        },
    },

    {
        path: '/register',
        name: 'register',
        component: resolve => {
            // 根据设备类型动态选择组件
            const component = sessionStorage.getItem("device_type") === "mobile" ? MobileRegister : Register;
            resolve(component);
        },
    },

    {
        path: '/record',
        name: 'record',
        component: RecordManage,
    },

    {
        path: '/settings',
        name: 'settings',
        component: Settings,
        meta: {
            keepAlive: true,
        }
    },

    {
        path: '/user',
        name: 'user',
        meta: {
            keepAlive: true,
        },
        component: resolve => {
            // 根据设备类型动态选择组件
            const component = sessionStorage.getItem("device_type") === "mobile" ? MobileUser : User;
            resolve(component);
        },
    },

    {
        path: '/help',
        name: 'help',
        component: Help,
        meta: {
            keepAlive: true,
        }
    },

    {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    },

    {
        path: '/article/:id', // 定义了一个名为id的路由参数
        name: 'ArticleDetails',
        component: resolve => {
            // 根据设备类型动态选择组件
            const component = sessionStorage.getItem("device_type") === "mobile" ? MobileArticleDetails : ComputerArticleDetails;
            resolve(component);
        },
    },

    {
        path: '/editArticle',
        name: 'EditArticle',
        component: resolve => {
            // 根据设备类型动态选择组件
            const component = sessionStorage.getItem("device_type") === "mobile" ? MobileArticleEdit : ComputerArticleEdit;
            resolve(component);
        },
    },

    {
        path: '/browsingHistory',
        name: 'BrowsingHistory',
        component: resolve => {
            // 根据设备类型动态选择组件
            const component = sessionStorage.getItem("device_type") === "mobile" ? MobileBrowsingHistory : MobileBrowsingHistory;
            resolve(component);
        },
    },

    {
        path: '/myArticle',
        name: 'MyArticle',
        component: resolve => {
            // 根据设备类型动态选择组件
            const component = sessionStorage.getItem("device_type") === "mobile" ? MobileMyArticle : MobileMyArticle;
            resolve(component);
        },
    },

    {
        path: '/editDraft',
        name: 'EditDraft',
        component: resolve => {
            // 根据设备类型动态选择组件
            const component = sessionStorage.getItem("device_type") === "mobile" ? MobileEditDraft : MobileEditDraft;
            resolve(component);
        },
    },

    {
        path: '/myBookmark',
        name: 'MyBookmark',
        component: resolve => {
            // 根据设备类型动态选择组件
            const component = sessionStorage.getItem("device_type") === "mobile" ? MobileMyBookmark : MobileMyBookmark;
            resolve(component);
        },
    },

    {
        path: '/updateArticle',
        name: 'UpdateArticle',
        component: resolve => {
            // 根据设备类型动态选择组件
            const component = sessionStorage.getItem("device_type") === "mobile" ? MobileUpdateArticle : ComputerUpdateArticle;
            resolve(component);
        },
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

// 添加全局前置守卫
router.beforeEach((to, from, next) => {
    const isLoggedIn = sessionStorage.getItem('isLoggedIn');

    // 更新 sessionStorage 中的设备类型
    const isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
    if (isMobile) {
        sessionStorage.setItem("device_type", "mobile");
    } else {
        sessionStorage.setItem("device_type", "computer");
    }

    // 如果用户已经登录，则直接放行
    if (isLoggedIn === "yes") {
        // console.log('已登录');
        next();
        return;
    }

    // 对于登录、注册和首页，不进行重定向判断
    if (to.name === 'login' || to.name === 'register' || to.name === 'home' || to.name === 'ArticleDetails') {
        // console.log('无需登录');
        next();
    }else{
        // console.log('未登录！');
        next({name:'login'});
    }

    // 对于其他需要登录才能访问的页面，如果未登录，则重定向到登录页
    // next({ name: 'login' });
})

// 添加全局后置守卫
router.afterEach((to, from) => {
    // 更新 sessionStorage 中的设备类型
    const isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
    if (isMobile) {
        sessionStorage.setItem("device_type", "mobile");
    } else {
        sessionStorage.setItem("device_type", "computer");
    }
})

export default router
