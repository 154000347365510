<template>
  <LayoutContainer>

    <template v-slot:my-header>
      <div>
        <el-dropdown trigger="click">
              <span class="el-dropdown-link" style="cursor: pointer;color:#42b983;font-weight: bold">
                {{ $t("basic.current_account") }}{{ username }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="signOut">{{ $t("basic.quit_account") }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </template>

    <span>setting</span>
  </LayoutContainer>
</template>

<script>
import moment from "moment";
import LayoutContainer from "@/components/layout/LayoutContainer";

export default {
  name: "Settings",
  components:{LayoutContainer},

  data(){
    return{
      isCollapse: true,
      loading:true,
      recordIds:[],
      records:[],
      createTime:[],
      multipleSelection: [],
      tableData:[],
      username:sessionStorage.getItem("username"),
    }
  },

  created(){
  },

  methods:{
    collapseNav(){
      this.isCollapse = !this.isCollapse;
    },

    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },


    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },

    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    signOut() {
      sessionStorage.setItem("username", '');
      sessionStorage.setItem("isLoggedIn", '');
      this.$message.success(this.$t("basic.sign_out_success").toString());
      this.$router.push('/login');
    },
  },
}
</script>

<style scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}

.toggle-button{
  width: 100%;
}

.header-style{
  background-color: #2c3e50;
  color: #42b983;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
