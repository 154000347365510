<template>
  <div class="article-card-container">
    <div class="article-title">
      <slot name="statusTag"> <!-- 插槽 -->
        <!-- 默认状态标签为空 -->
      </slot>

      <span style="">{{articleTitle}}</span>

      <slot name="editButton"></slot>
    </div>

    <div class="article-brief-content">
      <span style=" display: block;text-align: justify">{{articleBriefContent}}</span>
    </div>

<!--    <div class="article-message">-->
<!--      <span>{{releaseTime}}</span>-->
<!--      <span class="fa fa-thumbs-o-up">{{articleLike}}</span>-->
<!--      <span class="el-icon-view">{{articleViews}}</span>-->
<!--      <span class="el-icon-chat-dot-round">{{articleComments}}</span>-->
<!--    </div>-->
  </div>

</template>

<script>

export default {
  name: "MobileArticleCard",

  props:{
    articleTitle:String,
    articleBriefContent:String,
    releaseTime:String,
    articleLike:Number,
    articleViews:Number,
    articleComments:Number,



  },

  methods:{

  },
}
</script>

<style scoped>
.article-card-container{
  display: flex;
  flex-direction: column;
  border: 1px solid #ebebeb;
  border-radius: 10px;
  width: 98%;
  margin-bottom: 5px;
}

.article-title{
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: large;
  font-weight: bold;
  padding: 2%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 90%;
}

.article-brief-content{
  padding: 0 2% 10px 2%;
  font-size: small;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 90%;
}

.article-message{
  padding: 2%;
  margin-top: auto;
  font-size: small;
  display: flex;
  justify-content: space-between;
}

</style>
