<template xmlns="http://www.w3.org/1999/html">

  <LayoutContainer>
    <template v-slot:my-header>
      <div style="display: flex;flex-direction: column;align-items: flex-start">
        <el-dropdown trigger="click">
              <span class="el-dropdown-link" style="cursor: pointer;color:#42b983;font-weight: bold">
                {{ $t("basic.current_account") }}{{ username }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="choiceLogin">{{ $t("basic.login_to_choice") }}</el-dropdown-item>
            <el-dropdown-item @click.native="choiceStop">{{ $t("basic.quit_choice") }}</el-dropdown-item>
            <el-dropdown-item @click.native="signOut">{{ $t("basic.quit_account") }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

        <div style="margin-top: 5px">
          <span style="margin-right: 5px;font-weight: bold">{{ $t("basic.language_tips") }}</span>
          <el-select v-model="language" size="mini" @change="changeLang" placeholder="Language"
                     style="vertical-align: middle; width: 100px; margin-right: 20px;">
            <el-option label="简体中文" value="zh"></el-option>
            <el-option label="English" value="en"></el-option>
          </el-select>
        </div>
      </div>
    </template>

    <div class="searchStock">
      <el-form>

        <div class="flex_center_style">
          <div style="display:flex;flex-direction:column;align-items:center;min-width: 1410px;max-width: 1600px">
            <div class="code_input_bar">
              <div id="step1">
                <span class="red-font-style">{{ $t("basic.input_code_notification") }}</span>
                <div>
                  <i class="el-icon-caret-right"></i>
                  <el-popover
                      placement="top"
                      width="200"
                      trigger="hover"
                      :content="$t('basic.input_code_description')">
                    <el-button type="text" style="color: black;margin-right: 10px" slot="reference">
                      {{ $t('basic.stock_codes') }}
                    </el-button>
                  </el-popover>
                  <el-autocomplete
                      v-model="stockCodes"
                      popper-class="codeInput-popper"
                      :popper-append-to-body="false"
                      :fetch-suggestions="querySearchRecords"
                      :placeholder="$t('basic.stock_codes_enter_tips')"
                      @select="handleSelect"
                      @input="onChange"
                      style="width: 300px"
                      clearable
                      v-on:keyup.native.enter="sendStockData"
                  ></el-autocomplete>

                  <span v-if="inputHasError" class="error-message">{{ $t("basic.stock_enter_err_tips") }}</span>
                  <span v-else style="color: #505458;font-weight: bold">{{
                      $t("basic.stock_count")
                    }}{{ numberOfStock }}</span>
                </div>
              </div>

              <div id="step2">
                <div>
                  <span class="red-font-style"
                        v-if="secuType === 3">{{ $t("basic.chinese_mutual_fund_only_text") }}</span>
                </div>
                <div>
                  <i class="el-icon-caret-right"></i>
                  <el-popover
                      placement="top-start"
                      width="200"
                      trigger="hover"
                      :content="$t('basic.portfolio_type_description')">
                    <el-button type="text" style="color: black;margin-right: 10px" slot="reference">
                      {{ $t("basic.portfolio_type") }}
                    </el-button>
                  </el-popover>
                  <el-select v-model="secuType" :placeholder="$t('basic.portfolio_type_tips')" style="width: 195px">
                    <el-option
                        v-for="item in portfolioTypeOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </div>

              </div>

              <div id="step3">
                  <span>
                    <i class="el-icon-caret-right"></i>
                    <el-popover
                        placement="top-start"
                        width="200"
                        trigger="hover"
                        :content="$t('basic.date_range_description')">
                      <el-button type="text" style="color: black;margin-right: 10px" slot="reference">{{
                          $t("basic.date_range")
                        }}</el-button>
                    </el-popover>
                    <el-date-picker
                        v-model="startAndEndDate"
                        style="width: 250px"
                        class=".el-picker-panel .el-picker-panel__sidebar"
                        type="daterange"
                        align="right"
                        unlink-panels
                        range-separator="~"
                        :start-placeholder="$t('basic.start_date_enter_tips')"
                        :end-placeholder="$t('basic.end_date_enter_tips')"
                        :picker-options="pickerOptions">
                    </el-date-picker>
                  </span>
              </div>

            </div>
            <div class="code_input_bar">
              <div style="display: flex;align-items: center" id="step4">
                <i class="el-icon-caret-right"></i>
                <el-popover
                    placement="bottom"
                    width="200"
                    trigger="hover"
                    :content="$t('basic.weight_limit_description')">
                  <el-button type="text" style="color: black;margin-right: 10px" slot="reference">
                    {{ $t("basic.weight_limit") }}
                  </el-button>
                </el-popover>
                <el-slider
                    v-model="weightLimit"
                    show-input
                    input-size="mini"
                    height="10"
                    style="width: 365px"
                >
                </el-slider>
              </div>


              <div>
                <i class="el-icon-caret-right"></i>
                <el-popover
                    placement="bottom"
                    width="200"
                    trigger="hover"
                    :content="$t('basic.date_period_description')">
                  <el-button type="text" style="color: black;margin-right: 10px" slot="reference">{{
                      $t("basic.date_period")
                    }}
                  </el-button>
                </el-popover>
                <el-select v-model="datePeriod" placeholder="" style="width: 195px">
                  <el-option
                      v-for="item in periodOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>

              <div>
                <i class="el-icon-caret-right"></i>
                <el-popover
                    placement="bottom"
                    width="200"
                    trigger="hover"
                    :content="$t('basic.adjustment_description')">
                  <el-button type="text" style="color: black;margin-right: 10px" slot="reference">{{
                      $t("basic.adjustment")
                    }}
                  </el-button>
                </el-popover>
                <el-select v-model="adjustFlag" placeholder="">
                  <el-option
                      v-for="item in adjustFlagOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </div>
            </div>

            <!--            <el-form-item v-loading="multipleCalFlag" :element-loading-text="$t('basic.wait_a_minute_tips')"-->
            <!--                          style="display: flex">-->
            <!--              <el-button id="step9" type="text" size="" @click="viewIntro">{{ $t("basic.operation_guidance") }}-->
            <!--              </el-button>-->
            <!--            </el-form-item>-->

          </div>
        </div>

        <el-tabs :stretch="true" style="min-height: 440px" v-loading="sharpeCalFlag || multipleCalFlag">
          <el-tab-pane v-loading="sharpeCalFlag"
                       :element-loading-text="$t('basic.wait_a_minute_tips')">
            <span slot="label" style="font-weight: bold">{{ $t('basic.portfolio_sharpe_list') }}</span>
            <!--个股夏普比率情况展示-->
            <div style="width: 100%;text-align: center;">
              <!--              <h3 style="">{{ $t("basic.portfolio_sharpe_list") }}</h3>-->
              <label v-if="endDateValue"
                     style="font-weight: bold;color: #949797">{{ $t("basic.end_date_enter_tips") + ': ' }}</label>
              <span>{{ endDateValue }}</span>
            </div>
            <div style="max-width: 100%;display: flex;justify-content: center">
              <div style="display: flex;justify-content: center;max-width: 1600px;">
                <div class="sharpe-ratio-display">
                  <div style="padding-right: 40px">
                    <el-table
                        class=""
                        :data="portfolioSharpeRatioData['sharpe_since_listing']"
                        stripe
                        :cell-style="cellStyle"
                        style="">
                      <el-table-column
                          prop="code"
                          :label="$t('basic.stock_codes')"
                          width="135">
                      </el-table-column>
                      <el-table-column
                          prop="list_date"
                          :label="$t('basic.security_listing_date')"
                          width="115"
                          show-overflow-tooltip
                      ></el-table-column>
                      <el-table-column
                          prop="sharpe_since_list"
                          :label="$t('basic.security_sharpe_since_listing')"
                          width="115"
                      ></el-table-column>
                    </el-table>
                  </div>

                  <ShowSharpeRatioTable
                      :label-of-data="$t('basic.security_five_year_sharpe').toString()"
                      sharpe-ratio-prop="five_year_sharpe"
                      :sharpe-ratio-data="portfolioSharpeRatioData['five_year_sharpe']"
                  ></ShowSharpeRatioTable>

                  <ShowSharpeRatioTable
                      :label-of-data="$t('basic.security_three_year_sharpe').toString()"
                      sharpe-ratio-prop="three_year_sharpe"
                      :sharpe-ratio-data="portfolioSharpeRatioData['three_year_sharpe']"
                  ></ShowSharpeRatioTable>

                  <ShowSharpeRatioTable
                      :label-of-data="$t('basic.security_one_year_sharpe').toString()"
                      sharpe-ratio-prop="one_year_sharpe"
                      :sharpe-ratio-data="portfolioSharpeRatioData['one_year_sharpe']"
                  ></ShowSharpeRatioTable>
                </div>

                <div style="display: flex;flex-direction: column;justify-content: space-around;align-items: flex-end">
                  <el-button size="small" :disabled="isSharpeTableEmpty" @click="ascendingSort"
                             :type="ascSortButtonType">{{ $t("basic.asc_sort_button") }}
                  </el-button>
                  <el-button size="small" :disabled="isSharpeTableEmpty" @click="descendingSort"
                             :type="descSortButtonType">{{ $t("basic.desc_sort_button") }}
                  </el-button>

                </div>
              </div>
            </div>

            <div class="flex_center_style">
              <el-button type="primary" @click="generatePortfolioSharpeRatio">
                {{ $t("basic.generate_portfolio_sharpe_button") }}
              </el-button>
            </div>
          </el-tab-pane>
          <el-tab-pane>
            <span slot="label" style="font-weight: bold">{{ $t('basic.system_recommended_weight_allocation') }}</span>
            <div class="expected-data-display"
                 v-loading="multipleCalFlag"
                 :element-loading-text="$t('basic.wait_a_minute_tips')">
              <!--              <h3>{{ $t("basic.system_recommended_weight_allocation") }}</h3>-->
              <div class="data-display">
                <!-- 五组数据以行排列 -->
                <el-row :gutter=10 class="data-display-row">
                  <!-- 第一组数据 -->

                  <ShowcalResult
                      :sum-equity-yield-data="sumOfEquityYieldDatas.sumOfEquityYieldData1"
                      :equity-return="expectedEquityReturns.expectedReturn1"
                      :equity-volatility="expectedEquityVolatility.expectedVolatility1"
                      :equity-sharpe-ratio="equitySharpeRatios.sharpeRatio1"
                      :sum-weighted-yield-data="sumOfWeightedYieldDatas.sumOfWeightedYieldData1"
                      :weighted-return="expectedReturns.expectedReturn1"
                      :weighted-volatility="expectedVolatility.expectedVolatility1"
                      :weighted-sharpe-ratio="sharpeRatios.sharpeRatio1"
                      :start-date="table1StartDate"
                      :end-date="table1EndDate"
                      number-of-table="①"
                      :table-data="stockTables.tableData1"
                  ></ShowcalResult>

                  <!-- 添加第二数据，按照相同的结构 -->
                  <ShowcalResult
                      :sum-equity-yield-data="sumOfEquityYieldDatas.sumOfEquityYieldData2"
                      :equity-return="expectedEquityReturns.expectedReturn2"
                      :equity-volatility="expectedEquityVolatility.expectedVolatility2"
                      :equity-sharpe-ratio="equitySharpeRatios.sharpeRatio2"
                      :sum-weighted-yield-data="sumOfWeightedYieldDatas.sumOfWeightedYieldData2"
                      :weighted-return="expectedReturns.expectedReturn2"
                      :weighted-volatility="expectedVolatility.expectedVolatility2"
                      :weighted-sharpe-ratio="sharpeRatios.sharpeRatio2"
                      :start-date="table2StartDate"
                      :end-date="table2EndDate"
                      number-of-table="②"
                      :table-data="stockTables.tableData2"
                  ></ShowcalResult>

                  <!--            第三组数据-->
                  <ShowcalResult
                      :sum-equity-yield-data="sumOfEquityYieldDatas.sumOfEquityYieldData3"
                      :equity-return="expectedEquityReturns.expectedReturn3"
                      :equity-volatility="expectedEquityVolatility.expectedVolatility3"
                      :equity-sharpe-ratio="equitySharpeRatios.sharpeRatio3"
                      :sum-weighted-yield-data="sumOfWeightedYieldDatas.sumOfWeightedYieldData3"
                      :weighted-return="expectedReturns.expectedReturn3"
                      :weighted-volatility="expectedVolatility.expectedVolatility3"
                      :weighted-sharpe-ratio="sharpeRatios.sharpeRatio3"
                      :start-date="table3StartDate"
                      :end-date="table3EndDate"
                      number-of-table="③"
                      :table-data="stockTables.tableData3"
                  ></ShowcalResult>

                  <!--            第四组数据-->
                  <ShowcalResult
                      :sum-equity-yield-data="sumOfEquityYieldDatas.sumOfEquityYieldData4"
                      :equity-return="expectedEquityReturns.expectedReturn4"
                      :equity-volatility="expectedEquityVolatility.expectedVolatility4"
                      :equity-sharpe-ratio="equitySharpeRatios.sharpeRatio4"
                      :sum-weighted-yield-data="sumOfWeightedYieldDatas.sumOfWeightedYieldData4"
                      :weighted-return="expectedReturns.expectedReturn4"
                      :weighted-volatility="expectedVolatility.expectedVolatility4"
                      :weighted-sharpe-ratio="sharpeRatios.sharpeRatio4"
                      :start-date="table4StartDate"
                      :end-date="table4EndDate"
                      number-of-table="④"
                      :table-data="stockTables.tableData4"
                  ></ShowcalResult>

                  <!--            第五组数据-->
                  <ShowcalResult
                      :sum-equity-yield-data="sumOfEquityYieldDatas.sumOfEquityYieldData5"
                      :equity-return="expectedEquityReturns.expectedReturn5"
                      :equity-volatility="expectedEquityVolatility.expectedVolatility5"
                      :equity-sharpe-ratio="equitySharpeRatios.sharpeRatio5"
                      :sum-weighted-yield-data="sumOfWeightedYieldDatas.sumOfWeightedYieldData5"
                      :weighted-return="expectedReturns.expectedReturn5"
                      :weighted-volatility="expectedVolatility.expectedVolatility5"
                      :weighted-sharpe-ratio="sharpeRatios.sharpeRatio5"
                      :start-date="table5StartDate"
                      :end-date="table5EndDate"
                      number-of-table="⑤"
                      :table-data="stockTables.tableData5"
                  ></ShowcalResult>

                </el-row>
              </div>
              <div class="flex_center_style" style="margin-top: 20px">
                <el-button type="primary" @click="sendStockData">{{ $t("basic.cal_weight_button") }}</el-button>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>


        <div id="step5" style="border-top: 1px solid #ebebeb;margin-top: 20px">
          <h3 style="text-align: center">{{ $t("basic.compare_title") }}</h3>
          <div class="back-test-style" id="step6">
            <div>
              <i class="el-icon-caret-right"></i>
              <el-popover
                  placement="top-start"
                  width="200"
                  trigger="hover"
                  :content="$t('basic.table_choose_description')">
                <el-button type="text" style="color: black;margin-right: 10px" slot="reference">{{
                    $t("basic.choose_table")
                  }}
                </el-button>
              </el-popover>
              <el-select v-model="tableChoice" :placeholder="$t('basic.choose_table_tips')">
                <el-option
                    v-for="item in tableChoiceOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    :disabled="item.disabled"
                >
                </el-option>
              </el-select>
            </div>

            <div>
              <i class="el-icon-caret-right"></i>
              <el-popover
                  placement="top-start"
                  width="200"
                  trigger="hover"
                  :content="$t('basic.new_date_range_description')">
                <el-button type="text" style="color: black;margin-right: 10px" slot="reference">
                  {{ $t("basic.choose_new_date") }}
                </el-button>
              </el-popover>
              <el-date-picker
                  v-model="newStartAndEndDate"
                  type="daterange"
                  align="right"
                  unlink-panels
                  range-separator="~"
                  :start-placeholder="$t('basic.choose_start_date_tips')"
                  :end-placeholder="$t('basic.choose_end_date_tips')"
                  :picker-options="newPickerOptions">
              </el-date-picker>
            </div>
          </div>

          <div style="text-align: center" id="step7">
            <span style="color: red;font-size: small;">{{ $t("basic.choose_date_notice") }}</span>
            <el-form-item style="margin-top: 10px">

              <el-button
                  v-if="stockTables.tableData1 === null || stockTables.tableData1.length === 0"
                  :disabled="true"
              >
                {{ $t("basic.no_weight_compare_button") }}
              </el-button>
              <el-button v-else-if="calYieldFlag === true" disabled type="info">
                {{ $t("basic.calculating_button") }}
              </el-button>
              <el-button
                  v-else-if="calYieldFlag === false"
                  @click="getYield"
              >
                {{ $t("basic.compare_button") }}
              </el-button>

              <el-button @click="customizeWeightVisible = true"
                         :disabled="stockTables.tableData1 === null || stockTables.tableData1.length === 0">
                {{ $t("basic.customize_weight") }}
              </el-button>
            </el-form-item>
          </div>

          <div class="back-test-data-container">
            <div style="display: flex;flex-direction: column;min-width: 1360px;align-items: center;">
              <div style="width: 70%">
                <el-row :gutter="20" style="display: flex;justify-content: space-evenly">
                  <el-col :span="8">
                    <div class="expected-data-display">
                      <el-statistic
                          group-separator=","
                          :precision="1"
                          :value="sumOfEquityYieldData"
                          :title="equityTitle"
                          suffix="%"
                      ></el-statistic>

                      <el-statistic
                          group-separator=","
                          :precision="1"
                          :value="equityBackTestVolatility"
                          :title="equity_volatility_title"
                          suffix="%"
                      ></el-statistic>

                      <el-col :span="12.5">
                        <el-statistic
                            group-separator=","
                            :precision="3"
                            decimal-separator="."
                            :value="equityBackTestSharpeRatio"
                            :title="equity_sharpe_title"
                        >
                          <template slot="prefix">
                            <i class="el-icon-s-flag" style="color: red"></i>
                          </template>
                          <template slot="suffix">
                            <i class="el-icon-s-flag" style="color: red"></i>
                          </template>
                        </el-statistic>
                      </el-col>
                    </div>
                  </el-col>

                  <el-col :span="8">
                    <div class="expected-data-display">
                      <el-statistic
                          group-separator=","
                          :precision="1"
                          :value="sumOfWeightedYieldData"
                          :title="weightedTitle + ' (' + $t('basic.weight_limit_tips') + currentWeightLimit + '%)'"
                          suffix="%"
                      ></el-statistic>

                      <el-statistic
                          group-separator=","
                          :precision="1"
                          :value="weightedBackTestVolatility"
                          :title="weighted_volatility_title"
                          suffix="%"
                      ></el-statistic>

                      <el-col :span="12.5">
                        <el-statistic
                            group-separator=","
                            :precision="3"
                            decimal-separator="."
                            :value="weightedBackTestSharpeRatio"
                            :title="weighted_sharpe_title"
                        >
                          <template slot="prefix">
                            <i class="el-icon-s-flag" style="color: blue"></i>
                          </template>
                          <template slot="suffix">
                            <i class="el-icon-s-flag" style="color: blue"></i>
                          </template>
                        </el-statistic>
                      </el-col>
                    </div>
                  </el-col>

                  <el-col :span="8">
                    <div class="expected-data-display">
                      <el-statistic
                          group-separator=","
                          :precision="1"
                          :value="sumOfCustomWeightsYieldData"
                          :title="$t('basic.custom_weight_returns_title')"
                          suffix="%"
                      ></el-statistic>

                      <el-statistic
                          group-separator=","
                          :precision="1"
                          :value="customBackTestVolatility"
                          :title="$t('basic.custom_weight_volatility_title')"
                          suffix="%"
                      ></el-statistic>

                      <el-col :span="12.5">
                        <el-statistic
                            group-separator=","
                            :precision="3"
                            decimal-separator="."
                            :value="customBackTestSharpeRatio"
                            :title="$t('basic.custom_weight_sharpe_ratio_title')"
                        >
                          <template slot="prefix">
                            <i class="el-icon-s-flag" style="color: red"></i>
                          </template>
                          <template slot="suffix">
                            <i class="el-icon-s-flag" style="color: red"></i>
                          </template>
                        </el-statistic>
                      </el-col>
                    </div>
                  </el-col>
                </el-row>
              </div>

              <div style="display: flex;width: 70%">
                <el-table
                    :data="yieldData"
                    height="400px"
                    border
                    style="">
                  <el-table-column
                      prop="newDatePeriod"
                      :label="$t('basic.compare_date')"
                      width="180">
                  </el-table-column>
                  <el-table-column
                      prop="equityYield"
                      :label="$t('basic.compare_mean_weighted_return')"
                      width="">
                  </el-table-column>
                  <el-table-column
                      prop="weightedYield"
                      :label="$t('basic.compare_weighted_return') + '（' + $t('basic.weight_limit_tips') + currentWeightLimit + '%）'">
                  </el-table-column>
                </el-table>

                <el-popover
                    placement="top-start"
                    width="200"
                    trigger="hover"
                    :content="$t('basic.portfolio_yield_detail')">
                  <el-button
                      id="step8"
                      slot="reference"
                      type="text"
                      icon="el-icon-search"
                      size=""
                      style="width: 30px;height: 30px;margin-left: 10px"
                      @click="yieldDialogVisible"
                      :disabled="yieldData.length === 0"
                  >
                  </el-button>
                </el-popover>
              </div>
            </div>
          </div>


        </div>
      </el-form>

      <el-dialog
          :title="$t('basic.customize_weight')"
          :visible.sync="customizeWeightVisible"
          width="30%"
          :close-on-click-modal="false"
          center>

        <span>{{ $t("basic.remain_weight") + ": " + remainAssignableWeight.toFixed(1) }}</span>

        <div class="customize-weight-list-style">
          <div class="customize-weight-style" v-for="(code,codeIndex) in stockCodesArr" v-bind:key="codeIndex">
            <span>{{ code }}</span>
            <el-slider
                v-model="weightAllocationArr[codeIndex]"
                show-input
                :step="0.1"
                :max="remainAssignableWeight + weightAllocationArr[codeIndex]"
                @input="remainAssignableWeightChange(codeIndex)"
            >
            </el-slider>
          </div>
        </div>


        <span slot="footer" class="dialog-footer">
              <el-button @click="remainAssignableWeightCancel">{{ $t("basic.cancel_button_text") }}</el-button>
              <el-button @click="remainAssignableWeightReset">{{ $t("basic.reset_text") }}</el-button>
              <el-button type="primary"
                         @click="remainAssignableWeightConfirm">{{ $t("basic.confirm_button_text") }}</el-button>
            </span>
      </el-dialog>

      <el-dialog
          :title="$t('basic.portfolio_yield_detail')"
          :visible.sync="yieldDetailDialogVisible"
          width="50%"
          :close-on-click-modal="false"
          center>
        <el-table
            ref="yieldTableLayout"
            :data="yieldDetailData"
            stripe
            height="400px"
        >
          <el-table-column
              prop="code"
              :label="$t('basic.stock_codes')"
          ></el-table-column>
          <el-table-column
              prop="sumOfSecurity"
              :label="$t('basic.total_returns')"
          >
          </el-table-column>
          <el-table-column
              prop="sumOfWeightedSecurity"
              :label="$t('basic.total_weighted_returns')"
          >
          </el-table-column>
          <el-table-column
              prop="securityDetail"
              fixed="right"
              align="center"
              :label="$t('basic.operation')"
              width="100px">
            <template v-slot="scope">
              <el-button type="text" size="small" @click="viewDetail(scope.row)">{{ $t("basic.view_details") }}
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <span slot="footer" class="dialog-footer">
              <el-button @click="yieldDetailDialogVisible = false">{{ $t("basic.cancel_button_text") }}</el-button>
              <el-button type="primary" @click="yieldDetailDialogVisible = false">{{
                  $t("basic.confirm_button_text")
                }}</el-button>
            </span>
      </el-dialog>

      <el-dialog width="30%" :title="$t('basic.stock_yield_detail')" :visible.sync="stockYieldDetailDialogVisible">
        <el-table
            :data="stockYieldDetailData"
            stripe
        >
          <el-table-column
              prop="date"
              :label="$t('basic.date_text')"
          ></el-table-column>
          <el-table-column
              prop="monthly_returns"
              :label="$t('basic.actual_returns')"
          ></el-table-column>
          <el-table-column
              prop="monthly_weighted_returns"
              :label="$t('basic.after_weight_returns')"
          ></el-table-column>
        </el-table>
      </el-dialog>

      <el-dialog :visible.sync="showOverlayDialog">
        <span>{{ $t("basic.cover_table") }}：</span>
        <el-radio-group v-model="selectedTable">
          <el-radio :label="1">{{ $t("basic.cover_table1") }}</el-radio>
          <el-radio :label="2">{{ $t("basic.cover_table2") }}</el-radio>
          <el-radio :label="3">{{ $t("basic.cover_table3") }}</el-radio>
          <el-radio :label="4">{{ $t("basic.cover_table4") }}</el-radio>
          <el-radio :label="5">{{ $t("basic.cover_table5") }}</el-radio>
        </el-radio-group>
        <el-button @click="confirmOverlay">{{ $t("basic.cover_table_button") }}</el-button>
      </el-dialog>


    </div>
    <FloatingMenu>
      <div class="menu-item" @click="clearTables">{{ $t("basic.clear_all_button") }}</div>

      <el-tooltip placement="left" effect="light" :content="$t('basic.one_click_tips')">
        <div v-if="!multipleCalFlag && !sharpeCalFlag" class="menu-item" @click="calSharpeAndWeights">
          {{ $t("basic.one_click_generation_button") }}
        </div>
        <div v-else class="menu-item" style="cursor: not-allowed">{{ $t("basic.calculating_button") }}</div>
      </el-tooltip>
    </FloatingMenu>
  </LayoutContainer>

</template>

<script>
// @ is an alias to /src
import TextInput from '@/components/Home.vue'
import moment from "moment";
import login from "./login";
import LayoutContainer from "@/components/layout/LayoutContainer";
import ShowcalResult from "@/components/component/ShowcalResult";
import ShowSharpeRatioTable from "@/components/component/ShowSharpeRatioTable";
import FloatingMenu from "@/components/menu/FloatingMenu";

export default {

  components: {
    FloatingMenu,
    ShowSharpeRatioTable,
    ShowcalResult,
    LayoutContainer,
    TextInput,

  },
  data() {
    return {
      isCollapse: true,
      customizeWeightVisible: false,
      yieldDetailDialogVisible: false,
      stockYieldDetailDialogVisible: false,
      portfolioSharpeRatioVisible: false,
      isSharpeTableEmpty: true,
      multipleCalFlag: false,
      sharpeCalFlag: false,
      calYieldFlag: false,
      inputHasError: false,

      username: '',
      stockCodes: '',

      stockCodesArr: [],
      weightAllocationArr: [],
      startAndEndDate: [],
      tableChoiceOptions: [],
      records: [],
      stockYieldList: [],
      yieldDetailData: [],
      stockYieldDetailData: [],

      tempSharpeTableData: null,
      portfolioSharpeRatioData: {
        sharpe_since_listing: [],
        five_year_sharpe: [],
        three_year_sharpe: [],
        one_year_sharpe: [],
      },

      state1: '',
      startDateValue: '',
      endDateValue: '',
      numberOfStock: 0,
      sharpeTableSortOrder: 'none',
      ascSortButtonType: '',
      descSortButtonType: '',

      language: sessionStorage.getItem("theLanguage"),


      indicators: 'CLOSE',
      datePeriod: 1,
      adjustFlag: 3,
      secuType: '',
      curType: 1,
      market: 'CNSESH',
      weightLimit: 40,
      currentWeightLimit: '--',

      stockData: '',
      remainAssignableWeight: 100,

      //临时数据
      tempSumOfWeighted: "",
      tempExpectedReturn: "",
      tempExpectedVolatility: "",
      tempSharpeRatio: "",

      tempSumOfEquity: "",
      tempExpectedEquityReturns: "",
      tempExpectedEquityVolatility: "",
      tempEquitySharpeRatio: "",
      tempStartDate: "",
      tempEndDate: "",
      tempStockNames: [],
      tempWeightAllocation: [],

      equity_yield_title: this.$t("basic.mean_weighted_interval_total_return"),
      equity_return_title: this.$t("basic.weighted_return"),
      equity_volatility_title: this.$t("basic.weighted_volatility"),
      equity_sharpe_title: this.$t("basic.weighted_sharpe"),

      weighted_yield_title: this.$t("basic.weighted_interval_total_return"),
      weighted_return_title: this.$t("basic.weighted_return_with_premium"),
      weighted_sharpe_title: this.$t("basic.sharpe_with_premium"),
      weighted_volatility_title: this.$t("basic.weighted_volatility_with_premium"),

      equityTitle: this.$t("basic.mean_weighted_interval_total_return"),
      weightedTitle: this.$t("basic.weighted_interval_total_return"),


      expectedReturns: {
        expectedReturn1: 0,
        expectedReturn2: 0,
        expectedReturn3: 0,
        expectedReturn4: 0,
        expectedReturn5: 0,
      },
      expectedEquityReturns: {
        expectedReturn1: 0,
        expectedReturn2: 0,
        expectedReturn3: 0,
        expectedReturn4: 0,
        expectedReturn5: 0,
      },

      expectedVolatility: {
        expectedVolatility1: 0,
        expectedVolatility2: 0,
        expectedVolatility3: 0,
        expectedVolatility4: 0,
        expectedVolatility5: 0,
      },
      expectedEquityVolatility: {
        expectedVolatility1: 0,
        expectedVolatility2: 0,
        expectedVolatility3: 0,
        expectedVolatility4: 0,
        expectedVolatility5: 0,
      },

      sharpeRatios: {
        sharpeRatio1: 0,
        sharpeRatio2: 0,
        sharpeRatio3: 0,
        sharpeRatio4: 0,
        sharpeRatio5: 0,
      },
      equitySharpeRatios: {
        sharpeRatio1: 0,
        sharpeRatio2: 0,
        sharpeRatio3: 0,
        sharpeRatio4: 0,
        sharpeRatio5: 0,
      },

      sumOfEquityYieldDatas: {
        sumOfEquityYieldData1: 0,
        sumOfEquityYieldData2: 0,
        sumOfEquityYieldData3: 0,
        sumOfEquityYieldData4: 0,
        sumOfEquityYieldData5: 0,
      },
      sumOfWeightedYieldDatas: {
        sumOfWeightedYieldData1: 0,
        sumOfWeightedYieldData2: 0,
        sumOfWeightedYieldData3: 0,
        sumOfWeightedYieldData4: 0,
        sumOfWeightedYieldData5: 0,
      },

      weightAllocations: {
        weightAllocation1: [],
        weightAllocation2: [],
        weightAllocation3: [],
        weightAllocation4: [],
        weightAllocation5: [],
      },

      numbersOfTable: ['①', '②', '③', '④', '⑤'],

      stockNamesList: {
        stockNames1: '',
        stockNames2: '',
        stockNames3: '',
        stockNames4: '',
        stockNames5: '',
      },

      weightLimits: {
        weightLimit1: '',
        weightLimit2: '',
        weightLimit3: '',
        weightLimit4: '',
        weightLimit5: '',
      },

      stockTables: {
        tableData1: [],
        tableData2: [],
        tableData3: [],
        tableData4: [],
        tableData5: [],
      },


      yieldData: [],
      newStartAndEndDate: [],
      newStartDateValue: '',
      newEndDateValue: '',
      /**
       * 回测功能的均权总收益率
       */
      sumOfEquityYieldData: 0,
      /**
       * 回测功能的优权总收益率
       */
      sumOfWeightedYieldData: 0,
      /**
       * 回测功能的自定义权重总收益率
       */
      sumOfCustomWeightsYieldData: 0,
      equityBackTestVolatility: 0,
      weightedBackTestVolatility: 0,
      customBackTestVolatility: 0,
      equityBackTestSharpeRatio: 0,
      weightedBackTestSharpeRatio: 0,
      customBackTestSharpeRatio: 0,

      table1StartDate: '',
      table1EndDate: '',
      table2StartDate: '',
      table2EndDate: '',
      table3StartDate: '',
      table3EndDate: '',
      table4StartDate: '',
      table4EndDate: '',
      table5StartDate: '',
      table5EndDate: '',
      showOverlayDialog: false, // 是否显示覆盖提示框
      selectedTable: 1, // 用户选择的表格
      tableChoice: '',

      customizeWeightAllocation: [],

      newPickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: this.$t("basic.year_to_date").toString(),
            onClick: (picker) => {
              const today = new Date();
              const date = new Date(today.getFullYear(), 0, 1);
              picker.$emit('pick', [date, today]);
            }
          }
        ]
      },

      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [

          {
            text: this.$t("basic.five_years_prev_trading").toString(),
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 365 * 5);
              const today = new Date();
              picker.$emit('pick', [date, today]);
            }
          },
          {
            text: this.$t("basic.three_years_prev_trading").toString(),
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 365 * 3);
              const today = new Date();
              picker.$emit('pick', [date, today]);
            }
          },
          {
            text: this.$t("basic.one_year_prev_trading").toString(),
            onClick: (picker) => {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 365);
              const today = new Date();
              picker.$emit('pick', [date, today]);
            }
          },

          {
            text: this.$t("basic.three_years_prev_month").toString(),
            onClick: (picker) => {
              const today = new Date();
              const oldYear = today.getFullYear() - 3;

              // 计算上个月的最后一天
              const lastMonthLastDay = new Date(today.getFullYear(), today.getMonth(), 0);

              // 设置开始日期（上个月的第一天）
              const startDate = new Date(oldYear, lastMonthLastDay.getMonth() + 1, 0);

              // 设置结束日期（上个月的最后一天往前一年）
              const endDate = new Date(today.getFullYear(), lastMonthLastDay.getMonth(), lastMonthLastDay.getDate());

              picker.$emit('pick', [startDate, endDate]);
            }
          },
          {
            text: this.$t("basic.one_year_prev_month").toString(),
            onClick: (picker) => {
              const today = new Date();
              const lastYear = today.getFullYear() - 1;

              // 计算上个月的最后一天
              const lastMonthLastDay = new Date(today.getFullYear(), today.getMonth(), 0);

              // 设置开始日期（上个月的第一天）
              const startDate = new Date(lastYear, lastMonthLastDay.getMonth() + 1, 0);

              // 设置结束日期（上个月的最后一天往前一年）
              const endDate = new Date(today.getFullYear(), lastMonthLastDay.getMonth(), lastMonthLastDay.getDate());

              picker.$emit('pick', [startDate, endDate]);
            }
          },
          {
            text: this.$t("basic.entire_five_years").toString(),
            onClick(picker) {
              const today = new Date();
              const lastYear = today.getFullYear() - 5;

              // 设置开始日期（前一年的1月1日）
              const startDate = new Date(lastYear, 0, 1); // 0 表示 1 月

              // 设置结束日期（前一年的12月31日）
              const endDate = new Date(lastYear + 4, 11, 31); // 11 表示 12 月

              picker.$emit('pick', [startDate, endDate]);
            }
          },
          {
            text: this.$t("basic.entire_three_years").toString(),
            onClick(picker) {
              const today = new Date();
              const lastYear = today.getFullYear() - 3;

              // 设置开始日期（前一年的1月1日）
              const startDate = new Date(lastYear, 0, 1); // 0 表示 1 月

              // 设置结束日期（前一年的12月31日）
              const endDate = new Date(lastYear + 2, 11, 31); // 11 表示 12 月

              picker.$emit('pick', [startDate, endDate]);
            }
          },
          {
            text: this.$t("basic.entire_one_year").toString(),
            onClick(picker) {
              const today = new Date();
              const lastYear = today.getFullYear() - 1;

              // 设置开始日期（前一年的1月1日）
              const startDate = new Date(lastYear, 0, 1); // 0 表示 1 月

              // 设置结束日期（前一年的12月31日）
              const endDate = new Date(lastYear, 11, 31); // 11 表示 12 月
              picker.$emit('pick', [startDate, endDate]);
            }
          },

        ]
      },

      portfolioTypeOptions: [{
        value: 1,
        label: this.$t("basic.portfolio_type_stock")
      },
        // {
        //   value: '2',
        //   label: this.$t("basic.portfolio_type_bond")
        // },
        {
          value: 3,
          label: this.$t("basic.portfolio_type_fund")
        },
        // {
        //   value: '5',
        //   label: this.$t("basic.portfolio_type_index")
        // },
      ],

      adjustFlagOptions: [{
        value: 1,
        label: this.$t("basic.no_adjustment")
      }, {
        value: 2,
        label: this.$t("basic.backward_adjustment")
      }, {
        value: 3,
        label: this.$t("basic.forward_adjustment")
      },],

      periodOptions: [{
        value: 1,
        label: this.$t("basic.daily_closing_price")
      }, {
        value: 2,
        label: this.$t("basic.weekly_closing_price")
      }, {
        value: 3,
        label: this.$t("basic.monthly_closing_price")
      }, {
        value: 4,
        label: this.$t("basic.yearly_closing_price")
      },],

      introOption: { // 参数对象
        prevLabel: this.$t("basic.back_text"),
        nextLabel: this.$t("basic.next_text"),
        skipLabel: this.$t("basic.skip_text"),
        doneLabel: this.$t("basic.done_text"),
        tooltipClass: 'intro-tooltip', /* 引导说明文本框的样式 */
        // highlightClass: 'intro-highlight', /* 说明高亮区域的样式 */
        exitOnEsc: true, /* 是否使用键盘Esc退出 */
        exitOnOverlayClick: false, /* 是否允许点击空白处退出 */
        keyboardNavigation: true, /* 是否允许键盘来操作 */
        showBullets: false, /* 是否使用点显示进度 */
        showProgress: false, /* 是否显示进度条 */
        scrollToElement: true, /* 是否滑动到高亮的区域 */
        overlayOpacity: 0.5, // 遮罩层的透明度 0-1之间
        positionPrecedence: ['bottom', 'top', 'right', 'left'], /* 当位置选择自动的时候，位置排列的优先级 */
        disableInteraction: false, /* 是否禁止与元素的相互关联 */
        hidePrev: true, /* 是否在第一步隐藏上一步 */
        // hideNext: true, /* 是否在最后一步隐藏下一步 */
        steps: [], /* steps步骤，可以写个工具类保存起来 */
      },
      // tipsImg1: require('./img/tips1.jpg'), // 新手引导的提示图片
      // tipsImg2: require('./img/tips2.jpg'), // 新手引导的提示图片
    }

  },

  watch: {

    startAndEndDate(newDates) {
      if (newDates && newDates.length === 2) {
        this.startDateValue = moment(newDates[0]).format('yyyy-MM-DD'); // 更新开始日期
        this.endDateValue = moment(newDates[1]).format('yyyy-MM-DD'); // 更新结束日期
      }
    },

    sharpeTableSortOrder(newVal) {
      this.ascSortButtonType = newVal === 'asc' ? 'primary' : '';
      this.descSortButtonType = newVal === 'desc' ? 'primary' : '';
    },

    portfolioSharpeRatioData: {
      handler(newVal) {
        // 遍历portfolioSharpeRatioData的每个数组，只要有一个不为空，就将isShapreDataEmpty设为false
        for (let key in newVal) {
          if (newVal[key].length > 0) {
            this.isSharpeTableEmpty = false;
            return;
          }
        }
        // 如果所有数组都为空，则将isShapreDataEmpty设为true
        this.isSharpeTableEmpty = true;
      },
      deep: true, // 深度监听对象内部值的变化
      immediate: true // 立即执行一次handler
    },

    // 监听tableData的变化，变化时更新tableChoiceOptions
    stockTables: {
      handler: 'updateTableChoiceOptions',
      deep: true,
    },
  },

  computed: {
    tableData1IsEmpty() {
      return this.isTableEmpty(this.stockTables.tableData1);
    },
    tableData2IsEmpty() {
      return this.isTableEmpty(this.stockTables.tableData2);
    },
    tableData3IsEmpty() {
      return this.isTableEmpty(this.stockTables.tableData3);
    },
    tableData4IsEmpty() {
      return this.isTableEmpty(this.stockTables.tableData4);
    },
    tableData5IsEmpty() {
      return this.isTableEmpty(this.stockTables.tableData5)
    }
  },

  created() {
    this.username = sessionStorage.getItem('username');

    this.tableChoiceOptions = [
      {
        value: 1,
        label: this.$t("basic.base_on_table1"),
        disabled: this.isTableEmpty(this.stockTables.tableData1)
      },
      {
        value: 2,
        label: this.$t("basic.base_on_table2"),
        disabled: this.isTableEmpty(this.stockTables.tableData2)
      },
      {
        value: 3,
        label: this.$t("basic.base_on_table3"),
        disabled: this.isTableEmpty(this.stockTables.tableData3)
      },
      {
        value: 4,
        label: this.$t("basic.base_on_table4"),
        disabled: this.isTableEmpty(this.stockTables.tableData4),
      },
      {
        value: 5,
        label: this.$t("basic.base_on_table5"),
        disabled: this.isTableEmpty(this.stockTables.tableData5),
      },
      {
        value: 6,
        label: this.$t("basic.customize_weight"),
        disabled: this.weightAllocationArr.length === 0,
      }
    ];

  },


  mounted() {
    this.records = this.loadAll();

    this.$nextTick(() => {
      if (localStorage.getItem('isFirst') === null || localStorage.getItem('isFirst') !== '1') {
        this.initGuide(); // 调用新手引导的方法
        localStorage.setItem('isFirst', 1);
      }

    })
  },

  methods: {
    // 一键生成夏普比率和系统建议
    async calSharpeAndWeights() {
      if (this.confirmIsFormComplete()) {
        return;
      }

      this.clearTables();
      this.multipleCalFlag = true;
      this.sharpeCalFlag = true;
      await this.generatePortfolioSharpeRatio();
      this.sendMultipleStockData();
    },

    initGuide() {
      // 绑定标签元素的选择器数组
      this.introOption.steps = [
        {
          title: this.$t("basic.code_input_text").toString(),
          element: '#step1',
          intro:
              this.$t("basic.code_input_description").toString(),
        },
        {
          title: this.$t("basic.portfolio_type_choose_text").toString(),
          element: '#step2',
          intro: this.$t("basic.portfolio_type_choose_description").toString(),
        },
        {
          title: this.$t("basic.date_range_choose_text").toString(),
          element: '#step3',
          intro: this.$t("basic.date_range_choose_description").toString(),
        },
        {
          title: this.$t("basic.weight_limit_text").toString(),
          element: '#step4',
          intro: this.$t("basic.weight_limit_text_description").toString(),
        },
        {
          title: this.$t("basic.backtesting_text").toString(),
          element: '#step5',
          intro: this.$t("basic.backtesting_description").toString(),
        },
        {
          title: this.$t("basic.data_and_date_range_choose_text").toString(),
          element: '#step6',
          intro: this.$t("basic.data_and_date_range_choose_description").toString(),
        },
        {
          title: this.$t("basic.customize_weight_text").toString(),
          element: '#step7',
          intro: this.$t("basic.customize_weight_description").toString(),
        },
        {
          title: this.$t("basic.view_yield_detail_text").toString(),
          element: '#step8',
          intro: this.$t("basic.view_yield_detail_description").toString(),
        },

        {
          title: this.$t("basic.reboot_text").toString(),
          element: '#step9',
          intro: this.$t("basic.reboot_description").toString(),
        },
      ]
      this.$intro()
          .setOptions(this.introOption)
          // 点击结束按钮后执行的事件
          .oncomplete(() => {
            console.log('点击结束按钮后执行的事件')
          })
          // 点击跳过按钮后执行的事件
          .onexit(() => {
            console.log('点击跳过按钮后执行的事件')
          })
          // 确认完毕之后执行的事件
          .onbeforeexit(() => {
            console.log('确认完毕之后执行的事件')
          })
          .start()
    },

    viewIntro() {
      this.initGuide();
    },

    resizeTableLayout() {
      this.$nextTick(() => {
        this.$refs.yieldTableLayout.doLayout();
      })
    },

    choiceLogin() {
      const requestData = {
        username: "gtja4467",
        password: "oy875880"
      }

      this.$http.post("/choiceLogin", requestData, {
        headers: {
          'Authorization': sessionStorage.getItem("token") // 替换 yourToken 为实际的 token 值
        }
      }).then(response => {
        console.log(response)
        if (response.data.code === 1) {
          this.$message.info(response.data.message)
        } else {
          this.$message.error(response.data.message)
        }
      }).catch(error => {
        console.error(error)
      })
    },

    choiceStop() {
      this.$http.get("/choiceStop",{
        headers: {
          'Authorization': sessionStorage.getItem("token") // 替换 yourToken 为实际的 token 值
        }
      }).then(response => {
        console.log(response)
        if (response.data.code === 1) {
          this.$message.info(response.data.message)
        } else {
          this.$message.error(response.data.message)
        }
      }).catch(error => {
        console.error(error)
      })
    },

    signOut() {
      sessionStorage.setItem("username", '');
      sessionStorage.setItem("isLoggedIn", '');
      this.$message.success(this.$t("basic.sign_out_success").toString());
      this.$router.push('/login');
    },

    changeLang() {
      sessionStorage.setItem("theLanguage", this.language);
      this.$router.go(0); //刷新当前页面
    },

    clearTables() {
      this.clearPortfolioSharpeData();

      for (let i = 1; i <= 5; i++) {
        this.stockTables["tableData" + i] = [];
        this.expectedReturns["expectedReturn" + i] = 0;
        this.expectedVolatility["expectedVolatility" + i] = 0;
        this.sharpeRatios["sharpeRatio" + i] = 0;
        this.sumOfWeightedYieldDatas["sumOfWeightedYieldData" + i] = 0;

        this.expectedEquityReturns["expectedReturn" + i] = 0;
        this.expectedEquityVolatility["expectedVolatility" + i] = 0;
        this.equitySharpeRatios["sharpeRatio" + i] = 0;
        this.sumOfEquityYieldDatas["sumOfEquityYieldData" + i] = 0;
        this['table' + i + 'StartDate'] = '';
        this['table' + i + 'EndDate'] = '';
      }

    },

    querySearchRecords(queryString, cb) {
      var results = queryString ? this.records.filter(this.createFilter(queryString)) : this.records;
      cb(results)
    },

    createFilter(queryString) {
      return (records) => {
        return (records.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0)
      }
    },

    collapseNav() {
      this.isCollapse = !this.isCollapse;
    },

    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },

    loadAll() {
      let recordData = [];
      this.$http.get("/getRecordByToken", {
        headers: {
          'Authorization': sessionStorage.getItem("token") // 替换 yourToken 为实际的 token 值
        }
      }).then(response => {
        console.log(response);
        this.records = response.data.map((item) => ({value: item.recordContent}));
      }).catch(error => {
        console.error(error)
      })
      return recordData;
    },

    handleSelect(item) {
      console.log(item);
    },

    isTableEmpty(tableData) {
      if (tableData.length === 0 || tableData === null) {
        return true;
      } else return false;
    },

    updateTableChoiceOptions() {
      this.tableChoiceOptions = [
        {
          value: 1,
          label: this.$t("basic.base_on_table1"),
          disabled: this.isTableEmpty(this.stockTables.tableData1)
        },
        {
          value: 2,
          label: this.$t("basic.base_on_table2"),
          disabled: this.isTableEmpty(this.stockTables.tableData2)
        },
        {
          value: 3,
          label: this.$t("basic.base_on_table3"),
          disabled: this.isTableEmpty(this.stockTables.tableData3)
        },
        {
          value: 4,
          label: this.$t("basic.base_on_table4"),
          disabled: this.isTableEmpty(this.stockTables.tableData4),
        },
        {
          value: 5,
          label: this.$t("basic.base_on_table5"),
          disabled: this.isTableEmpty(this.stockTables.tableData5),
        },
        {
          value: 6,
          label: '自定义权重',
          disabled: this.weightAllocationArr.length === 0,
        }
      ];
    },


    /**
     * 得到股票数据
     */
    sendStockData() {
      if (this.stockCodes.endsWith(',')) {
        // 去除末尾的逗号
        this.stockCodes = this.stockCodes.slice(0, -1);
      }

      if (this.confirmIsFormComplete()) {
        return;
      }

      this.multipleCalFlag = true;
      // 构建请求参数
      const requestData = {
        codes: this.stockCodes,
        secuType: this.secuType,
        startDate: moment(this.startAndEndDate[0]).format('YYYY-MM-DD'),
        endDate: moment(this.startAndEndDate[1]).format('YYYY-MM-DD'),
        indicators: this.indicators,
        period: this.datePeriod,
        adjustFlag: this.adjustFlag,
        curType: this.curType,
        market: this.market,
        weightLimit: String(this.weightLimit / 100),
      };

      this.$http
          .get('/getStockData', {
            headers: {
              'Authorization': sessionStorage.getItem("token") // 替换 yourToken 为实际的 token 值
            },
            params: requestData,
          })
          .then((response) => {
            console.log(response);
            this.multipleCalFlag = false;
            debugger
            if ('ERROR_MESSAGE' in response.data || response.data.hasOwnProperty('ERROR_MESSAGE')) {
              if (response.data.ERROR_MESSAGE === 'ILLEGAL_CODE_ERROR') {
                response.data.ERROR_CODE = response.data.ERROR_CODE.replace('[', ' "').replace(']', '" ')
                this.$message.error({
                  message: response.data.ERROR_CODE + this.$t("basic.unrecognized_code_err").toString(),
                  duration: 5000,
                });
              } else if (response.data.ERROR_MESSAGE === 'NO_VALUE_ERROR') {
                this.$message.error({
                  message: this.$t("basic.no_data_err").toString() + response.data.ERROR_CODE,
                  duration: 5000,
                });

              } else if (response.data.ERROR_MESSAGE === '10003004EQERR_START_DATE_ERR') {
                this.$message.error(this.$t("basic.date_filling_err").toString());
              } else if (response.data.ERROR_MESSAGE === 'NO_DATA_ERR') {
                this.$message.error(this.$t("basic.no_data_err").toString());
              } else if (response.data.ERROR_MESSAGE === '10001001EQERR_NO_LOGIN') {
                this.$message.error(this.$t("basic.no_login_err").toString());
              } else if (response.data.ERROR_MESSAGE === '10003008EQERR_CODE_INVALIED') {
                this.$message.error(this.$t("basic.code_invalid_err").toString());
              } else if (typeof response.data.ERROR_MESSAGE === 'string' && response.data.ERROR_MESSAGE.includes('ERR')) {
                this.$message.error(response.data.ERROR_MESSAGE);
              } else if ('RESULT_DATA' in response.data && (response.data.RESULT_DATA === null || response.data.RESULT_DATA.length === 0)) {
                this.$message.error(this.$t("basic.no_data_msg").toString());
              }
            } else {
              this.$message.success(this.$t("basic.cal_success").toString());
              this.stockData = response.data['RESULT_DATA'];

              const responseData = {
                data: JSON.parse(response.data['RESULT_DATA']),
              };

              // 遍历五个表格，找到为空的表格
              let foundEmptyTable = false;
              for (let tableKey = 1; tableKey <= 5; tableKey++) {
                const tableData = this.stockTables['tableData' + tableKey];

                if (tableData.length === 0) {
                  //找到空表格，插入数据
                  this.sortAndStoreData(responseData)
                  foundEmptyTable = true;
                  break; // 找到空表格后退出循环
                }
              }

              if (!foundEmptyTable) {
                // 如果没有找到空表格，存入临时变量
                this.showOverlayDialog = true;
                debugger
                this.stockData = JSON.parse(this.stockData);
                this.tempSumOfWeighted = this.stockData.sum_of_all_weighted_yield;
                this.tempExpectedReturn = this.stockData.returns;
                this.tempExpectedVolatility = this.stockData.volatility;
                this.tempSharpeRatio = this.stockData.sharpe;

                this.tempSumOfEquity = this.stockData.sum_of_all_equity_yield;
                this.tempExpectedEquityReturns = this.stockData.equity_returns;
                this.tempExpectedEquityVolatility = this.stockData.equity_volatility;
                this.tempEquitySharpeRatio = this.stockData.equity_sharpe;
                this.tempWeightAllocation = this.stockData.weight_allocation;
                this.tempStockNames = this.stockData.stock_names;
                this.tempStartDate = moment(this.startDateValue).format('YYYY-MM-DD');
                this.tempEndDate = moment(this.endDateValue).format('YYYY-MM-DD');
              } else {
                //如果找到空表格，执行排序和存储数据

              }

              this.multipleCalFlag = false;
            }
          })
          .catch((error) => {
            console.error(error);
            this.multipleCalFlag = false;
          });
    },

    /**
     * 一键生成上个交易日往前五年、三年、一年，上个月末往前三年、一年的数据并导入表格中
     */
    sendMultipleStockData() {
      if (this.confirmIsFormComplete()) {
        return;
      }
      // this.clearTables();
      const dateRange = [];
      this.multipleCalFlag = true;

      // 构建请求参数
      const requestData = {
        codes: this.stockCodes,
        startDate: '',
        endDate: '',
        indicators: this.indicators,
        period: this.datePeriod,
        adjustFlag: this.adjustFlag,
        curType: this.curType,
        secuType: this.secuType,
        market: this.market,
        weightLimit: String(this.weightLimit / 100),
      };

      // this.pickerOptions.shortcuts.forEach(shortcut => {
      //   //创建临时对象模拟picker对象
      //   const picker = {
      //     $emit: function (event, value) {
      //       dateRange.push(value);
      //     }
      //   };
      //   //调用快捷键的onClick函数
      //   shortcut.onClick(picker);
      // });
      debugger
      const endDate = new Date(this.endDateValue);
// 往前一年的日期
      const oneYearAgo = new Date(endDate);
      oneYearAgo.setFullYear(endDate.getFullYear() - 1);
      dateRange.push([oneYearAgo, endDate]);

// 往前三年的日期
      const threeYearsAgo = new Date(endDate);
      threeYearsAgo.setFullYear(endDate.getFullYear() - 3);
      dateRange.push([threeYearsAgo, endDate]);

// 往前五年的日期
      const fiveYearsAgo = new Date(endDate);
      fiveYearsAgo.setFullYear(endDate.getFullYear() - 5);
      dateRange.push([fiveYearsAgo, endDate]);


      //报错统计
      let resultMsg = [];

      const requests = [];

      //  循环五次拿到前五个日期选择快捷键中的日期数据，循环计算得到五组计算数据
      for (let i = 0; i < 3; i++) {
        debugger
        requestData['startDate'] = moment(dateRange[i][0]).format('YYYY-MM-DD');
        requestData['endDate'] = moment(dateRange[i][1]).format('YYYY-MM-DD');

        requests.push(
            this.$http.get('/getStockData', {
              headers: {
                'Authorization': sessionStorage.getItem("token") // 替换 yourToken 为实际的 token 值
              },
              params: requestData,
            })
        );
      }

      Promise.all(requests)
          .then((responses) => {
            const tempDataArray = [];

            //获取五组数据
            for (let i = 0; i < responses.length; i++) {
              const response = responses[i];
              console.log(response);
              if ("ERROR_MESSAGE" in response.data) {
                if (response.data['ERROR_MESSAGE'] === '10003004EQERR_START_DATE_ERR') {
                  resultMsg['result' + (i + 1)] = this.$t("basic.date_filling_err").toString();
                  // this.$message.error('日期输入错误，请重新输入');
                } else if (response.data['ERROR_MESSAGE'] === 'NO_DATA_ERR') {
                  resultMsg['result' + (i + 1)] = this.$t("basic.no_data_err").toString();
                  // this.$message.error('存在股票无数据，请尝试更改日期或交易市场！');
                } else if (response.data['ERROR_MESSAGE'] === '10001001EQERR_NO_LOGIN') {
                  resultMsg['result' + (i + 1)] = this.$t("basic.no_login_err").toString();
                  // this.$message.error('身份验证过期！请重新登录');
                } else if (response.data['ERROR_MESSAGE'] === '10003008EQERR_CODE_INVALIED') {
                  resultMsg['result' + (i + 1)] = this.$t("basic.code_invalid_err").toString();
                  // this.$message.error('股票代码输入有误！');
                } else if (typeof response.data['ERROR_MESSAGE'] === 'string' && response.data['ERROR_MESSAGE'].includes('ERR')) {
                  resultMsg['result' + (i + 1)] = response.data
                  // this.$message.error(response.data);
                }
              } else if (response.data["RESULT_DATA"] === null || response.data["RESULT_DATA"].length === 0) {
                // this.$message.error('暂无数据');
              } else {
                resultMsg['result' + (i + 1)] = this.$t("basic.cal_success").toString();
                // this.$message.success('计算完毕');
                response.data = JSON.parse(response.data["RESULT_DATA"]);
                const tempDataList = {
                  tableKey: (i + 1),
                  data: {
                    //得到的数据为区间总收益率，转换为年化收益率
                    sumOfWeightedYieldData: response.data.sum_of_all_weighted_yield * 100,
                    expectedReturn: response.data.returns * 100,
                    expectedVolatility: response.data.volatility * 100,
                    sharpeRatio: response.data.sharpe,
                    weightLimit: this.weightLimit,

                    sumOfEquityYieldData: response.data.sum_of_all_equity_yield * 100,
                    expectedEquityReturn: response.data.equity_returns * 100,
                    expectedEquityVolatility: response.data.equity_volatility * 100,
                    equitySharpeRatio: response.data.equity_sharpe,

                    stockNames: response.data.stock_names,
                    weightAllocation: response.data.weight_allocation,
                    // 更新表格时间
                    startDate: moment(dateRange[i][0]).format('YYYY-MM-DD'),
                    endDate: moment(dateRange[i][1]).format('YYYY-MM-DD'),
                  }
                }
                tempDataArray.push(tempDataList);
              }
            }

            //根据日期排序
            // 根据日期排序数据对象数组
            tempDataArray.sort((a, b) => {
              if (a.data.startDate !== b.data.startDate) {
                return new Date(a.data.startDate) - new Date(b.data.startDate);
              } else {
                return new Date(a.data.endDate) - new Date(b.data.endDate);
              }
            });

            // 将排序后的数据填充回各个表格
            for (let i = 0; i < tempDataArray.length; i++) {
              const tableData = tempDataArray[i];
              // 更新表格数据,tableDataArray已完成排序，通过循环array直接插入数据即可
              this.sumOfWeightedYieldDatas['sumOfWeightedYieldData' + (i + 1)] = tableData.data.sumOfWeightedYieldData;
              this.expectedReturns['expectedReturn' + (i + 1)] = tableData.data.expectedReturn;
              this.expectedVolatility['expectedVolatility' + (i + 1)] = tableData.data.expectedVolatility;
              this.sharpeRatios['sharpeRatio' + (i + 1)] = tableData.data.sharpeRatio;

              this.sumOfEquityYieldDatas['sumOfEquityYieldData' + (i + 1)] = tableData.data.sumOfEquityYieldData;
              this.expectedEquityReturns['expectedReturn' + (i + 1)] = tableData.data.expectedEquityReturn;
              this.expectedEquityVolatility['expectedVolatility' + (i + 1)] = tableData.data.expectedEquityVolatility;
              this.equitySharpeRatios['sharpeRatio' + (i + 1)] = tableData.data.equitySharpeRatio;

              this.stockNamesList['stockNames' + (i + 1)] = tableData.data.stockNames;
              this.weightAllocations['weightAllocation' + (i + 1)] = tableData.data.weightAllocation;
              this.weightLimits['weightLimit' + (i + 1)] = tableData.data.weightLimit;
              this['table' + (i + 1) + 'StartDate'] = tableData.data.startDate;
              this['table' + (i + 1) + 'EndDate'] = tableData.data.endDate;

              // 将数据存入表格
              for (let j = 0; j < this.stockNamesList['stockNames' + (i + 1)].length; j++) {
                const weight = (this.weightAllocations['weightAllocation' + (i + 1)][j] * 100).toFixed(1);
                this.stockTables['tableData' + (i + 1)].push({
                  stockCode: this.stockNamesList['stockNames' + (i + 1)][j],
                  weights: weight + '%',
                });
              }
            }
          }).catch(err => {
        console.error(err)
      }).finally(() => {
        this.multipleCalFlag = false;
      })
    },

    sortAndStoreData(response) {
      // 构建数据对象数组
      const tableDataArray = [];
      let tableDataCount = 0;
      debugger

      for (let tableKey = 1; tableKey <= 5; tableKey++) {
        if (this.stockTables['tableData' + tableKey].length === 0) {
          //循环到空表格，直接退出循环
          break;
        } else {
          const tableData = {
            tableKey: tableKey,
            data: {
              sumOfWeightedYieldData: this.sumOfWeightedYieldDatas['sumOfWeightedYieldData' + tableKey],
              expectedReturn: this.expectedReturns['expectedReturn' + tableKey],
              expectedVolatility: this.expectedVolatility['expectedVolatility' + tableKey],
              sharpeRatio: this.sharpeRatios['sharpeRatio' + tableKey],

              sumOfEquityYieldData: this.sumOfEquityYieldDatas['sumOfEquityYieldData' + tableKey],
              expectedEquityReturn: this.expectedEquityReturns['expectedReturn' + tableKey],
              expectedEquityVolatility: this.expectedEquityVolatility['expectedVolatility' + tableKey],
              equitySharpeRatio: this.equitySharpeRatios['sharpeRatio' + tableKey],

              stockNames: this.stockNamesList['stockNames' + tableKey],
              weightLimit: this.weightLimits['weightLimit' + tableKey],
              weightAllocation: this.weightAllocations['weightAllocation' + tableKey],
              startDate: this['table' + tableKey + 'StartDate'],
              endDate: this['table' + tableKey + 'EndDate'],
              tableData: this.stockTables['tableData' + tableKey],
            },
          };
          tableDataArray.push(tableData);
          tableDataCount += 1;
        }
      }

      if (tableDataCount !== 5) {
        //  若tableDataCount不为5，则说明表格未满，将新数据存入表格中
        debugger
        const newTableData = {
          tableKey: 0, //表示新数据
          data: {
            //得到的数据为区间总收益率，转换为年化收益率
            sumOfWeightedYieldData: response.data.sum_of_all_weighted_yield * 100,
            expectedReturn: response.data.returns * 100,
            expectedVolatility: response.data.volatility * 100,
            sharpeRatio: response.data.sharpe,
            weightLimit: this.weightLimit,

            sumOfEquityYieldData: response.data.sum_of_all_equity_yield * 100,
            expectedEquityReturn: response.data.equity_returns * 100,
            expectedEquityVolatility: response.data.equity_volatility * 100,
            equitySharpeRatio: response.data.equity_sharpe,

            stockNames: response.data.stock_names,
            weightAllocation: response.data.weight_allocation,
            // 更新表格时间
            startDate: moment(this.startDateValue).format('YYYY-MM-DD'),
            endDate: moment(this.endDateValue).format('YYYY-MM-DD'),
            tableData: [], // 未插入数据，留空
          }
        };
        tableDataArray.push(newTableData);
      }
      //若未进if语句，表示五个表格已满，表格替换工作已完成，跳过push（tableData）操作，直接对五个表格进行排序并存入tableData

      // 根据日期排序数据对象数组
      tableDataArray.sort((a, b) => {
        if (a.data.startDate !== b.data.startDate) {
          return new Date(a.data.startDate) - new Date(b.data.startDate);
        } else {
          return new Date(a.data.endDate) - new Date(b.data.endDate);
        }
      });

      // 将排序后的数据填充回各个表格
      for (let i = 0; i < tableDataArray.length; i++) {
        const tableData = tableDataArray[i];
        // 更新表格数据,tableDataArray已完成排序，通过循环array直接插入数据即可
        this.sumOfWeightedYieldDatas['sumOfWeightedYieldData' + (i + 1)] = tableData.data.sumOfWeightedYieldData;
        this.expectedReturns['expectedReturn' + (i + 1)] = tableData.data.expectedReturn;
        this.expectedVolatility['expectedVolatility' + (i + 1)] = tableData.data.expectedVolatility;
        this.sharpeRatios['sharpeRatio' + (i + 1)] = tableData.data.sharpeRatio;

        this.sumOfEquityYieldDatas['sumOfEquityYieldData' + (i + 1)] = tableData.data.sumOfEquityYieldData;
        this.expectedEquityReturns['expectedReturn' + (i + 1)] = tableData.data.expectedEquityReturn;
        this.expectedEquityVolatility['expectedVolatility' + (i + 1)] = tableData.data.expectedEquityVolatility;
        this.equitySharpeRatios['sharpeRatio' + (i + 1)] = tableData.data.equitySharpeRatio;

        this.stockNamesList['stockNames' + (i + 1)] = tableData.data.stockNames;
        this.weightAllocations['weightAllocation' + (i + 1)] = tableData.data.weightAllocation;
        this.weightLimits['weightLimit' + (i + 1)] = tableData.data.weightLimit;
        this['table' + (i + 1) + 'StartDate'] = tableData.data.startDate;
        this['table' + (i + 1) + 'EndDate'] = tableData.data.endDate;

        // 清空表格数据
        this.stockTables['tableData' + (i + 1)] = [];

        // 将数据存入表格
        for (let j = 0; j < this.stockNamesList['stockNames' + (i + 1)].length; j++) {
          const weight = (this.weightAllocations['weightAllocation' + (i + 1)][j] * 100).toFixed(1);
          this.stockTables['tableData' + (i + 1)].push({
            stockCode: this.stockNamesList['stockNames' + (i + 1)][j],
            weights: weight + '%',
          });
        }
      }
    },

    confirmOverlay() {
      // 用户确认覆盖，根据用户选择更新表格数据
      this.addDataToSelectedTable();
      // 隐藏提示框
      this.showOverlayDialog = false;
    },

    /**
     * 当五个表格都满了时，将用户选择的表格的数据覆盖为新数据
     */
    addDataToSelectedTable() {
      // 根据用户选择将新增数据添加到相应的表格
      this.stockTables["tableData" + this.selectedTable] = [];

      debugger

      this.sumOfWeightedYieldDatas['sumOfWeightedYieldData' + this.selectedTable] = this.tempSumOfWeighted * 100;
      this.expectedReturns["expectedReturn" + this.selectedTable] = this.tempExpectedReturn * 100;
      this.expectedVolatility["expectedVolatility" + this.selectedTable] = this.tempExpectedVolatility * 100;
      this.sharpeRatios["sharpeRatio" + this.selectedTable] = this.tempSharpeRatio;

      this.sumOfEquityYieldDatas["sumOfEquityYieldData" + this.selectedTable] = this.tempSumOfEquity * 100;
      this.expectedEquityReturns['expectedReturn' + this.selectedTable] = this.tempExpectedEquityReturns * 100;
      this.expectedEquityVolatility['expectedVolatility' + this.selectedTable] = this.tempExpectedEquityVolatility * 100;
      this.equitySharpeRatios['sharpeRatio' + this.selectedTable] = this.tempEquitySharpeRatio;

      this.weightLimits['weightLimit' + this.selectedTable] = this.weightLimit;
      this.stockNamesList['stockNames' + this.selectedTable] = this.tempStockNames;
      this.weightAllocations['weightAllocation' + this.selectedTable] = this.tempWeightAllocation;
      this["table" + this.selectedTable + "StartDate"] = this.tempStartDate;
      this["table" + this.selectedTable + "EndDate"] = this.tempEndDate;

      for (let i = 0; i < this.tempStockNames.length; i++) {
        this.stockTables["tableData" + this.selectedTable].push({
          stockCode: this.tempStockNames[i],
          weights: this.tempWeightAllocation[i]
        });
      }

      //调用表格排序函数
      this.sortAndStoreData();
    },

    /**
     * 生成组合个股的夏普比率
     */
    generatePortfolioSharpeRatio() {
      return new Promise((resolve, reject) => {
        if (this.confirmIsFormComplete()) {
          return;
        }

        this.clearPortfolioSharpeData()

        this.sharpeCalFlag = true;

        const requestData = {
          codes: this.stockCodes,
          startDate: moment(this.startAndEndDate[0]).format('YYYY-MM-DD'),
          endDate: moment(this.startAndEndDate[1]).format('YYYY-MM-DD'),
          indicators: this.indicators,
          period: this.datePeriod,
          adjustFlag: this.adjustFlag,
          curType: this.curType,
          secuType: this.secuType,
          market: this.market,
        }


        /**
         * 生成报告测试
         */
        this.$http.get('/generateReport', {
          params: requestData
        }).then(response => {console.log(response)})
            .catch(err=>{console.error(err)});




        this.$http.get('/sharpeRatioCalculate', {
          headers: {
            'Authorization': sessionStorage.getItem("token") // 替换 yourToken 为实际的 token 值
          },
          params: requestData
        }).then(response => {
          debugger
          console.log(response.data);

          if (this.catchAndDisplayResponseError(response)) {
            return;
          }

          let fiveYearMsg = JSON.parse(response.data.msg['five_year'])
          let threeYearMsg = JSON.parse(response.data.msg['three_year'])
          let oneYearMsg = JSON.parse(response.data.msg['one_year'])
          let listDateMsg = response.data.msg.list_date;
          let sharpeSinceListMsg = JSON.parse(response.data.msg['sharpe_since_list'])

          let codes = [];
          let listDate = [];
          let fiveYearSharpe = [];
          let threeYearSharpe = [];
          let oneYearSharpe = [];
          let sharpeSinceList = [];

          for (let i = 0; i < fiveYearMsg.length; i++) {
            codes.push(fiveYearMsg[i]['code'])

            for (let j = 0; j < listDateMsg.length; j++) {
              if (listDateMsg[j]['code'] === fiveYearMsg[i]['code']) {
                listDate.push(listDateMsg[j]['list_date']);
              }

              if (sharpeSinceListMsg[j]['code'] === fiveYearMsg[i]['code']) {
                sharpeSinceList.push(sharpeSinceListMsg[j]['sharpe_ratio'].toFixed(3));
              }
            }

            if (!isNaN(fiveYearMsg[i]['sharpe_ratio'])) {
              fiveYearSharpe.push(fiveYearMsg[i]['sharpe_ratio'].toFixed(3));
            } else {
              fiveYearSharpe.push('/');
            }
            if (!isNaN(threeYearMsg[i]['sharpe_ratio'])) {
              threeYearSharpe.push(threeYearMsg[i]['sharpe_ratio'].toFixed(3));
            } else {
              threeYearSharpe.push('/');
            }
            if (!isNaN(oneYearMsg[i]['sharpe_ratio'])) {
              oneYearSharpe.push(oneYearMsg[i]['sharpe_ratio'].toFixed(3));
            } else {
              oneYearSharpe.push('/');
            }
          }

          for (let i = 0; i < codes.length; i++) {
            this.portfolioSharpeRatioData['sharpe_since_listing'].push({
              code: codes[i],
              list_date: listDate[i],
              sharpe_since_list: sharpeSinceList[i],
            });

            this.portfolioSharpeRatioData['five_year_sharpe'].push({
              code: codes[i],
              five_year_sharpe: fiveYearSharpe[i],
            });

            this.portfolioSharpeRatioData['three_year_sharpe'].push({
              code: codes[i],
              three_year_sharpe: threeYearSharpe[i],
            });

            this.portfolioSharpeRatioData['one_year_sharpe'].push({
              code: codes[i],
              one_year_sharpe: oneYearSharpe[i],
            })
          }

          //记录原始顺序
          this.tempSharpeTableData = JSON.parse(JSON.stringify(this.portfolioSharpeRatioData));

        }).catch(err => {
          console.error(err)
        }).finally(() => {
          this.sharpeCalFlag = false;
          resolve();
        });
      })
    },

    catchAndDisplayResponseError(response) {
      if (response.data.code.includes("ERR")) {
        if (response.data.code === 'ILLEGAL_CODE_ERROR') {
          response.data.code = response.data.code.replace('[', ' "').replace(']', '" ')
          this.$message.error({
            message: response.data.code + this.$t("basic.unrecognized_code_err").toString(),
            duration: 5000,
          });
        } else if (response.data.code === 'NO_VALUE_ERROR') {
          this.$message.error({
            message: this.$t("basic.no_data_err").toString() + response.data.code,
            duration: 5000,
          });

        } else if (response.data.code === '10003004EQERR_START_DATE_ERR') {
          this.$message.error(this.$t("basic.date_filling_err").toString());
        } else if (response.data.code === 'NO_DATA_ERR') {
          this.$message.error(this.$t("basic.no_data_err").toString());
        } else if (response.data.code === '10001001EQERR_NO_LOGIN') {
          this.$message.error(this.$t("basic.no_login_err").toString());
        } else if (response.data.code === '10003008EQERR_CODE_INVALIED') {
          this.$message.error(this.$t("basic.code_invalid_err").toString());
        } else if (typeof response.data.code === 'string' && response.data.code.includes('ERR')) {
          this.$message.error(response.data.code);
        } else if ('RESULT_DATA' in response.data && (response.data.RESULT_DATA === null || response.data.RESULT_DATA.length === 0)) {
          this.$message.error(this.$t("basic.no_data_msg").toString());
        }
        return true;
      } else {
        return false;
      }
    },

    clearPortfolioSharpeData() {
      this.portfolioSharpeRatioData.sharpe_since_listing = [];
      this.portfolioSharpeRatioData.five_year_sharpe = [];
      this.portfolioSharpeRatioData.three_year_sharpe = [];
      this.portfolioSharpeRatioData.one_year_sharpe = [];

      this.ascSortButtonType = '';
      this.descSortButtonType = '';
    },

    getYield() {
      if (this.newStartAndEndDate.length === 0 ||
          this.tableChoice === ''
      ) {
        this.$message.warning(this.$t("basic.date_and_table_data_err").toString())
        return;
      }
      this.calYieldFlag = true;

      this.newStartDateValue = this.newStartAndEndDate[0];
      this.newEndDateValue = this.newStartAndEndDate[1];

      //获取指定表格的stockcodes
      if (this.tableChoice === 6) {
        this.sendWeightData(this.stockCodes, this.weightAllocationArr);
        // this.currentWeightLimit = '--';
      } else {
        const stockCodes = this.stockTables['tableData' + this.tableChoice].map(item => item['stockCode']);
        const stockCodesStr = stockCodes.join(',');

        this.currentWeightLimit = this.weightLimits['weightLimit' + this.tableChoice];
        this.sendWeightData(stockCodesStr, this.weightAllocations['weightAllocation' + this.tableChoice]);
      }
      this.calYieldFlag = false;
    },

    /**
     * 一键升序排序个股夏普比率列表
     * @param sharpeTableData
     */
    ascendingSort() {
      if (this.sharpeTableSortOrder !== 'asc') {
        this.sharpeTableSortOrder = 'asc';

        this.portfolioSharpeRatioData.sharpe_since_listing.sort((a, b) => this.compareSharpe(a.sharpe_since_list, b.sharpe_since_list));
        this.portfolioSharpeRatioData.five_year_sharpe.sort((a, b) => this.compareSharpe(a.five_year_sharpe, b.five_year_sharpe));
        this.portfolioSharpeRatioData.three_year_sharpe.sort((a, b) => this.compareSharpe(a.three_year_sharpe, b.three_year_sharpe));
        this.portfolioSharpeRatioData.one_year_sharpe.sort((a, b) => this.compareSharpe(a.one_year_sharpe, b.one_year_sharpe));
      } else {
        //  说明要恢复原始顺序
        this.sharpeTableSortOrder = 'none';
        this.portfolioSharpeRatioData = JSON.parse(JSON.stringify(this.tempSharpeTableData));
      }
    },

    /**
     * 一键降序排序个股夏普比率列表
     * @param sharpeTableData
     */
    descendingSort() {
      if (this.sharpeTableSortOrder !== 'desc') {
        this.sharpeTableSortOrder = 'desc';

        this.portfolioSharpeRatioData.sharpe_since_listing.sort((a, b) => this.compareSharpe(b.sharpe_since_list, a.sharpe_since_list));
        this.portfolioSharpeRatioData.five_year_sharpe.sort((a, b) => this.compareSharpe(b.five_year_sharpe, a.five_year_sharpe));
        this.portfolioSharpeRatioData.three_year_sharpe.sort((a, b) => this.compareSharpe(b.three_year_sharpe, a.three_year_sharpe));
        this.portfolioSharpeRatioData.one_year_sharpe.sort((a, b) => this.compareSharpe(b.one_year_sharpe, a.one_year_sharpe));
      } else {
        //  说明要恢复原始顺序
        this.sharpeTableSortOrder = 'none';
        this.portfolioSharpeRatioData = JSON.parse(JSON.stringify(this.tempSharpeTableData));
      }
    },

    // 定义一个辅助函数，用于处理正负值和非数字值
    compareSharpe(a, b) {
      // 非数字情况处理
      if (isNaN(a) && isNaN(b)) {
        return 0; // a和b都不是数字，则它们相等
      } else if (isNaN(a)) {
        return 1; // a不是数字，b是数字，则a排在后面
      } else if (isNaN(b)) {
        return -1; // a是数字，b不是数字，则b排在后面
      }
      // 数字情况处理
      return a - b;
    },

    sendWeightData(stockCodes, weightAllocation) {
      if (this.tableChoice === 6) {
        //自定义权重
        weightAllocation = weightAllocation.map(weight => {
          weight = weight / 100;
          return weight;
        })
      }
      const weightAllocationStr = weightAllocation.join(',')
      const requestData = {
        codes: stockCodes,
        startDate: moment(this.newStartDateValue).format('YYYY-MM-DD'),
        endDate: moment(this.newEndDateValue).format('YYYY-MM-DD'),
        indicators: this.indicators,
        period: this.datePeriod,
        adjustFlag: this.adjustFlag,
        curType: this.curType,
        secuType: this.secuType,
        market: this.market,
        weightLimit: String(this.weightLimit / 100),
        weightAllocation: weightAllocationStr,
      }
      this.$http.get('/getEquityAndWeightedYield', {
        headers: {
          'Authorization': sessionStorage.getItem("token") // 替换 yourToken 为实际的 token 值
        },
        params: requestData
      }).then(response => {
        console.log(response)
        if (response.data === '10001001EQERR_NO_LOGIN') {
          this.$message.error(this.$t("basic.no_login_err").toString());
        } else if (response.data === "10003008EQERR_CODE_INVALIED") {
          this.$message.error(this.$t("basic.code_invalid_err").toString());
        } else if (typeof response.data === 'string' && response.data.includes("ERR")) {
          this.$message.error(response.data);
        } else {
          //获取收益详情, 为表格填充数据
          this.yieldDetailData = [];
          this.stockYieldList = response.data.stock_yield_list;
          response.data.stock_yield_list.map((stock, index) => {
            const sumOfMonthlyYield = Object.values(stock['actual_monthly_yield']).reduce((acc, value) => acc + value, 0);
            const sumOfWeightedSecurity = stock.weighted_monthly_yield.reduce((acc, value) => acc + value, 0);
            this.yieldDetailData.push({
              'index': index,
              'code': stock['code'],
              'sumOfSecurity': (sumOfMonthlyYield * 100).toFixed(3),
              'sumOfWeightedSecurity': (sumOfWeightedSecurity * 100).toFixed(3),
            })
          })

          if (this.tableChoice === 6) {
            //  如果是自定义权重，为自定义权重的相关参数赋值
            this.sumOfCustomWeightsYieldData = response.data.sum_of_all_weighted * 100;
            this.customBackTestVolatility = response.data.annualized_volatility_weighted * 100;
            this.customBackTestSharpeRatio = response.data.sharpe_ratio_weighted;
          } else {
            this.sumOfWeightedYieldData = response.data.sum_of_all_weighted * 100;
            this.weightedBackTestVolatility = response.data.annualized_volatility_weighted * 100;
            this.weightedBackTestSharpeRatio = response.data.sharpe_ratio_weighted;
          }
          this.$message.success(this.$t("basic.cal_success").toString());
          this.yieldData = [];
          this.sumOfEquityYieldData = response.data.sum_of_all_equity * 100;
          this.equityBackTestVolatility = response.data.annualized_volatility_equity * 100;
          this.equityBackTestSharpeRatio = response.data.sharpe_ratio_equity;

          // 往表格中写数据
          for (let i = 0; i < response.data.new_date.length - 1; i++) {
            this.yieldData.push({
              newDatePeriod: response.data.new_date[i],
              equityYield: response.data.equity_yield[i].toFixed(5),
              weightedYield: response.data.weighted_yield[i].toFixed(5)
            });
          }
        }
      }).catch(error => {
        console.error(error)
      })
    },

    /**
     * 监听代码输入框变化
     */
    onChange() {
      this.stockCodesArr = this.stockCodes.replace(/\s/g, '').split(",");

      const pattern = /,{2,}/;
      this.inputHasError = pattern.test(this.stockCodes);
      if (this.stockCodes.includes("，"))
        this.inputHasError = true;

      const stockCodesArray = this.stockCodes.split(',').filter(code => code.trim() !== "");

      if (stockCodesArray.length > 0 && stockCodesArray[stockCodesArray.length - 1] === '') {
        this.numberOfStock = stockCodesArray.length - 1;
      } else {
        this.numberOfStock = stockCodesArray.length;
      }
    },

    remainAssignableWeightChange(codeIndex) {
      if (this.weightAllocationArr.some(value => isNaN(value))) {
        this.weightAllocationArr.fill(0);
      }
      // 使用 Vue.set 或 this.$set 更新数组中的元素
      this.$set(
          this.weightAllocationArr,
          codeIndex,
          parseFloat(this.weightAllocationArr[codeIndex])
      );
      // 更新剩余可分配的权重
      this.remainAssignableWeight = 100 - this.weightAllocationArr.reduce((sum, val) => sum + val, 0);
    },

    viewDetail(row) {
      this.stockYieldDetailData = [];
      this.stockYieldDetailDialogVisible = true;
      const date = Object.keys(this.stockYieldList[row.index]['actual_monthly_yield']);
      const actualReturns = Object.values(this.stockYieldList[row.index]['actual_monthly_yield']);
      const weightedReturns = this.stockYieldList[row.index]['weighted_monthly_yield'];

      date.forEach((currentDate, index) => {
        this.stockYieldDetailData.push({
          'date': currentDate,
          'monthly_returns': (actualReturns[index] * 100).toFixed(3),
          'monthly_weighted_returns': (weightedReturns[index] * 100).toFixed(3),
        })
      })
    },

    confirmIsFormComplete() {
      if (
          this.stockCodes === '' ||
          this.stockCodes.length === 0 ||
          this.secuType === '' ||
          this.secuType.length === 0 ||
          this.startAndEndDate.length === 0 ||
          this.datePeriod === '' ||
          this.adjustFlag === '' ||
          this.curType === '' ||
          this.market === '' ||
          this.weightLimit === ''
      ) {
        this.$message.warning(this.$t("basic.form_filling_err").toString());
        return true;
      } else {
        return false;
      }
    },

    remainAssignableWeightConfirm() {
      this.tableChoice = 6;
      this.customizeWeightVisible = false;
    },

    remainAssignableWeightReset() {
      this.weightAllocationArr = [];
    },

    remainAssignableWeightCancel() {
      // this.weightAllocationArr = [];
      this.customizeWeightVisible = false;
    },

    yieldDialogVisible() {
      this.yieldDetailDialogVisible = true;
      this.resizeTableLayout();
    },

    cellStyle({row, column, rowIndex, columnIndex}) {
      if (column.property === 'code') {
        return 'font-weight: bold;'; // 这里可以设置具体的样式
      } else {
        return '';
      }
    },


  },
}
</script>

<style>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 1560px;
}

.toggle-button {
  width: 100%;
}

.el-picker-panel .el-picker-panel__sidebar {
  width: 125px !important;
}

.searchStock {
}

.code_input_bar {
  display: flex;
  padding: 10px;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
}

.period_picker {
  text-align: left;
}

.weightDataTable {
  border-bottom: 1px solid #ccc; /* 分割线的样式，颜色，和宽度 */
}

.activeBar {
  text-align: center;
  margin: 0;
  background-color: #2c3e50;
}

.active_bar_title {
  color: #42b983;
  font-size: 280%;
  font-weight: bold;
  /*text-decoration: underline*/
}

.table-container {
  min-width: 1520px;
  max-width: 1550px;
  display: flex; /* 将容器内的元素横向排列 */
  justify-content: space-between;
  overflow-x: auto;
  padding-bottom: 10px;
}

.table-container.el-table {
  margin-right: 3px;
}

.expected-data-display {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
}

.data-display {
  min-width: 1450px;
  max-width: 1600px;
  width: 100%;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: space-between
}

.data-display-row {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  display: flex;
  justify-content: space-between;
}

.equity_data_style {
  display: flex;
  flex-direction: column;
  background-color: lightpink;
  width: 140px;
}

.weighted_data_style {
  display: flex;
  flex-direction: column;
  background-color: lightblue;
  width: 140px;
}

.table-width-style {
  width: 270px;
}

.error-message {
  color: red;
  font-size: small;
}

.codeInput-popper {
  width: 600px !important;
}

.back-test-style {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.back-test-data-container{
  display: flex;
  justify-content: center;
  width: 100%
}

.header-style {
  background-color: #2c3e50;
  color: #42b983;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.customize-weight-list-style {
  height: 500px;
  overflow-y: auto;
}

.sharpe-ratio-display {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  max-height: 520px;
  overflow-y: auto;
}

.red-font-style {
  color: red;
  font-size: small;
}

.flex_center_style {
  display: flex;
  justify-content: center;
}

.menu-item {
  text-align: center;
  font-size: 13px;
  width: 55px;
  height: 55px;
  background-color: #fff;
  color: #949797;
  font-weight: bold;
  border: 1px solid #cccccc;
  box-shadow: 0 5px 4px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
</style>

