<template>
  <div class="subscription-card-container">
    <div style="display: flex;flex-direction: row;align-items: center;">
      <div style="width: 95%">
        <div class="subscription-title">
          <slot name="statusTag"> <!-- 插槽 -->
            <!-- 默认状态标签为空 -->
          </slot>

          <span style="overflow: hidden;text-overflow: ellipsis">{{subscriptionTitle}}</span>

          <slot name="editButton"></slot>
        </div>

        <div class="subscription-desc">
          <span style=" display: block;text-align: justify;overflow: hidden;text-overflow: ellipsis">{{subscriptionDesc}}</span>
        </div>

        <!--    <div class="subscription-message">-->
        <!--      <span>{{releaseTime}}</span>-->
        <!--      <span class="fa fa-thumbs-o-up">{{subscriptionLike}}</span>-->
        <!--      <span class="el-icon-view">{{subscriptionViews}}</span>-->
        <!--      <span class="el-icon-chat-dot-round">{{subscriptionComments}}</span>-->
        <!--    </div>-->
      </div>

      <div style="color: #949797;font-size: larger">
        <i class="fa fa-angle-double-right"></i>
      </div>
    </div>



  </div>

</template>

<script>

export default {
  name: "SubscriptionCard",

  props:{
    subscriptionTitle:String,
    subscriptionDesc:String,





  },

  methods:{

  },
}
</script>

<style scoped>
.subscription-card-container{
  display: flex;
  flex-direction: column;
  border: 1px solid #ebebeb;
  border-radius: 10px;
  width: 98%;
  margin-bottom: 5px;
}

.subscription-title{
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: large;
  font-weight: bold;
  padding: 2%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 90%;
}

.subscription-desc{
  padding: 0 2% 10px 2%;
  font-size: small;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 90%;
}

.subscription-message{
  padding: 2%;
  margin-top: auto;
  font-size: small;
  display: flex;
  justify-content: space-between;
}

</style>
