import { render, staticRenderFns } from "./MobileArticleCard.vue?vue&type=template&id=6b3a6eea&scoped=true&"
import script from "./MobileArticleCard.vue?vue&type=script&lang=js&"
export * from "./MobileArticleCard.vue?vue&type=script&lang=js&"
import style0 from "./MobileArticleCard.vue?vue&type=style&index=0&id=6b3a6eea&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b3a6eea",
  null
  
)

export default component.exports