<template>

  <LayoutContainer>
    <template v-slot:my-header>
      <div>
        <el-dropdown trigger="click">
              <span class="el-dropdown-link" style="cursor: pointer;color:#42b983;font-weight: bold">
                {{ $t("basic.current_account") }}{{ username }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="signOut">{{ $t("basic.quit_account") }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </template>

    <div class="bookmark-table">
      <el-table
          :data="tableData"
          border
          v-loading="tableDataLoading"
          style="width: 100%">
        <el-table-column
            fixed="left"
            prop="bookmarkName"
            align="center"
            :label="$t('basic.bookmark_name')"
            width="135px">
        </el-table-column>
        <el-table-column
            prop="bookmarkContent"
            :label="$t('basic.bookmark_content')"
            width=""
            min-width="600px"
            show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
            prop="portfolioType"
            align="center"
            :label="$t('basic.portfolio_type')"
            width="120px">
        </el-table-column>
        <el-table-column
            prop="date"
            align="center"
            :label="$t('basic.bookmark_date')"
            width="125px">
        </el-table-column>
        <el-table-column
            fixed="right"
            align="center"
            :label="$t('basic.operation')"
            width="100px">
          <template v-slot="scope">
            <el-button @click="searchChanges(scope.row)" type="text" size="small">
              {{ $t("basic.view_portfolio_changes_button") }}
            </el-button>
            <el-button @click="deleteBookmark(scope.row)" type="text" size="small">
              {{ $t("basic.delete_button_text") }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div>
      <el-button @click="addBookmarkDialogVisible = true">{{ $t("basic.add_bookmark_button_text") }}</el-button>
    </div>

    <el-dialog
        :title="bookmark_name + $t('basic.portfolio_compare_title')"
        :visible.sync="centerDialogVisible"
        width="80%"

        center>

      <el-row class="data-display-row" v-loading="">
        <div class="compare-data-style">
          <span class="date-font-class">{{ this.table2StartDate + '~' + this.table2EndDate }}</span>

          <ShowcalResult
              :sum-equity-yield-data="sumOfEquityYieldDatas.sumOfEquityYieldData2"
              :equity-return="expectedEquityReturns.expectedReturn2"
              :equity-volatility="expectedEquityVolatility.expectedVolatility2"
              :equity-sharpe-ratio="equitySharpeRatios.sharpeRatio2"
              :sum-weighted-yield-data="sumOfWeightedYieldDatas.sumOfWeightedYieldData2"
              :weighted-return="expectedReturns.expectedReturn2"
              :weighted-volatility="expectedVolatility.expectedVolatility2"
              :weighted-sharpe-ratio="sharpeRatios.sharpeRatio2"
              :start-date="table2StartDate"
              :end-date="table2EndDate"
              number-of-table="①"
              :table-data="stockTables.tableData2"
          ></ShowcalResult>

          <div>
            <el-date-picker
                v-model="startAndEndDate2"
                style="width: 250px"
                class=".el-picker-panel .el-picker-panel__sidebar"
                type="daterange"
                align="right"
                unlink-panels
                range-separator="~"
                :start-placeholder="$t('basic.start_date_enter_tips')"
                :end-placeholder="$t('basic.end_date_enter_tips')"
                :picker-options="pickerOptions">
            </el-date-picker>
          </div>
        </div>

        <div class="compare-data-style">
          <span class="date-font-class">{{ this.table1StartDate + '~' + this.table1EndDate }}</span>

          <ShowcalResult
              :sum-equity-yield-data="sumOfEquityYieldDatas.sumOfEquityYieldData1"
              :equity-return="expectedEquityReturns.expectedReturn1"
              :equity-volatility="expectedEquityVolatility.expectedVolatility1"
              :equity-sharpe-ratio="equitySharpeRatios.sharpeRatio1"
              :sum-weighted-yield-data="sumOfWeightedYieldDatas.sumOfWeightedYieldData1"
              :weighted-return="expectedReturns.expectedReturn1"
              :weighted-volatility="expectedVolatility.expectedVolatility1"
              :weighted-sharpe-ratio="sharpeRatios.sharpeRatio1"
              :start-date="table1StartDate"
              :end-date="table1EndDate"
              number-of-table="②"
              :table-data="stockTables.tableData1"
          ></ShowcalResult>

          <div>
            <el-date-picker
                v-model="startAndEndDate1"
                style="width: 250px"
                class=".el-picker-panel .el-picker-panel__sidebar"
                type="daterange"
                align="right"
                unlink-panels
                range-separator="~"
                :start-placeholder="$t('basic.start_date_enter_tips')"
                :end-placeholder="$t('basic.end_date_enter_tips')"
                :picker-options="pickerOptions">
            </el-date-picker>
          </div>

        </div>
      </el-row>

      <div style="display: flex;align-items: center">
        <i class="el-icon-caret-right"></i>
        <el-popover
            placement="top-start"
            width="200"
            trigger="hover"
            :content="$t('basic.weight_limit_description')">
          <el-button type="text" style="color: black;margin-right: 10px" slot="reference">
            {{ $t("basic.weight_limit") }}
          </el-button>
        </el-popover>
        <el-slider
            v-model="weightLimit"
            show-input
            input-size="mini"
            height="10"
            style="width: 365px"
        >
        </el-slider>

      </div>


      <el-dialog
          width="50%"
          :title="$t('basic.generate_report')"
          :visible.sync="generateReportVisible"
          v-loading="reportGenerating"
          append-to-body>
        <span>{{ $t('basic.weight_limit') + ':  ' + weightLimit }}</span>

        <el-descriptions :title="$t('basic.portfolio_performance_changes')" :size="'medium'" :column="2" border
                         direction="" style="margin-top: 20px">
          <el-descriptions-item content-class-name="report-content-style" :label="$t('basic.mean_with_total_change')">
            {{ meanWithTotalChange }}
          </el-descriptions-item>
          <el-descriptions-item content-class-name="report-content-style"
                                :label="$t('basic.optimize_with_total_change')">{{ optimizeWithTotalChange }}
          </el-descriptions-item>

          <el-descriptions-item content-class-name="report-content-style" :label="$t('basic.mean_return_change')">
            {{ meanReturnChange }}
          </el-descriptions-item>
          <el-descriptions-item content-class-name="report-content-style" :label="$t('basic.weighted_return_change')">
            {{ weightedReturnChange }}
          </el-descriptions-item>

          <el-descriptions-item content-class-name="report-content-style"
                                :label="$t('basic.mean_annualized_volatility_change')">
            {{ meanAnnualizedVolatilityChange }}
          </el-descriptions-item>
          <el-descriptions-item content-class-name="report-content-style"
                                :label="$t('basic.weighted_annualized_volatility_change')">
            {{ weightedAnnualizedVolatilityChange }}
          </el-descriptions-item>

          <el-descriptions-item content-class-name="report-content-style" :label="$t('basic.mean_sharpe_change')">
            {{ meanSharpeChange }}
          </el-descriptions-item>
          <el-descriptions-item content-class-name="report-content-style" :label="$t('basic.weighted_sharpe_change')">
            {{ weightedSharpeChange }}
          </el-descriptions-item>
        </el-descriptions>

        <el-descriptions :title="$t('basic.weight_allocation_change')" :size="'medium'" :direction="'vertical'" border
                         style="margin-top: 20px">
          <el-descriptions-item :label="$t('basic.weight_increase_code')"><span class="weight-allocation-changes-style">{{
              weightIncreaseCodeStr
            }}</span>
          </el-descriptions-item>
          <el-descriptions-item :label="$t('basic.weight_decrease_code')"><span class="weight-allocation-changes-style">{{
              weightDecreaseCodeStr
            }}</span>
          </el-descriptions-item>
        </el-descriptions>

        <el-descriptions :title="table1EndDate + '  ' + $t('basic.KDJ_index_performance')" :size="'medium'"
                         :direction="'vertical'" border style="margin-top: 20px" v-loading="kdjIndexCalculating">
          <el-descriptions-item :label="$t('basic.J_value_daily')"><span
              class="weight-allocation-changes-style">{{ JDailyValue }}</span></el-descriptions-item>
          <el-descriptions-item :label="$t('basic.J_value_weekly')"><span
              class="weight-allocation-changes-style">{{ JWeeklyValue }}</span></el-descriptions-item>
          <el-descriptions-item :label="$t('basic.J_value_monthly')"><span
              class="weight-allocation-changes-style">{{ JMonthlyValue }}</span></el-descriptions-item>
        </el-descriptions>
      </el-dialog>

      <span slot="footer" class="dialog-footer">
               <el-button @click="recalculate">{{ $t("basic.recalculate_button_text") }}</el-button>
            <el-button @click="generateReport">{{ $t("basic.generate_report") }}</el-button>
        <!--              <el-button @click="centerDialogVisible = false">{{ $t("basic.cancel_button_text") }}</el-button>-->
        <!--              <el-button type="primary" @click="centerDialogVisible = false">{{$t("basic.confirm_button_text") }}</el-button>-->
            </span>
    </el-dialog>

    <el-dialog :title="$t('basic.add_bookmark_title')" :visible.sync="addBookmarkDialogVisible" width="800px">
      <el-form>
        <el-form-item :label="$t('basic.bookmark_name')" label-width=""
                      style="display: flex;justify-content: flex-start">
          <el-input v-model="bookmarkName" autocomplete="off" style="width: 200px;"
                    :placeholder="$t('basic.bookmark_name_tips')"></el-input>
          <span style="margin-left: 120px;margin-right: 10px">{{ $t("basic.portfolio_type") }}</span>
          <el-select v-model="portfolioType" :placeholder="$t('basic.portfolio_type_tips')" style="width: 195px">
            <el-option
                v-for="item in portfolioTypeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('basic.bookmark_content')" label-width="">
          <el-transfer
              :titles="[$t('basic.available_options_list'),$t('basic.selected_options_list')]"
              filterable
              :filter-placeholder="$t('basic.enter_code_tips')"
              v-model="securityCodeValue"
              :data="securityData">
          </el-transfer>
        </el-form-item>
        <el-form-item style="display: flex;justify-content: center">
          <el-button type="primary" @click="addBookmark">{{ $t("basic.add_bookmark_text") }}</el-button>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
      </div>
    </el-dialog>

  </LayoutContainer>

</template>

<script>
import moment from "moment";
import LayoutContainer from "@/components/layout/LayoutContainer";
import ShowcalResult from "@/components/component/ShowcalResult";

export default {
  name: "Bookmarks",
  components: {ShowcalResult, LayoutContainer},


  data() {
    const generateData = _ => {
      const data = [];
      const cities = this.securityCodeList;
      cities.forEach((city, index) => {
        data.push({
          label: city,
          key: index,
        });
      });
      return data;
    };

    return {
      centerDialogVisible: false,
      generateReportVisible: false,
      addBookmarkDialogVisible: false,
      isCollapse: true,
      calFlag: true,
      tableDataLoading: true,
      kdjIndexCalculating: true,
      reportGenerating: true,

      startAndEndDate1: [],
      startAndEndDate2: [],
      securityData: [],
      securityCodeValue: [],
      row: [],
      res1: [],
      res2: [],
      multipleSelection: [],
      tableData: [],
      securityCodeList: [],

      portfolioTypeOptions: [{
        value: '1',
        label: this.$t("basic.portfolio_type_stock")
      },
        // {
        //   value: '2',
        //   label: this.$t("basic.portfolio_type_bond")
        // },
        {
          value: '3',
          label: this.$t("basic.portfolio_type_fund")
        },
        // {
        //   value: '5',
        //   label: this.$t("basic.portfolio_type_index")
        // },
      ],

      equity_yield_title: this.$t("basic.mean_weighted_interval_total_return"),
      equity_return_title: this.$t("basic.weighted_return"),
      equity_volatility_title: this.$t("basic.weighted_volatility"),
      equity_sharpe_title: this.$t("basic.weighted_sharpe"),
      weighted_yield_title: this.$t("basic.weighted_interval_total_return"),
      weighted_return_title: this.$t("basic.weighted_return_with_premium"),
      weighted_sharpe_title: this.$t("basic.sharpe_with_premium"),
      weighted_volatility_title: this.$t("basic.weighted_volatility_with_premium"),
      equityTitle: this.$t("basic.mean_weighted_interval_total_return"),
      weightedTitle: this.$t("basic.weighted_interval_total_return"),

      weightLimit: 20,
      username: sessionStorage.getItem("username"),
      table1StartDate: '',
      table1EndDate: '',
      table2StartDate: '',
      table2EndDate: '',
      bookmarkName: '',
      bookmark_name: '',
      portfolioType: '',
      reportContent: '',
      meanWithTotalChange: '',
      meanReturnChange: '',
      meanAnnualizedVolatilityChange: '',
      meanSharpeChange: '',
      optimizeWithTotalChange: '',
      weightedReturnChange: '',
      weightedAnnualizedVolatilityChange: '',
      weightedSharpeChange: '',
      weightIncreaseCodeStr: '',
      weightDecreaseCodeStr: '',
      JDailyValue: '',
      JWeeklyValue: '',
      JMonthlyValue: '',

      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [

          {
            text: this.$t("basic.five_years_prev_trading").toString(),
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 365 * 5);
              const today = new Date();
              picker.$emit('pick', [date, today]);
            }
          },
          {
            text: this.$t("basic.three_years_prev_trading").toString(),
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 365 * 3);
              const today = new Date();
              picker.$emit('pick', [date, today]);
            }
          },
          {
            text: this.$t("basic.one_year_prev_trading").toString(),
            onClick: (picker) => {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 365);
              const today = new Date();
              picker.$emit('pick', [date, today]);
            }
          },

          {
            text: this.$t("basic.three_years_prev_month").toString(),
            onClick: (picker) => {
              const today = new Date();
              const oldYear = today.getFullYear() - 3;

              // 计算上个月的最后一天
              const lastMonthLastDay = new Date(today.getFullYear(), today.getMonth(), 0);

              // 设置开始日期（上个月的第一天）
              const startDate = new Date(oldYear, lastMonthLastDay.getMonth() + 1, 0);

              // 设置结束日期（上个月的最后一天往前一年）
              const endDate = new Date(today.getFullYear(), lastMonthLastDay.getMonth(), lastMonthLastDay.getDate());

              picker.$emit('pick', [startDate, endDate]);
            }
          },
          {
            text: this.$t("basic.one_year_prev_month").toString(),
            onClick: (picker) => {
              const today = new Date();
              const lastYear = today.getFullYear() - 1;

              // 计算上个月的最后一天
              const lastMonthLastDay = new Date(today.getFullYear(), today.getMonth(), 0);

              // 设置开始日期（上个月的第一天）
              const startDate = new Date(lastYear, lastMonthLastDay.getMonth() + 1, 0);

              // 设置结束日期（上个月的最后一天往前一年）
              const endDate = new Date(today.getFullYear(), lastMonthLastDay.getMonth(), lastMonthLastDay.getDate());

              picker.$emit('pick', [startDate, endDate]);
            }
          },
          {
            text: this.$t("basic.entire_five_years").toString(),
            onClick(picker) {
              const today = new Date();
              const lastYear = today.getFullYear() - 5;

              // 设置开始日期（前一年的1月1日）
              const startDate = new Date(lastYear, 0, 1); // 0 表示 1 月

              // 设置结束日期（前一年的12月31日）
              const endDate = new Date(lastYear + 4, 11, 31); // 11 表示 12 月

              picker.$emit('pick', [startDate, endDate]);
            }
          },
          {
            text: this.$t("basic.entire_three_years").toString(),
            onClick(picker) {
              const today = new Date();
              const lastYear = today.getFullYear() - 3;

              // 设置开始日期（前一年的1月1日）
              const startDate = new Date(lastYear, 0, 1); // 0 表示 1 月

              // 设置结束日期（前一年的12月31日）
              const endDate = new Date(lastYear + 2, 11, 31); // 11 表示 12 月

              picker.$emit('pick', [startDate, endDate]);
            }
          },
          {
            text: this.$t("basic.entire_one_year").toString(),
            onClick(picker) {
              const today = new Date();
              const lastYear = today.getFullYear() - 1;

              // 设置开始日期（前一年的1月1日）
              const startDate = new Date(lastYear, 0, 1); // 0 表示 1 月

              // 设置结束日期（前一年的12月31日）
              const endDate = new Date(lastYear, 11, 31); // 11 表示 12 月
              picker.$emit('pick', [startDate, endDate]);
            }
          },

        ]
      },

      stockTables: {
        tableData1: [],
        tableData2: [],
      },

      expectedReturns: {
        expectedReturn1: 0,
        expectedReturn2: 0,
      },
      expectedEquityReturns: {
        expectedReturn1: 0,
        expectedReturn2: 0,
      },

      expectedVolatility: {
        expectedVolatility1: 0,
        expectedVolatility2: 0,
      },
      expectedEquityVolatility: {
        expectedVolatility1: 0,
        expectedVolatility2: 0,
      },

      sharpeRatios: {
        sharpeRatio1: 0,
        sharpeRatio2: 0,
      },
      equitySharpeRatios: {
        sharpeRatio1: 0,
        sharpeRatio2: 0,
      },

      sumOfEquityYieldDatas: {
        sumOfEquityYieldData1: 0,
        sumOfEquityYieldData2: 0,
      },
      sumOfWeightedYieldDatas: {
        sumOfWeightedYieldData1: 0,
        sumOfWeightedYieldData2: 0,
      },

      weightAllocations: {
        weightAllocation1: [],
        weightAllocation2: [],
      },
    }
  },

  created() {
    this.getSecurityCodeList();
    this.loadBookmarks();
  },

  methods: {
    loadBookmarks() {
      this.$http.get('/getBookmarksByToken', {
        headers: {
          'Authorization': sessionStorage.getItem("token") // 替换 yourToken 为实际的 token 值
        }
      }).then(response => {
        console.log(response)
        this.tableData = response.data.map(item => ({
          bookmarkName: item.bookmarkName,
          date: moment(item.createTime).format('yyyy-MM-DD'),
          bookmarkContent: item.bookmarkContent,
          portfolioType: item.portfolioType === 1 ? this.$t("basic.portfolio_type_stock") : this.$t("basic.portfolio_type_fund"),
          isFromRecord: item.isFromRecord,
        }))
      }).catch(err => {
        console.error(err)
      }).finally(() => {
        this.tableDataLoading = false;
      })
    },

    recalculate() {
      this.searchChanges(this.row, true);

    }
    ,

    async searchChanges(row, recalculateFlag) {
      this.calFlag = true;
      const dialogLoading = this.$loading({
        target: '.el-dialog',
      });
      this.centerDialogVisible = true;
      this.bookmark_name = row.bookmarkName;

      if (recalculateFlag) {
        this.table1StartDate = moment(this.startAndEndDate1[0]).format('yyyy-MM-DD');
        this.table1EndDate = moment(this.startAndEndDate1[1]).format('yyyy-MM-DD');
        this.table2StartDate = moment(this.startAndEndDate2[0]).format('yyyy-MM-DD');
        this.table2EndDate = moment(this.startAndEndDate2[1]).format('yyyy-MM-DD');
      } else {
        //  显示上周五 与 上上周五的数据变化
        this.table1StartDate = moment(this.getLastFridayRange()['lastYear']).format('yyyy-MM-DD');
        this.table1EndDate = moment(this.getLastFridayRange()['lastFriday']).format('yyyy-MM-DD');
        this.startAndEndDate1[0] = this.table1StartDate;
        this.startAndEndDate1[1] = this.table1EndDate;

        this.table2StartDate = moment(this.getLastFridayRange()['lastTwoYear']).format('yyyy-MM-DD');
        this.table2EndDate = moment(this.getLastFridayRange()['lastTwoFriday']).format('yyyy-MM-DD');
        this.startAndEndDate2[0] = this.table2StartDate;
        this.startAndEndDate2[1] = this.table2EndDate;
      }

      if (this.row !== row || recalculateFlag) {
        this.row = row;
        // 使用Promise.all并行执行异步请求
        [this.res1, this.res2] = await Promise.all([
          this.getStockData(row, this.table1StartDate, this.table1EndDate),
          this.getStockData(row, this.table2StartDate, this.table2EndDate)
        ]);
      }

      debugger

      if ('RESULT_DATA' in this.res1.data && 'RESULT_DATA' in this.res2.data) {
        this.res1.data = JSON.parse(this.res1.data['RESULT_DATA']);
        this.res2.data = JSON.parse(this.res2.data['RESULT_DATA']);
      } else if ('ERROR_MESSAGE' in this.res1.data || 'ERROR_MESSAGE' in this.res2.data) {
        this.$message.error(JSON.parse(this.res1.data['ERROR_MESSAGE']));
        dialogLoading.close();
        return;
      }

      // 将相似的数据处理逻辑提取成函数
      const processData = (data) => {
        return {
          sumOfEquityYield: data.sum_of_all_equity_yield * 100,
          expectedReturn: data.equity_returns * 100,
          expectedVolatility: data.equity_volatility * 100,
          sharpeRatio: data.equity_sharpe,
          sumOfWeightedYield: data.sum_of_all_weighted_yield * 100,
          returns: data.returns * 100,
          volatility: data.volatility * 100,
          sharpe: data.sharpe
        };
      };

      // 处理数据
      const processedData1 = processData(this.res1.data);
      const processedData2 = processData(this.res2.data);

      // 赋值
      Object.assign(this.sumOfEquityYieldDatas, {
        sumOfEquityYieldData1: processedData1.sumOfEquityYield,
        sumOfEquityYieldData2: processedData2.sumOfEquityYield
      });

      Object.assign(this.expectedEquityReturns, {
        expectedReturn1: processedData1.expectedReturn,
        expectedReturn2: processedData2.expectedReturn
      });

      Object.assign(this.expectedEquityVolatility, {
        expectedVolatility1: processedData1.expectedVolatility,
        expectedVolatility2: processedData2.expectedVolatility
      });

      Object.assign(this.equitySharpeRatios, {
        sharpeRatio1: processedData1.sharpeRatio,
        sharpeRatio2: processedData2.sharpeRatio
      });

      Object.assign(this.sumOfWeightedYieldDatas, {
        sumOfWeightedYieldData1: processedData1.sumOfWeightedYield,
        sumOfWeightedYieldData2: processedData2.sumOfWeightedYield
      });

      Object.assign(this.expectedReturns, {
        expectedReturn1: processedData1.returns,
        expectedReturn2: processedData2.returns
      });

      Object.assign(this.expectedVolatility, {
        expectedVolatility1: processedData1.volatility,
        expectedVolatility2: processedData2.volatility
      });

      Object.assign(this.sharpeRatios, {
        sharpeRatio1: processedData1.sharpe,
        sharpeRatio2: processedData2.sharpe
      });

      // 使用map函数构建股票数据表
      this.stockTables.tableData1 = this.res1.data.stock_names.map((stock, index) => ({
        stockCode: stock,
        weights: (this.res1.data.weight_allocation[index] * 100).toFixed(1) + '%'
      }));

      this.stockTables.tableData2 = this.res2.data.stock_names.map((stock, index) => ({
        stockCode: stock,
        weights: (this.res2.data.weight_allocation[index] * 100).toFixed(1) + '%'
      }));

      dialogLoading.close();
    }
    ,

    async getStockData(row, startDate, endDate) {
      const requestData = {
        codes: row.bookmarkContent,
        secuType: row.portfolioType === this.$t("basic.portfolio_type_stock").toString() ? 1 : 3,
        startDate: startDate,
        endDate: endDate,
        indicators: 'CLOSE',
        period: '1',
        adjustFlag: '3',
        curType: '1',
        market: 'CNSESH',
        weightLimit: (this.weightLimit / 100),
        username: this.username,
      };

      try {
        const response = await this.$http.get('/getStockData', {
          headers: {
            'Authorization': sessionStorage.getItem("token") // 替换 yourToken 为实际的 token 值
          },
          params:requestData
        });
        console.log(response)
        return response;  // 返回响应数据
      } catch (error) {
        console.error(error);
        throw error; // 抛出错误，如果有的话
      }
    }
    ,

    getSecurityCodeList() {
      this.$http.get('/getSecurityCodeList', {
        headers: {
          'Authorization': sessionStorage.getItem("token") // 替换 yourToken 为实际的 token 值
        }
      }).then(response => {
        console.log(response)
        const data = [];
        response.data.forEach((code, index) => {
          data.push({
            label: code,
            key: index,
          });
        });
        this.securityData = data;
      }).catch(err => {
        console.error(err);
      })
    }
    ,

    addBookmark() {
      if (!this.bookmarkName || !this.portfolioType || !this.securityCodeValue || this.securityCodeValue === null) {
        this.$message.warning(this.$t("basic.form_filling_err").toString());
        return;
      }

      let bookmarkContent = '';

      this.securityCodeValue.forEach((key) => {
        bookmarkContent = bookmarkContent + this.securityData[key]['label'] + ',';
      })

      const param = {
        bookmarkName: this.bookmarkName,
        portfolioType: this.portfolioType,
        bookmarkContent: bookmarkContent,
        createTime: new Date(),
      }

      this.$http.post("/addBookmark", param, {
        headers: {
          'Authorization': sessionStorage.getItem("token") // 替换 yourToken 为实际的 token 值
        }
      }).then(res => {
        console.log(res);
        if (res.data === 'success') {
          this.$router.go(0);
          this.$message.success(this.$t("basic.add_success_text").toString());
        } else {
          this.$message.error(this.$t("basic.add_failed_text").toString());
        }
      }).catch(err => {
        console.error(err);
      })

    }
    ,

    deleteBookmark(row) {
      const formData = new FormData();
      formData.append('bookmarkContent', row.bookmarkContent);
      formData.append('isFromRecord', row.isFromRecord);


      this.$confirm(this.$t("basic.delete_confirm").toString(), this.$t("basic.delete_confirm_title").toString(), {
        confirmButtonText: this.$t("basic.confirm_button_text").toString(),
        cancelButtonText: this.$t("basic.cancel_button_text").toString(),
        type: 'warning',
      }).then(() => {

        this.$http.post("/deleteBookmark", formData, {
          headers: {
            'Authorization': sessionStorage.getItem("token") // 替换 yourToken 为实际的 token 值
          }
        }).then(res => {
          console.log(res);
          if (res.data === 'success') {
            this.$router.go(0);
            this.$message.success(this.$t("basic.delete_success").toString());
          } else {
            this.$message.error(this.$t("basic.delete_failed").toString());
          }
        }).catch(err => {
          console.error(err);
        })

      }).catch(() => {
        this.$message.info(this.$t("basic.delete_cancel").toString());
      })

    }
    ,

    generateReport() {
      this.generateReportVisible = true;
      this.kdjIndexCalculating = true;
      this.meanWithTotalChange = (this.sumOfEquityYieldDatas.sumOfEquityYieldData1 - this.sumOfEquityYieldDatas.sumOfEquityYieldData2).toFixed(2) + '%';
      this.meanReturnChange = (this.expectedEquityReturns.expectedReturn1 - this.expectedEquityReturns.expectedReturn2).toFixed(2) + '%';
      this.meanAnnualizedVolatilityChange = (this.expectedEquityVolatility.expectedVolatility1 - this.expectedEquityVolatility.expectedVolatility2).toFixed(2) + '%';
      this.meanSharpeChange = (this.equitySharpeRatios.sharpeRatio1 - this.equitySharpeRatios.sharpeRatio2).toFixed(2);
      this.optimizeWithTotalChange = (this.sumOfWeightedYieldDatas.sumOfWeightedYieldData1 - this.sumOfWeightedYieldDatas.sumOfWeightedYieldData2).toFixed(2) + '%';
      this.weightedReturnChange = (this.expectedReturns.expectedReturn1 - this.expectedReturns.expectedReturn2).toFixed(2) + '%';
      this.weightedAnnualizedVolatilityChange = (this.expectedVolatility.expectedVolatility1 - this.expectedVolatility.expectedVolatility2).toFixed(2) + '%';
      this.weightedSharpeChange = (this.sharpeRatios.sharpeRatio1 - this.sharpeRatios.sharpeRatio2).toFixed(2);

      const newWeekData = {};
      const oldWeekData = {};

      const weightAsc = [];
      const weightDesc = [];

      if (!this.stockTables.tableData1) {
        return;
      }

      this.stockTables.tableData1.forEach((security, index) => {
        newWeekData[security.stockCode] = security.weights;
        oldWeekData[security.stockCode] = this.stockTables.tableData2[index].weights;

        if (parseFloat(security.weights) - parseFloat(this.stockTables.tableData2[index].weights) > 0) {
          weightAsc.push(security.stockCode);
        } else if (parseFloat(security.weights) - parseFloat(this.stockTables.tableData2[index].weights) < 0) {
          weightDesc.push(security.stockCode);
        }
      });

      this.weightIncreaseCodeStr = '';
      this.weightDecreaseCodeStr = '';

      weightAsc.forEach((securityCode) => {
        this.weightIncreaseCodeStr += `${securityCode}: ${oldWeekData[securityCode]} => ${newWeekData[securityCode]}\n`;
      });

      this.reportContent += '\n\n权重减少的证券：'


      weightDesc.forEach((securityCode) => {
        this.weightDecreaseCodeStr += `${securityCode}: ${oldWeekData[securityCode]} => ${newWeekData[securityCode]}\n`;
      });

      const changedSecurity = weightAsc.concat(weightDesc).join(',');

      const param = {
        codes: changedSecurity,
        adjustFlag: '3',
        curType: '1',
        startDate: moment(this.startAndEndDate1[0]).format('yyyy-MM-DD'),
        endDate: moment(this.startAndEndDate1[1]).format(('yyyy-MM-DD')),
        // startDate:'2023-05-01',
        // endDate:'2023-12-06',
      }

      this.$http.get('/getKdjIndex', {
        headers: {
          'Authorization': sessionStorage.getItem("token") // 替换 yourToken 为实际的 token 值
        },
        params: param,
      }).then(response => {
        console.log(response)
        const KDJDayValue = response.data.KDJDayValue;
        const KDJWeekValue = response.data.KDJWeekValue;
        const KDJMonthValue = response.data.KDJMonthValue;

        this.JDailyValue = '';
        this.JWeeklyValue = '';
        this.JMonthlyValue = '';

        KDJDayValue.forEach(security => {
          this.JDailyValue += security.code + ':  ' + (security.j_index[security.j_index.length - 1]).toFixed(1) + '\n';
        })

        KDJWeekValue.forEach(security => {
          this.JWeeklyValue += security.code + ':  ' + (security.j_index[security.j_index.length - 1]).toFixed(1) + '\n';
        })

        KDJMonthValue.forEach(security => {
          this.JMonthlyValue += security.code + ':  ' + (security.j_index[security.j_index.length - 1]).toFixed(1) + '\n';
        })
      }).catch(err => {
        console.error(err)
      }).finally(() => {
        if (weightAsc.length === 0 && weightDesc.length === 0) {
          this.weightIncreaseCodeStr = this.$t("basic.no_data_msg");
          this.weightDecreaseCodeStr = this.$t("basic.no_data_msg");
          this.JDailyValue = this.$t("basic.no_data_msg");
          this.JWeeklyValue = this.$t("basic.no_data_msg");
          this.JMonthlyValue = this.$t("basic.no_data_msg");
        } else if (weightAsc.length === 0) {
          this.weightIncreaseCodeStr = this.$t("basic.no_data_msg");
        } else if (weightDesc.length === 0) {
          this.weightDecreaseCodeStr = this.$t("basic.no_data_msg");
        }
        this.kdjIndexCalculating = false;
      })

    }
    ,


    getLastFridayRange() {
      const today = new Date();
      const lastFriday = new Date(today);

      const dayOfWeek = today.getDay();
      const diff = dayOfWeek === 5 ? 7 : (dayOfWeek + 7 - 5); // 5表示周五，如果是周五，需要回到上周五

      lastFriday.setDate(today.getDate() - diff);

      const lastYear = new Date(lastFriday);
      lastYear.setFullYear(lastYear.getFullYear() - 1);

      const lastTwoYear = new Date(lastYear);
      lastTwoYear.setDate(lastYear.getDate() - 7);

      const lastTwoFriday = new Date(lastFriday);
      lastTwoFriday.setDate(lastFriday.getDate() - 7);

      return {
        lastYear,
        lastFriday,
        lastTwoFriday,
        lastTwoYear,
      };
    }
    ,

    collapseNav() {
      this.isCollapse = !this.isCollapse;
    }
    ,

    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    }
    ,
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    }
    ,

    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    }
    ,

    handleSelectionChange(val) {
      this.multipleSelection = val;
    }
    ,

    signOut() {
      sessionStorage.setItem("username", '');
      sessionStorage.setItem("isLoggedIn", '');
      this.$message.success(this.$t("basic.sign_out_success").toString());
      this.$router.push('/login');
    }
    ,
  },
}
</script>

<style scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}

.toggle-button {
  width: 100%;
}

.header-style {
  background-color: #2c3e50;
  color: #42b983;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bookmark-table {
  width: 900px;
}

.data-display-row {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  display: flex;
  justify-content: space-evenly;
}

.equity_data_style {
  display: flex;
  flex-direction: column;
  background-color: lightpink;
  width: 140px;
}

.weighted_data_style {
  display: flex;
  flex-direction: column;
  background-color: lightblue;
  width: 140px;
}

.table-width-style {
  width: 270px;
}

.compare-data-style {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.date-font-class {
  font-size: 17px;
  font-weight: bold;
}

.report-content-style {
  font-weight: bold;
  font-size: medium;
}

.weight-allocation-changes-style {
  white-space: pre-line;
}

</style>
