<template>
  <div class="edit-container">
    <div class="article-content-edit" style="width: 100%; height: 100%" v-show="isEditContent">
      <div class="edit-navigation">
        <el-button type="text" style="padding: 5px 15px;color: white;font-weight: bold" @click="jumpToHome">
          {{ $t("basic.cancel_button_text") }}
        </el-button>
        <el-button type="text" style="padding: 5px 15px;color: white;font-weight: bold" @click="jumpToArticleSettings">
          {{ $t("basic.next_text") }}
        </el-button>
      </div>

      <div class="edit-title">
        <el-input :placeholder="$t('basic.enter_article_title_tips')"
                  v-model="articleTitle"
                  style="font-size: 17px;font-weight: bold"
        ></el-input>
      </div>

      <div class="edit-content">
        <multi-text-editor v-model="contentWithStyle"></multi-text-editor>
      </div>
    </div>

    <div class="article-message-settings" v-show="!isEditContent" v-loading="userManagedTopicLoading">
      <div class="edit-navigation">
        <el-button type="text" style="padding: 5px 15px;color: white;font-weight: bold" @click="isEditContent = true">
          {{ $t("basic.back_text") }}
        </el-button>
      </div>

      <div class="edit-article-source">
        <span class="article-setting-label">{{ $t("basic.article_source_text") }}</span>

        <el-radio-group v-model="articleSource" size="small" fill="#42b983">
          <el-radio-button :label="$t('basic.original_text').toString()"></el-radio-button>
          <el-radio-button :label="$t('basic.repost_text').toString()"></el-radio-button>
          <el-radio-button :label="$t('basic.translate_text').toString()"></el-radio-button>
        </el-radio-group>
      </div>

      <div class="edit-article-topic">
        <span class="article-setting-label">{{ $t("basic.article_topic_text") }}</span>

        <el-radio-group v-model="articleTopic"
                        :disabled="isChooseCustomTopic"
                        size="small"
                        fill="#42b983"
                        style="display: flex;
                      flex-direction: row;
                      overflow-x: auto;
                      width: 60vw;"
        >
          <el-radio-button :label="$t('basic.market_info_text').toString()"></el-radio-button>
          <el-radio-button :label="$t('basic.research_report_text').toString()"></el-radio-button>
          <el-radio-button :label="$t('basic.perspectives_and_interviews_text').toString()"></el-radio-button>
        </el-radio-group>
      </div>

      <div class="custom-topic-edit" v-show="topicList.length > 0">
        <span>{{$t('basic.publish_article_to_managed_topic')}}</span>

        <el-switch
            style="margin-left: 10px"
            v-model="isChooseCustomTopic"
            active-color="#13ce66"
            inactive-color="#ebebeb">
        </el-switch>
      </div>

      <div class="custom-topic-container" v-show="isChooseCustomTopic && topicList.length > 0">
        <span class="article-setting-label">{{$t("basic.my_topic_text")}}</span>

        <el-radio-group v-model="articleTopic"
                        :disabled="!isChooseCustomTopic"
                        size="small"
                        fill="#42b983"
                        style="display: flex;
                      flex-direction: row;
                      overflow-x: auto;
                      width: 60vw;"
        >
          <el-radio-button v-for="(topic,index) in topicList" :key="index" :label="topic.topicName"></el-radio-button>
        </el-radio-group>
      </div>

      <div class="submit-article">
        <el-button @click="publishArticle"
                   v-loading="isPublished"
                   style="width: 400px;border-radius: 20px;background-color: #42b983;
                 color: white;font-weight: bold;font-size: 18px;"
        >{{ $t("basic.publish_text") }}
        </el-button>

      </div>
    </div>

    <!--确认保存草稿弹窗-->
    <el-dialog
        :visible.sync="saveDraftDialogVisible"
        width="25%"
        v-loading="saveDraftLoading"
    >
      <span>{{ $t("basic.confirm_save_draft_text") }}</span>
      <span slot="footer" class="dialog-footer" style="display: flex;align-items: center;justify-content: space-around">
        <el-button @click="$router.go(-1)">{{ $t("basic.discard_text") }}</el-button>
        <el-button type="primary" @click="saveDraft">{{ $t("basic.yes_text") }}</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>

import MultiTextEditor from "@/components/component/MultiTextEditor.vue";
import {addQuillTitle} from "@/components/component/quill-title";

export default {
  name: "ComputerArticleEdit",
  components: {"MultiTextEditor": MultiTextEditor},

  created() {
  },

  mounted() {
    addQuillTitle();
  },

  data() {
    return {
      userManagedTopicLoading:false,
      isChooseCustomTopic: false,
      isEditContent: true,
      isPublished: false,
      saveDraftDialogVisible: false,
      saveDraftLoading: false,

      articleTitle: "",
      contentWithStyle: "",
      articleContent: "",
      articleSource: "",
      articleTopic: "",
      articleStatus: -1,
      articleTopicNumber: -1,
      articleSourceNumber: -1,

      topicList:[],
    }
  },

  methods: {

    saveDraft() {
      this.saveDraftLoading = true;
      // 文章状态设为草稿类型
      this.articleStatus = 1;

      const requestData = {
        articleTitle: this.articleTitle,
        articleContent: this.articleContent,
        contentWithStyle: this.contentWithStyle,
        articleSource: 0,
        articleTopic: 0,
        articleStatus: this.articleStatus
      };

      const config = {
        headers: {
          'Authorization': sessionStorage.getItem("token") // 替换 yourToken 为实际的 token 值
        },
      };

      this.$http.post('/addArticle', requestData, config).then(res => {
        console.log(res);
        if (res.data.code === 200) {
          this.$message.success(this.$t("basic.save_success_text").toString());
          this.$router.go(-1);
        } else {
          this.$message.warning(this.$t("basic.save_failed_text").toString());
        }
      }).catch(err => {
        console.error(err);
      }).finally(() => {
        this.saveDraftLoading = false;
      })
    },

    jumpToArticleSettings() {
      if (this.articleTitle === "" || this.contentWithStyle === "") {
        this.$message.warning(this.$t("basic.topic_content_empty_error").toString());
        return;
      }

      this.userManagedTopicLoading = true;

      // 获取正文文本
      const parser = new DOMParser();
      const doc = parser.parseFromString(this.contentWithStyle, "text/html");
      this.articleContent = doc.body.textContent || '';

      this.isEditContent = false;

    //   获取当前用户管理的专题
      this.$http.get('/getTopicListByUserId',{
        headers: {
          'Authorization': sessionStorage.getItem("token")
        }
      }).then((res) => {
        this.topicList = res.data.topicList;
        console.log(res);
      }).catch(err=>{
        console.error(err);
      }).finally(()=>{
        this.userManagedTopicLoading = false;
      })
    },

    jumpToHome() {
      if (this.articleTitle !== "" && this.contentWithStyle !== "") {
        // 确认是否保存草稿
        this.saveDraftDialogVisible = true;
        return;
      }
      this.$router.go(-1);
    },

    publishArticle() {
      if (this.articleSource === "" || this.articleTopic === "") {
        this.$message.warning(this.$t("basic.incomplete_registration_information").toString());
        return;
      }

      this.getSourceAndTopicNumber();
      this.isPublished = true;
      // 文章状态设为已发布类型
      this.articleStatus = 2;

      const requestData = {
        articleTitle: this.articleTitle,
        articleContent: this.articleContent,
        contentWithStyle: this.contentWithStyle,
        articleSource: this.articleSourceNumber,
        articleTopic: this.articleTopicNumber,
        articleStatus: this.articleStatus
      };

      const config = {
        headers: {
          'Authorization': sessionStorage.getItem("token") // 替换 yourToken 为实际的 token 值
        },
      };

      this.$http.post('/addArticle', requestData, config).then(res => {
        if (res.data.code === 200) {
          this.$message.success(this.$t("basic.publish_success_text").toString());
          this.$router.go(-1);
        }
      }).catch(err => {
        console.error(err);
      }).finally(() => {
        this.isPublished = false;
      })

    }
    ,

    getSourceAndTopicNumber() {
      if(this.isChooseCustomTopic){
      //   需要订阅的专题
        this.topicList.map(topic=>{
          if(topic.topicName === this.articleTopic){
            this.articleTopicNumber = topic.topicId;
          }
        })
      }else{
        // 公开的专题
        switch (this.articleTopic) {
          case "市场资讯":
            this.articleTopicNumber = 1;
            break;
          case "研究报告":
            this.articleTopicNumber = 2;
            break;
          case "观点与访谈":
            this.articleTopicNumber = 3;
            break;
          default:
            this.articleTopicNumber = -1; // 如果不是以上任何一个主题，则设置为-1或其他默认值
            break;
        }
      }

      switch (this.articleSource) {
        case "原创":
          this.articleSourceNumber = 1;
          break;
        case "转载":
          this.articleSourceNumber = 2;
          break;
        case "翻译":
          this.articleSourceNumber = 3;
          break;
        default:
          this.articleSourceNumber = -1; // 如果不是以上任何一种source，则设置为-1或其他默认值
          break;
      }
    }
    ,
  }

}
</script>

<style scoped>
.edit-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow-y: hidden;
}

.edit-navigation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #ebebeb;
  width: 100%;
  height: 50px;
  background-color: #545c64;
  color: #42b983;
}

.edit-title {
  padding: 0 5px;
  height: 40px;
}

.edit-title >>> .el-input__inner {
  border: 0;
  border-bottom: 2px solid #ebebeb;
}

.edit-content {
  width: 100%;
  height: calc(100vh - 90px);
}

.edit-content >>> .el-textarea__inner {
  border: 0;
  height: 100%;
}

.edit-article-source {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 15px 15px;
}

.article-message-settings {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.article-setting-label {
  padding: 10px;
}

.edit-article-topic {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 15px 15px;
}

.edit-article-topic >>> ::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.custom-topic-edit {
  margin-left: 160px;
  width: 100%;
  font-size: small;
}

.custom-topic-container{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 15px 15px;
}

.custom-topic-container >>> ::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.submit-article {
  margin-top: auto;
  padding: 15px 0;
}

</style>
