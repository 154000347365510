<template>
  <div class="topic-style">
    <el-menu
        style="width: 70%;min-width: 540px"
        class=""
        mode="horizontal"
        @select="jumpToIndexMenu"
        background-color="#545c64"
        text-color="#fff"
        active-text-color="#42b983">
      <el-menu-item index="1" style="">{{$t("basic.market_info_text")}}</el-menu-item>
      <el-menu-item index="2" style="">{{$t("basic.research_report_text")}}</el-menu-item>
      <el-menu-item index="3" style="">{{$t("basic.perspectives_and_interviews_text")}}</el-menu-item>
<!--      <el-menu-item index="4">{{$t("basic.report_text")}}</el-menu-item>-->
<!--      <el-menu-item index="5">{{$t("basic.data_analysis_text")}}</el-menu-item>-->
      <el-menu-item>
        <router-link to="/gaoFundSystem" class="jump-link-style">
          <div>高方德组合优化系统</div>
        </router-link>
      </el-menu-item>
    </el-menu>

    <el-menu
        style="width: 30%;min-width: 345px"
        mode="horizontal"
        background-color="#545c64"
        text-color="#fff"
        active-text-color="#42b983">
      <el-menu-item style="">
        <router-link to="/user"  class="jump-link-style">
          <div>
            <el-image
                style="width: 45px; height: 45px;border-radius: 50%"
                :src="imageUrl ? imageUrl : require('@/default_image.jpg')"
                fit="cover"
            ></el-image>

            <span style="margin-left: 5px">{{nickname}}</span>
          </div>
        </router-link>
      </el-menu-item>

      <el-menu-item style="" v-if="isShowMenu === 'true'">
        <el-button class="submit-btn" @click="$router.push('/editArticle')">
          {{$t("basic.publish_text")}}</el-button>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script >
export default {
  name:"ComputerArticleNavMenu",

  data(){
    return{
      nickname: sessionStorage.getItem("nickname") || "",
      username: sessionStorage.getItem('username') || "",
      isShowMenu:sessionStorage.getItem("isShowMenu"),
      imageUrl:sessionStorage.getItem("userImage"),

    }
  },

  methods:{
    jumpToIndexMenu(key, keyPath) {
      // sessionStorage.setItem("homeMenuIndex",key);
      this.$router.push({name:"home",params:{menuIndex:key}});
    },
  }
}
</script>

<style scoped>
.topic-style {
  height: 61px;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  /*overflow-x: auto; !* 水平方向超出部分显示滚动条 *!*/
  /*-webkit-overflow-scrolling: touch; !* 兼容iOS的滚动效果 *!*/
}

.submit-btn{
  background: #42b983;
  color: white;
  font-weight: bold;
  border-radius: 10px;
}

.jump-link-style{
  text-decoration: none;
}
</style>