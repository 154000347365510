<template>
  <LayoutContainer>
    <template v-slot:my-header>
      <div>
        <el-dropdown trigger="click">
              <span class="el-dropdown-link" style="cursor: pointer;color:#42b983;font-weight: bold">
                {{ $t("basic.current_account") }}{{ username }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="signOut">{{ $t("basic.quit_account") }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </template>
    <div>
      <h1 style="font-size: 20px">{{ $t("basic.record_management_title") }}</h1>

      <el-table
          v-loading="loading"
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 80%"
          stripe
          height="500px"
          @selection-change="handleSelectionChange">
        <el-table-column
            type="selection"
            width="55">
        </el-table-column>
        <el-table-column
            prop="createTime"
            :label="$t('basic.create_time')"
            width="120">
        </el-table-column>
        <el-table-column
            prop="recordContent"
            :label="$t('basic.record_content')"
            show-overflow-tooltip>
        </el-table-column>
        <el-table-column
            prop="bookmarkRecord"
            fixed="right"
            align="center"
            :label="$t('basic.operation')"
            width="100px">
          <template v-slot="scope">
                  <span v-if="!scope.row.isBookmark" @click="bookmarkRecord(scope.row)">
                    <i class="el-icon-star-off"></i>
                  </span>
            <span v-else>
                    <i class="el-icon-star-on" style="color:red"></i>
                  </span>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 20px">
        <!--      <el-button @click="toggleSelection([tableData[1], tableData[2]])">切换第二、第三行的选中状态</el-button>-->
        <!--      <el-button @click="toggleSelection()">取消选择</el-button>-->
        <el-button @click="deleteRecordsByIds">{{ $t("basic.delete_button") }}</el-button>
      </div>
    </div>

    <el-dialog :title="$t('basic.add_bookmark_title')" :visible.sync="bookmarkDialogVisible" width="800px">
      <el-form>
        <el-form-item :label="$t('basic.bookmark_name')" label-width=""
                      style="display: flex;justify-content: flex-start">
          <el-input v-model="bookmarkName" autocomplete="off" style="width: 200px;"
                    :placeholder="$t('basic.bookmark_name_tips')"></el-input>
          <span style="margin-left: 120px;margin-right: 10px">{{ $t("basic.portfolio_type") }}</span>
          <el-select v-model="portfolioType" :placeholder="$t('basic.portfolio_type_tips')" style="width: 195px">
            <el-option
                v-for="item in portfolioTypeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('basic.bookmark_content')" style="display: flex">
          <el-input v-model="bookmarkContent" style="width:580px" disabled></el-input>
        </el-form-item>
        <el-form-item :label="$t('basic.add_bookmark_button_text')" label-width="">
          <el-transfer
              :titles="[$t('basic.available_options_list'),$t('basic.selected_options_list')]"
              filterable
              :filter-placeholder="$t('basic.enter_code_tips')"
              v-model="securityCodeValue"
              :data="securityData">
          </el-transfer>
        </el-form-item>
        <el-form-item style="display: flex;justify-content: center">
          <el-button type="" @click="addCodesToBookmarkContent" :disabled="isAddButtonDisabled">{{
              $t("basic.add_text")
            }}
          </el-button>
        </el-form-item>
        <el-form-item style="display: flex;justify-content: center">
          <el-button type="primary" @click="addBookmark" v-loading="addBookmarkFlag">{{
              $t("basic.add_bookmark_text")
            }}
          </el-button>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
      </div>
    </el-dialog>

  </LayoutContainer>

</template>

<script>
import moment from "moment";
import LayoutContainer from "@/components/layout/LayoutContainer";

export default {
  name: "RecordManage",
  components: {LayoutContainer},
  data() {
    return {
      bookmarkDialogVisible: false,
      isCollapse: true,
      loading: true,
      isAddButtonDisabled: true,
      addBookmarkFlag: false,
      isAddNewCodes: false,

      recordIds: [],
      records: [],
      createTime: [],
      multipleSelection: [],
      tableData: [],
      securityData: [],
      securityCodeValue: [],

      bookmarkName: '',
      bookmarkContent: '',
      recordUUid: '',
      portfolioType: '',
      username: sessionStorage.getItem("username"),

      portfolioTypeOptions: [{
        value: '1',
        label: this.$t("basic.portfolio_type_stock")
      },
        // {
        //   value: '2',
        //   label: this.$t("basic.portfolio_type_bond")
        // },
        {
          value: '3',
          label: this.$t("basic.portfolio_type_fund")
        },
        // {
        //   value: '5',
        //   label: this.$t("basic.portfolio_type_index")
        // },
      ],
    }
  },

  created() {
    this.loadAllRecords();
    this.getSecurityCodeList();
  },

  watch: {
    securityCodeValue(newValue) {
      // 如果 securityCodeValue 有值，则返回 false，否则返回 true
      this.isAddButtonDisabled = newValue.length === 0;
    },
  },

  methods: {
    collapseNav() {
      this.isCollapse = !this.isCollapse;
    },

    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },

    addCodesToBookmarkContent() {
      this.isAddNewCodes = true;

      let existedCodes = '';
      let legalCodes = '';

      this.securityCodeValue.forEach((key) => {
        if (this.bookmarkContent.includes(this.securityData[key]['label'])) {
          //  添加的codes再content中已存在
          existedCodes += this.securityData[key]['label'] + ',';
        } else {
          legalCodes += this.securityData[key]['label'] + ',';
        }
      })

      if (existedCodes !== '') {
        this.$message.warning(existedCodes + this.$t("basic.code_exist_err").toString())
        return;
      }

      if (this.bookmarkContent.endsWith(',')) {
        this.bookmarkContent += legalCodes;
      } else {
        this.bookmarkContent += ',' + legalCodes;
      }
      this.$message.success(this.$t("basic.add_success_text").toString());
    },

    getSecurityCodeList() {
      this.$http.get('/getSecurityCodeList', {
        headers: {
          'Authorization': sessionStorage.getItem("token") // 替换 yourToken 为实际的 token 值
        }
      }).then(response => {
        console.log(response)
        const data = [];
        response.data.forEach((code, index) => {
          data.push({
            label: code,
            key: index,
          });
        });
        this.securityData = data;
      }).catch(err => {
        console.error(err);
      })
    },

    loadAllRecords() {
      let recordData = [];
      this.$http.get("/getRecordByToken", {
        headers: {
          'Authorization': sessionStorage.getItem("token") // 替换 yourToken 为实际的 token 值
        }
      }).then(response => {
        console.log(response);
        this.tableData = response.data.map(item => ({
          recordId: item.id,
          recordUUid: item.uuid,
          recordContent: item.recordContent,
          createTime: moment(item.createTime).format('yyyy-MM-DD'),
          isBookmark: item.isBookmark === 1 ? true : false,
          // isBookmark:true,
        }));
      }).catch(error => {
        console.error(error)
      }).finally(() => {
        this.loading = false;
      })
      return recordData;
    },

    bookmarkRecord(row) {
      this.bookmarkDialogVisible = true;
      this.bookmarkContent = row.recordContent;
      this.recordUUid = row.recordUUid;
    },

    async addBookmark() {
      if (!this.bookmarkName || !this.portfolioType || !this.securityCodeValue || this.securityCodeValue === null) {
        this.$message.warning(this.$t("basic.form_filling_err").toString());
        return;
      }
      this.addBookmarkFlag = true;

      const param = {
        username: this.username,
        bookmarkName: this.bookmarkName,
        portfolioType: this.portfolioType,
        bookmarkContent: this.bookmarkContent,
        createTime: new Date(),
        isFromRecord: 1,
      };

      if (this.isAddNewCodes) {
        param.isFromRecord = 0;
      }

      this.$http.post("/addBookmark", param, {
        headers: {
          'Authorization': sessionStorage.getItem("token") // 替换 yourToken 为实际的 token 值
        }
      }).then(async res => {
        console.log(res);
        if (res.data === 'success') {
          if (!this.isAddNewCodes) {
            await this.updateRecord();  // 将更新逻辑提前执行
          }

          this.$router.go(0);
          this.$message.success(this.$t("basic.add_success_text").toString());
        } else {
          this.$message.error(this.$t("basic.add_failed_text").toString());
        }
      }).catch(err => {
        console.error(err);
      }).finally(() => {
        this.addBookmarkFlag = false;
        this.isAddNewCodes = false;
      })

    },

    async updateRecord() {
      const param1 = {
        isBookmark: 1,
        uuid: this.recordUUid,
      };
      this.$http.post('/updateRecordByToken', param1, {
        headers: {
          'Authorization': sessionStorage.getItem("token") // 替换 yourToken 为实际的 token 值
        }
      }).then(response => {
        console.log(response)
      }).catch(error => {
        console.error(error)
      })
    },

    deleteRecordsByIds() {
      if (this.multipleSelection.length === 0 || this.multipleSelection === null) {
        this.$message.warning(this.$t("basic.no_record_choose_err").toString());
        return;
      }
      this.$confirm(this.$t("basic.delete_confirm").toString(), this.$t("basic.delete_confirm_title").toString(), {
        confirmButtonText: this.$t("basic.confirm_button_text").toString(),
        cancelButtonText: this.$t("basic.cancel_button_text").toString(),
        type: 'warning',
      }).then(() => {
        const ids = this.multipleSelection.map((item) => (item.recordId))
        this.$http.post("/deleteRecordsByIds", ids,{
          headers: {
            'Authorization': sessionStorage.getItem("token") // 替换 yourToken 为实际的 token 值
          }
        }).then((response) => {
          console.log(response)
          if (response.data.code === 1) {
            this.$message.success(this.$t("basic.delete_success").toString());
            this.tableData = this.tableData.filter((item) => !ids.includes(item.recordId))
          } else {
            this.$message.error(this.$t("basic.delete_failed").toString());
          }
        }).catch((err) => {
          console.error(err)
        })
      }).catch(() => {
        this.$message.info(this.$t("basic.delete_cancel").toString());
      })


    },


    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },

    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    signOut() {
      sessionStorage.setItem("username", '');
      sessionStorage.setItem("isLoggedIn", '');
      this.$message.success(this.$t("basic.sign_out_success").toString());
      this.$router.push('/login');
    },
  },
}
</script>

<style scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}

.toggle-button {
  width: 100%;
}

.header-style {
  background-color: #2c3e50;
  color: #42b983;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
