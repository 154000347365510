import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "axios"
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/css/global.css'
import i18n from './lang'
import 'font-awesome/css/font-awesome.css'
import intro from 'intro.js' // introjs库
import 'intro.js/introjs.css' // introjs默认css样式
// introjs还提供了多种主题，可以通过以下方式引入
import 'intro.js/themes/introjs-modern.css'
import {resolve} from "crypto-browserify/example/bundle"; // introjs主题
import VueSmoothScroll from "vue2-smooth-scroll";
import Viewer from 'v-viewer';
import 'viewerjs/dist/viewer.css';


Vue.config.productionTip = false
// axios.defaults.baseURL = "http://localhost:8081"
// axios.defaults.baseURL = "http://192.168.10.36:8081"
// axios.defaults.baseURL = "http://114.55.124.250:8081"
axios.defaults.baseURL = "http://47.103.73.140:8081"

axios.defaults.headers.common['Access-Control-Allow-Methods'] = 'GET, POST';
Vue.prototype.$intro = intro
Vue.prototype.$http = axios

Vue.use(ElementUI)
// 隐藏 右侧浏览器 滚动条
Vue.use(VueSmoothScroll)

// 使用图片放大插件
Vue.use(Viewer)
//设置初始值
Viewer.setDefaults({
    Options: {
        'inline': true,
        'button': true,
        'navbar': true,
        'title': false,
        'toolbar': true,
        'tooltip': true,
        'movable': true,
        'zoomable': true,
        'rotatable': true,
        'scalable': true,
        'transition': true,
        'fullscreen': true,
        'keyboard': false,
        'url': 'data-source'
    }
})

new Vue({
    i18n,
    router,
    store,
    render: h => h(App)
}).$mount('#app')
