<template>
  <div class="login-wrap">
    <div style="height: 15vh">
      <h1 class="sys-title">高方德基金组合优化系统</h1>
      <h3 style="color: gold;text-shadow: 16px 16px 32px rgba(0, 0, 0, 0.5);font-weight: bolder;font-size: 5vw;text-align: center">
        GaoFund Portfolio Optimizer</h3>
    </div>

    <el-form class="login-container">
      <h2 class="title">{{$t("basic.register_title")}}</h2>
      <el-form-item class="input-form-style" :label="$t('basic.username')">
        <el-input style="width: 70%" type="text" v-model="uname" :placeholder="$t('basic.username_enter_tips')" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item class="input-form-style" :label="$t('basic.password')">
        <el-input style="width: 70%" type="password" v-model="password" :placeholder="$t('basic.password_enter_tips')" autocomplete="off" show-password></el-input>
      </el-form-item>
      <el-form-item class="input-form-style" :label="$t('basic.email')" v-model="email">
          <el-input style="width: 70%" type="text" v-model="email" :placeholder="$t('basic.email_enter_tips')" autocomplete="off" v-on:keyup.native.enter="sendEmail"></el-input>
      </el-form-item>

      <el-form-item style="" :label="$t('basic.verification_code')">
        <el-input style="width: 45%" v-model="verifyCode" :placeholder="$t('basic.verification_code_enter_tips')" v-on:keyup.native.enter="dosubmit"></el-input>
        <el-button size="small" v-if="!sendEmailFlag" style="margin-left: 5px;color: dodgerblue;font-weight: bold" @click="sendEmail">{{ $t("basic.get_verification_code_button") }}</el-button>
        <el-button size="small" v-else style="margin-left: 5px" disabled type="info">发送中...</el-button>
      </el-form-item>

      <el-form-item>
        <el-button v-if="!RegisterFlag" type="primary" style="width: 100%;" @click="dosubmit()" :disabled="!isSendEmail">{{$t("basic.register_button")}}</el-button>
        <el-button v-else-if="RegisterFlag === true" style="width: 100%;" type="info" disabled>
          注册中...
        </el-button>
        <span class="registerButton" @click="toLogin" style="font-size: small">{{$t("basic.sign_in_tips")}}</span>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'MobileRegister',
  data: function () {
    return {
      uname: "",
      password: "",
      email: '',
      verifyCode:'',
      loading: false,
      RegisterFlag:false,
      isSendEmail: false,
      sendEmailFlag:false,
    }
  },
  methods: {
    toLogin() {
      this.$router.push('/login')
    },
    dosubmit: function () {
      if (!this.uname || !this.password || !this.email || !this.verifyCode) {
        this.$message.warning(this.$t("basic.incomplete_registration_information").toString())
      } else {
        this.RegisterFlag = true;

        let userData = {
          username: this.uname,
          password: this.password,
          email: this.email,
          verificationCode:this.verifyCode,
        };

        const config = {

        };

        this.$http.post('/user/Register', userData
        ).then(response => {
          if (response.data.code === "REGISTER_SUCCESS") {
            console.log(response.data)
            this.$message({
              type: 'success',
              message: this.$t("basic.register_success").toString()
            })
            this.$router.push('/login')
          } else if(response.data.code === 'REGISTER_ERR'){
            this.$message.error(this.$t("basic.register_failed").toString())
          }
          else if(response.data.code === 'CODE_ERR'){
            this.$message.error(this.$t("basic.incorrect_verification_code").toString())
          }else if(response.data.code === 'TIMEOUT_ERR'){
            this.$message.error(this.$t("basic.verification_timeout").toString())
          }else if(response.data.code === 'USERNAME_ALREADY_EXIST'){
            this.$message.error(this.$t("basic.username_already_exist").toString())
          }
        }).catch((error) => {
          console.log(error)
          this.$message({
            type: "error",
            message: ''
          })
        })
        this.RegisterFlag = false;
      }

    },

    sendEmail() {
      if (!this.email || !this.uname || !this.password) {
        this.$message.warning(this.$t("basic.incomplete_registration_information").toString())
        return;
      }
      this.sendEmailFlag = true;
      let requestData = {
        username:this.uname,
        password:this.password,
        email: this.email
      }
      this.$http.post("/user/sendEmail", requestData).then(response => {
        if (response.data.code === 'EMAIL_SEND_SUCCESS') {
          this.$message.success(this.$t("basic.email_send_success").toString())
          this.isSendEmail = true
        } else if(response.data.code === 'EMAIL_NOT_EXIST_ERR'){
          this.$message.error(this.$t("basic.email_no_exist_err").toString())
        }else if(response.data.code === 'EMAIL_SEND_ERR'){
          this.$message.error(this.$t("basic.email_send_err").toString())
        }else if(response.data.code === 'USERNAME_ALREADY_EXIST'){
          this.$message.error(this.$t("basic.username_already_exist").toString())
        }
      }).catch(error => {
        console.log(error)
      }).finally(()=>{
            this.sendEmailFlag = false;
          }
      );
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.sys-title {
  text-align: center;
  color: gold;
  font-size: 8vw;
  font-weight: bold;
  /*font-size: 30px;*/
  /*margin-left: 43%;*/
  margin-top: 5vh;
  text-shadow: 8px 8px 16px rgba(0, 0, 0, 0.5); /* 可根据需要调整阴影的偏移和模糊程度 */
}

.login-wrap {
  width: 100%;
  height: 100vh;

  background-image: url('../bg.jpeg');
  background-size: 100% 100%;
  position:fixed;

  /*
   background-position: center right;
  background-size: 100%; */
}

.login-container {
  border-radius: 5vw;
  margin: 0vh auto;
  width: 90vw;
  height: auto;
  padding: 3vh 3vw;
  background: #fff;
  border: 1px solid #eaeaea;
  text-align: left;
  box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.1);
}

.title {
  margin: 0px auto 4vh auto;
  text-align: center;
  color: #505458;
}

.registerButton {
  margin-top: 1%;
  cursor: pointer;
  user-select: none;
}

/* 鼠标悬停时改变背景颜色 */
.registerButton:hover {
  background-color: lightgray; /* 悬停时的背景颜色 */
}

.input-form-style{
  width: 90vw;
  height: 5vh;
}
</style>
