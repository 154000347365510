<template>
  <transition name="slide-right">
    <div class="page-container">
      <div class="topic-navigate">
        <i class="fa fa-reply" style="color: #ffffff;padding: 10px" @click="backToHome"></i>
        <el-dropdown trigger="click">
          <span class="el-dropdown-link">
            <i class="el-icon-more" v-if="isCurrentUserArticle"
               style="color: #ffffff;padding: 10px;font-size: large"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="updateArticle">
              <i class="fa fa-pencil-square-o"></i>
              <span>{{ $t("basic.update_article_text") }}</span>
            </el-dropdown-item>

            <el-dropdown-item v-if="articleStatus === 2" @click.native="undercarriageArticleDialogVisible = true">
              <i class="fa fa-archive"></i>
              <span>{{ $t("basic.undercarriage_text") }}</span>
            </el-dropdown-item>

            <el-dropdown-item @click.native="confirmDeleteDialogVisible = true">
              <i class="fa fa-trash-o"></i>
              <span>{{ $t("basic.delete_button_text") }}</span>
            </el-dropdown-item>

          </el-dropdown-menu>
        </el-dropdown>
      </div>

      <div class="article-container" v-loading="articleLoading">
        <div class="article-title">
          <span>{{ articleTitle }}</span>
        </div>

        <div class="author-container">
          <el-image
              style="width: 50px; height: 50px;border-radius: 50%"
              :src="authorImageUrl"
              fit="cover"
          ></el-image>

          <div style="display: flex;flex-direction: column;align-items: flex-start">
            <div style="display: flex;flex-direction: row;align-items: center">
              <span style="padding: 10px 10px">{{ authorName }}</span>
              <el-tag :type="typeJudge" size="small">{{ articleSource }}</el-tag>
            </div>

            <span
                style="font-weight: normal;font-size: 12px;padding: 5px 10px">{{
                $t('basic.published_on_text') + ' ' + releaseTime
              }}</span>
          </div>
        </div>

        <div class="article-content-container">
          <div v-html="contentWithStyle" class="ql-editor" v-viewer></div>
        </div>

        <div class="comment-section-container">
          <div class="comment-title">
            <h2 style="margin: 20px 5px 20px 20px">{{ $t("basic.comment_section_text") }}</h2>
            <span style="font-size: large;margin-left: 10px">{{ articleComments }}</span>
          </div>

          <div class="published-comment-container">
            <div class="published-comment-card" v-for="(comment,index) in commentList" :key="index">
              <div class="all-comment-container">
                <div class="root-comment-container">
                  <div class="root-comment-user-msg">
                    <el-image
                        style="width: 30px; height: 30px;border-radius: 50%"
                        :src="comment.rootComment.userImage"
                        fit="cover"
                    ></el-image>

                    <span style="margin-left: 10px">{{ comment.rootComment.nickname }}</span>
                  </div>

                  <div class="root-comment-content">
                    <span>{{ comment.rootComment.commentContent }}</span>
                  </div>

                  <div class="comment-msg">
                    <span
                        style="font-size: small;margin: 20px 20px 20px 40px">{{
                        moment(comment.rootComment.createdAt).format('yyyy-MM-DD HH:mm:ss')
                      }}</span>

                    <div v-if="nickname !== ''">
                      <el-button type="text"
                                 style="font-size: small; color: #949797"
                                 @click="toggleReplyContainer(index)"
                      >
                        {{ $t("basic.reply_text") }}
                      </el-button>
                    </div>

                    <el-dropdown trigger="click" :hide-on-click="false"
                                 v-if="comment.rootComment.nickname === nickname">
                      <span class="el-icon-more" style="cursor: pointer">
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item>
                          <el-popover
                              placement="top"
                              width="160"
                              v-model="confirmDeleteCommentVisible[index]">
                            <p>{{ $t("basic.delete_comment_confirm_text") }}</p>
                            <div style="text-align: right; margin: 0">
                              <el-button size="mini" type="text" @click="confirmDeleteCommentVisible[index] = false">
                                {{ $t("basic.discard_text") }}
                              </el-button>
                              <el-button type="primary" size="mini"
                                         @click="deleteRootComment(comment.rootComment.rootCommentId,index)">
                                {{ $t("basic.yes_text") }}
                              </el-button>
                            </div>
                            <el-button slot="reference" type="text">{{ $t("basic.delete_button_text") }}</el-button>
                          </el-popover>
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>

                  <div class="sub-comment-container">
                    <div class="sub-comment-card" v-for="(subComment,subIndex) in comment.subCommentList"
                         :key="subIndex">
                      <!--                    子评论-->
                      <div class="sub-comment-user-msg">
                        <!--                        <el-image-->
                        <!--                            style="width: 30px; height: 30px;border-radius: 50%"-->
                        <!--                            :src="subComment.userImage"-->
                        <!--                            fit="cover"-->
                        <!--                        ></el-image>-->

                        <span
                            style="margin-left: 10px">{{ subComment.nickname + ' ' + subComment.commentContent }}</span>
                      </div>

                      <!--                      <div class="sub-comment-content">-->
                      <!--                        <span>{{ subComment.commentContent }}</span>-->
                      <!--                      </div>-->

                      <div class="comment-msg">
                      <span
                          style="">{{
                          moment(subComment.createdAt).format('yyyy-MM-DD HH:mm:ss')
                        }}
                      </span>

                        <div>
                          <el-button type="text"
                                     style="font-size: small; color: #949797"
                                     @click="toggleSubReplyContainer(index,subIndex)"
                          >
                            {{ $t("basic.reply_text") }}
                          </el-button>
                        </div>

                        <el-dropdown trigger="click" :hide-on-click="false"
                                     v-if="subComment.nickname === nickname">
                      <span class="el-icon-more" style="cursor: pointer">
                      </span>
                          <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item>
                              <el-popover
                                  placement="top"
                                  width="160"
                                  v-model="confirmDeleteSubCommentVisible[index][subIndex]">
                                <p>{{ $t("basic.delete_comment_confirm_text") }}</p>
                                <div style="text-align: right; margin: 0">
                                  <el-button size="mini" type="text" @click="updateSubVisibleArray(index,subIndex)">
                                    {{ $t("basic.discard_text") }}
                                  </el-button>
                                  <el-button type="primary" size="mini"
                                             @click="deleteSubComment(subComment,index,subIndex)">
                                    {{ $t("basic.yes_text") }}
                                  </el-button>
                                </div>
                                <el-button slot="reference" type="text">{{ $t("basic.delete_button_text") }}</el-button>
                              </el-popover>
                            </el-dropdown-item>
                          </el-dropdown-menu>
                        </el-dropdown>
                      </div>

                      <!--                  回复子评论-->
                      <div class="reply-container"
                           v-show="activeSubReplyIndex.parentIndex === index && activeSubReplyIndex.subIndex === subIndex">
                        <el-input :placeholder="$t('basic.reply_text') + ' ' + subComment.nickname + ': '"
                                  v-model="replyCommentContainer"
                        ></el-input>

                        <el-button style="margin: 5px 0"
                                   type="primary"
                                   :disabled="replyCommentContainer === ''"
                                   @click="replyComment(subComment)"
                                   v-loading="replyFlag"
                        >{{ $t("basic.reply_text") }}
                        </el-button>
                      </div>

                    </div>


                  </div>

                  <!--              回复根评论    -->
                  <div class="reply-container" v-show="activeReplyIndex === index">
                    <el-input :placeholder="$t('basic.reply_text') + ' ' + comment.rootComment.nickname + ': '"
                              v-model="replyCommentContainer"
                    ></el-input>

                    <el-button style="margin: 5px 0"
                               type="primary"
                               :disabled="replyCommentContainer === ''"
                               @click="replyComment(comment.rootComment)"
                               v-loading="replyFlag"
                    >{{ $t("basic.reply_text") }}
                    </el-button>
                  </div>

                </div>

                <!--                当前根评论总回复数量统计-->
                <div class="root-comment-reply-count"
                     v-if="!checkAllSubCommentFlag[index] && comment.rootComment.rootCommentReplyCount > 0">
                  <span>{{ $t('basic.total_reply_count_text') + comment.rootComment.rootCommentReplyCount }}</span>
                  <el-button type="text" v-if="comment.rootComment.rootCommentReplyCount > 3"
                             @click="selectAllSubCommentByRootCommentId(comment.rootComment.id,index)">
                    {{ $t("basic.check_all_text") }}
                  </el-button>
                </div>

              </div>

            </div>
          </div>
        </div>
      </div>


      <div class="multi-button-container">
        <el-button v-if="nickname !== ''" type="text" size="small" @click="drawerVisible = true"
                   style="color: #949797;background-color: #ebebea;border-radius: 50% / 25vw;width: 50vw"
        >
          {{$t("basic.click_to_comment_text")}}
        </el-button>

        <el-button v-else type="text" size="small" @click="router().push('/login')"
                   style="color: #949797;background-color: #ebebea;border-radius: 50% / 25vw;width: 50vw"
        >
          {{$t("basic.click_to_comment_text")}}
        </el-button>

<!--        转发-->
        <div class="function-container" @click="copyArticleUrl">
          <i class="fa fa-mail-forward fa-2x"></i>
          <span style="font-size: 10px">{{$t("basic.transmit_text")}}</span>
        </div>

<!--        点赞-->
        <div class="function-container" v-if="!isClickLike" @click="addLikeCount">
          <i class="fa fa-thumbs-o-up fa-2x"></i>
          <span style="font-size: 10px">{{$t("basic.like_text")}}</span>
        </div>

        <div class="function-container" v-else>
          <i class="fa fa-thumbs-up fa-2x" style="color: #EF6B6F"></i>
          <span style="font-size: 10px">{{articleLike + 1}}</span>
        </div>
      </div>

      <!--      删除确认-->
      <el-dialog
          :visible.sync="confirmDeleteDialogVisible"
          width="75%"
      >
        <span>{{ $t("basic.confirm_delete_text") }}</span>
        <span slot="footer" class="dialog-footer"
              style="display: flex;align-items: center;justify-content: space-around">
          <el-button @click="confirmDeleteDialogVisible = false">{{ $t("basic.discard_text") }}</el-button>
          <el-button type="primary" @click="deleteArticle">{{ $t("basic.yes_text") }}</el-button>
        </span>
      </el-dialog>

      <!--    下架确认-->
      <el-dialog
          v-loading="undercarriageFlag"
          :visible.sync="undercarriageArticleDialogVisible"
          width="75%"
      >
        <span>{{ $t("basic.confirm_undercarriage_text") }}</span>
        <span slot="footer" class="dialog-footer"
              style="display: flex;align-items: center;justify-content: space-around">
        <el-button @click="undercarriageArticleDialogVisible = false">{{ $t("basic.discard_text") }}</el-button>
        <el-button type="primary" @click="undercarriageArticle">{{ $t("basic.yes_text") }}</el-button>
      </span>
      </el-dialog>

      <!--      评论输入框详情-->
      <el-drawer
          :visible.sync="drawerVisible"
          direction="btt"
          :show-close="false"
          size="20%"
          :withHeader="false"
      >
        <div class="submit-comment-container">
          <!--            登陆后显示评论输入框-->
          <div style="width: 100%;height: 13vh">
            <!--            <el-image-->
            <!--                style="width: 50px; height: 50px;border-radius: 50%;margin-right: 10px"-->
            <!--                :src="imageUrl"-->
            <!--                fit="cover"-->
            <!--            ></el-image>-->

            <el-input
                v-model="commentEdit"
                :placeholder="$t('basic.enter_comment_tips')"
                type="textarea"
            ></el-input>
          </div>

          <div style="">
            <el-button size="small" type="primary" @click="submitComment" :disabled="commentEdit === ''"
                       v-loading="publishCommentFlag">{{ $t("basic.publish_btn_text") }}
            </el-button>
          </div>

        </div>
      </el-drawer>
    </div>

  </transition>
</template>

<script>
import moment from "moment";
import router from "@/router";

export default {
  name: "MobileArticleDetails",

  created() {
    this.getArticleById();
    this.moment = moment;
  },

  mounted() {
    // // 获取articleId
    // const articleId = this.$route.params.id;
    // // 使用articleId进行相关操作
    // this.$message.success(articleId);

  },

  data() {
    return {
      isClickLike:false,
      drawerVisible: false,
      undercarriageFlag: false,
      undercarriageArticleDialogVisible: false,
      publishCommentFlag: false,
      replyFlag: false,
      articleLoading: true,
      isCurrentUserArticle: false,
      confirmDeleteDialogVisible: false,

      nickname: sessionStorage.getItem('nickname') || "",
      username: sessionStorage.getItem('username') || "",
      imageUrl: sessionStorage.getItem("userImage"),

      articleLike:0,
      articleComments: 0,
      activeReplyIndex: -1, // 存储当前显示输入框的索引，-1表示没有输入框显示
      activeSubReplyIndex: {
        parentIndex: -1,
        subIndex: -1
      }, // 存储当前显示输入框的索引，-1表示没有输入框显示

      articleId: "",
      articleTitle: "",
      articleContent: "",
      articleSource: "",
      articleStatus: "",
      releaseTime: "",
      contentWithStyle: "",
      authorImageUrl: "",
      authorName: "",
      typeJudge: "",
      commentEdit: "",
      replyCommentContainer: "",

      confirmDeleteCommentVisible: {},
      confirmDeleteSubCommentVisible: {},
      replyIndexContainer: {},
      checkAllSubCommentFlag: {},

      commentList: [],
    }
  },

  methods: {
    addLikeCount(){
      const requestData = {
        articleId: this.articleId,
      }

      this.$http.post('/addLikeCount',requestData).then(response=>{
        if (response.data.code === 200){
          this.isClickLike = true;
        }else {
          this.$message.warning(this.$t("basic.like_failed_text").toString());
        }
      }).catch(err=>{
        console.error(err);
      })
    },

    router() {
      return router
    },

    copyArticleUrl(){
      const url = window.location.href;
      // console.log("Attempting to copy URL:", url);

      // 创建一个临时输入元素
      const tempInput = document.createElement('input');
      tempInput.value = url;
      document.body.appendChild(tempInput);
      tempInput.select();
      const success = document.execCommand('copy');
      document.body.removeChild(tempInput);

      if (success) {
        // console.log("URL copied successfully");
        this.$message.success(this.$t("basic.copy_url_tips").toString());
      } else {
        // console.error("Failed to copy URL");
        this.$message.error(this.$t("basic.copy_url_error_tips").toString());
      }
    },

    selectAllSubCommentByRootCommentId(id, index) {
      const requestData = {
        rootCommentId: id
      }

      this.$http.get('/selectAllSubCommentByRootCommentId', {
        params: requestData
      }).then(res => {
        console.log(res)
        this.$set(this.commentList[index], 'subCommentList', res.data.subCommentList);
        this.checkAllSubCommentFlag[index] = true;
      }).catch(err => {
        console.error(err);
      })
    },

    updateSubVisibleArray(index, subIndex) {
      this.$set(this.confirmDeleteSubCommentVisible[index], subIndex, false);
    },

    replyComment(comment) {
      const requestData = {
        articleId: this.articleId,
        commentContent: ' ' + this.$t("basic.reply_text") + ' ' + comment.nickname + ': ' + this.replyCommentContainer,
        commentType: 2,
        parentCommentId: comment.id,
        repliedCommentId: comment.id,
        rootCommentId: comment.rootCommentId,
      }

      const config = {
        headers: {
          'Authorization': sessionStorage.getItem("token") // 替换 yourToken 为实际的 token 值
        }
      }

      this.replyFlag = true;

      this.$http.post('/insertComment', requestData, config).then(res => {
        if (res.data.code === 200) {
          this.$message.success(this.$t("basic.publish_success_text").toString());
          this.replyCommentContainer = "";

          //   重新获取当前文章的所有评论
          this.getArticleComments();
          //   评论数+1
          this.articleComments += 1;
          //   关闭评论输入框
          this.activeSubReplyIndex.subIndex = -1;
          this.activeSubReplyIndex.parentIndex = -1;
          this.activeReplyIndex = -1;
        } else {
          this.$message.error(this.$t("basic.publish_failed_text").toString());
        }
      }).catch(err => {
        console.error(err);
        this.$message.error(this.$t("basic.publish_failed_text").toString());
      }).finally(() => {
        this.replyFlag = false;
      })
    },

    toggleReplyContainer(index) {
      // 重置子回复状态
      this.activeSubReplyIndex = {parentIndex: -1, subIndex: -1};

      if (this.activeReplyIndex === index) {
        this.activeReplyIndex = -1; // 点击同一个按钮时，隐藏输入框
      } else {
        this.activeReplyIndex = index; // 显示点击按钮对应的输入框
      }
    },

    toggleSubReplyContainer(index, subIndex) {
      // 重置根回复状态
      this.activeReplyIndex = -1;

      if (this.activeSubReplyIndex.parentIndex === index && this.activeSubReplyIndex.subIndex === subIndex) {
        this.activeSubReplyIndex = {parentIndex: -1, subIndex: -1};
      } else {
        this.activeSubReplyIndex = {parentIndex: index, subIndex: subIndex};
      }
    },

    deleteSubComment(subComment, index, subIndex) {
      const config = {
        headers: {
          'Authorization': sessionStorage.getItem("token") // 替换 yourToken 为实际的 token 值
        }
      }

      console.log(subComment.commentContent)

      const requestData = {
        id: subComment.id,
        articleId: this.articleId,
      }

      this.$http.post("/deleteSubCommentById", requestData, config).then(res => {
        console.log(res);
        if (res.data.code === 200) {
          this.$message.success(this.$t("basic.delete_success").toString());
          this.$set(this.confirmDeleteSubCommentVisible[index], subIndex, false);
          // 重新获取评论区
          this.getArticleComments();
          //   评论数 - 1
          this.articleComments -= res.data.deleteCommentCount;
          //   重置评论区子评论详情
          this.checkAllSubCommentFlag = {};
        } else {
          this.$message.warning(this.$t("basic.delete_failed").toString());
        }
      }).catch(err => {
        console.error(err);
        this.$message.warning(this.$t("basic.delete_failed").toString());
      })
    },

    deleteRootComment(rootCommentId, index) {
      const config = {
        headers: {
          'Authorization': sessionStorage.getItem("token") // 替换 yourToken 为实际的 token 值
        }
      }

      const requestData = {
        rootCommentId: rootCommentId,
        articleId: this.articleId,
      }

      this.$http.post("/deleteRootComment", requestData, config).then(res => {
        console.log(res);
        if (res.data.code === 200) {
          this.$message.success(this.$t("basic.delete_success").toString());
          this.confirmDeleteCommentVisible[index] = false;
          // 重新获取评论区
          this.getArticleComments();
          //   评论数 - n
          this.articleComments -= res.data.deleteCommentCount;
          //   重置评论区子评论详情
          this.checkAllSubCommentFlag = {};
        } else {
          this.$message.warning(this.$t("basic.delete_failed").toString());
        }
      }).catch(err => {
        console.error(err);
        this.$message.warning(this.$t("basic.delete_failed").toString());
      })
    },

    getArticleComments() {
      const requestData = {
        articleId: this.articleId
      }

      console.log(requestData)

      this.$http.get('/selectCommentsByArticleId', {
            params: requestData
          }
      ).then(res => {
        console.log(res);
        this.commentList = res.data.commentList;
        //   重置评论区子评论详情
        this.checkAllSubCommentFlag = {};

        // 初始化 confirmDeleteSubCommentVisible
        this.commentList.forEach((comment, index) => {
          this.$set(this.confirmDeleteSubCommentVisible, index, []);
          comment.subCommentList.forEach((subComment, subIndex) => {
            this.$set(this.confirmDeleteSubCommentVisible[index], subIndex, false);
          });
        });
      }).catch(err => {
        console.error(err);
      })
    },

    submitComment() {
      this.publishCommentFlag = true;

      const requestData = {
        articleId: this.articleId,
        commentContent: this.commentEdit,
        commentType: 1,
      }

      const config = {
        headers: {
          'Authorization': sessionStorage.getItem("token") // 替换 yourToken 为实际的 token 值
        }
      }

      this.$http.post('/insertComment', requestData, config).then(res => {
        if (res.data.code === 200) {
          this.$message.success(this.$t("basic.publish_success_text").toString());
          this.commentEdit = "";
          this.drawerVisible = false;

          //   重新获取当前文章的所有评论
          this.getArticleComments();
          //   评论数+1
          this.articleComments += 1;
        } else {
          this.$message.error(this.$t("basic.publish_failed_text").toString());
        }
      }).catch(err => {
        console.error(err);
        this.$message.error(this.$t("basic.publish_failed_text").toString());
      }).finally(() => {
        this.publishCommentFlag = false;
      })
    },

    undercarriageArticle() {
      this.undercarriageFlag = true;

      const config = {
        headers: {
          'Authorization': sessionStorage.getItem("token") // 替换 yourToken 为实际的 token 值
        }
      }

      const requestData = {
        articleId: this.articleId,
        articleStatus: 3
      }

      this.$http.post('/updateArticle', requestData, config).then(res => {
        if (res.data.code === 200) {
          this.$message.success(this.$t("basic.undercarriage_success_text").toString());
          this.undercarriageArticleDialogVisible = false;
          this.$router.go(0);
        } else {
          this.$message.warning(this.$t("basic.undercarriage_failed_text").toString());
        }
      }).catch(err => {
        console.error(err);
        this.$message.warning(this.$t("basic.undercarriage_failed_text").toString());
      }).finally(() => {
        this.undercarriageFlag = false;
      })
    },

    deleteArticle() {
      const requestData = {
        articleId: this.articleId
      };

      const config = {
        headers: {
          'Authorization': sessionStorage.getItem("token") // 替换 yourToken 为实际的 token 值
        }
      }

      this.$http.post('/deleteArticleByArticleId', requestData, config).then(res => {
        if (res.data.code === 200) {
          this.$message.success(this.$t("basic.delete_success").toString());
          this.$router.push('/myArticle');
        }
      })
    },

    updateArticle() {
      this.$router.push({
        name: "UpdateArticle", params: {
          id: this.articleId,
          title: this.articleTitle,
          content: this.articleContent,
          contentWithStyle: this.contentWithStyle,
        }
      });
    },

    getArticleById() {
      this.articleLoading = true;

      if (this.$route.params.isCurrentUserArticle) {
        this.isCurrentUserArticle = true;
      }

      const requestData = {
        articleId: this.$route.params.id
      }

      this.$http.get('getArticleByArticleId', {
        params: requestData
      }).then(res => {
        console.log(res)

        this.articleId = res.data.data.articleId;
        this.articleTitle = res.data.data.articleTitle;
        this.authorName = res.data.nickname;
        this.authorImageUrl = res.data.userImage;
        this.articleContent = res.data.data.articleContent;
        this.articleComments = res.data.data.articleComments;
        this.articleStatus = res.data.data.articleStatus;
        this.articleLike = res.data.data.articleLike;
        this.contentWithStyle = res.data.data.contentWithStyle;
        this.releaseTime = moment(res.data.data.releaseTime).format('yyyy-MM-DD HH:mm');

        switch (res.data.data.articleSource) {
          case 1:
            this.articleSource = this.$t("basic.original_text").toString();
            this.typeJudge = "danger";
            break;
          case 2:
            this.articleSource = this.$t("basic.repost_text").toString();
            this.typeJudge = "success";
            break;
          case 3:
            this.articleSource = this.$t("basic.translate_text").toString();
            this.typeJudge = "warning";
            break;
          default:
            this.articleSource = this.$t("basic.unknown_text").toString();
            this.typeJudge = "info";
            break;
        }

      }).catch(err => {
        console.error(err);
      }).finally(() => {
        this.articleLoading = false;
        // 获取文章详情后再发送请求获取评论详情
        this.getArticleComments();
      })
    },

    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },

    backToHome() {
      if (this.$route.params.isCurrentUserArticle) {
        this.$router.push("/myArticle")
      } else {
        this.$router.push("/home");
      }
    },
  },
}
</script>

<style scoped>
.page-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.topic-navigate {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #545c64;
  height: 50px;
}

.article-container {
  height: calc(100vh - 100px);
  overflow-y: auto;
}

.article-title {
  /*text-align: center;*/
  padding: 10px 10px;
  font-size: 22px;
}

.author-container {
  display: flex;
  flex-direction: row;
  padding: 10px 10px;
  align-items: center;
  font-weight: bold;
}

.article-content-container {
  white-space: pre-wrap;
  text-align: justify;
  word-break: break-word;
  padding: 10px 20px;
  line-height: 1.5;
}

.ql-editor >>> img {
  max-width: 100%;
}

.multi-button-container {
  border-top: 1px solid #ebebeb;
  height: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.function-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
}

.comment-title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.comment-section-container {
  width: 100%;
}

.submit-comment-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 5px 10px;
  justify-content: space-between;
}

.submit-comment-container >>> .el-textarea__inner{
  border: 0;
  height: 13vh;
}

.published-comment-container {

}

.published-comment-card {
  border-bottom: 1px solid #ebebeb;
  margin: 20px 20px
}

.all-comment-container {

}

.root-comment-container {

}

.root-comment-user-msg {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.root-comment-content {
  margin: 20px 20px 20px 60px;
}

.comment-msg {
  color: #949797;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: small;
  margin: 0 20px 0 10px
}

.reply-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.sub-comment-container {
  margin-left: 40px;
  font-size: small;
}

.sub-comment-card {
  margin-top: 5px;
}

.sub-comment-user-msg {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.sub-comment-content {
  padding: 10px 0;
  margin-left: 10px;
}

.root-comment-reply-count {
  height: 40px;
  font-size: small;
  color: #949797;
  font-weight: normal;
  margin-left: 100px;
  display: flex;
  align-items: center;
}

</style>
