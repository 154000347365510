<template>
  <div class="table-width-style">
    <el-table
        class=""
        :data="sharpeRatioData"
        stripe
        style=""
        :cell-style="cellStyle">
      <el-table-column
          prop="code"
          :label="$t('basic.stock_codes')"
          width="135">
      </el-table-column>
      <el-table-column
          :prop="sharpeRatioProp"
          :label="labelOfData"
          width="115"
      ></el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "ShowSharpeRatioTable",

  props: {
    sharpeRatioData: Array,
    sharpeRatioProp:String,
    labelOfData:String,
  },

  methods:{
    sortData(a, b) {
      // 非数字情况处理
// 自定义排序函数
      debugger
      if (a < 0 && b < 0) {
        return b - a; // 负数按照从小到大排序
      } else if (a < 0) {
        return -1; // 负数排在正数之前
      } else if (b < 0) {
        return 1; // 正数排在负数之前
      } else {
        return a - b; // 正数按照从小到大排序
      }
    },

    cellStyle({ row, column, rowIndex, columnIndex }){
      if (column.property === 'code') {
        return 'font-weight: bold;'; // 这里可以设置具体的样式
      } else {
        return '';
      }
    },

  }
}
</script>

<style scoped>

</style>
