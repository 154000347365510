<template>
  <div>
    <div class="topic-style">
      <el-menu
          :default-active="activeIndex"
          class="nav-menu-style"
          :style="{ width: totalMenuWidth + 'px' }"
          mode="horizontal"
          @select="handleSelect"
          background-color="#545c64"
          text-color="#fff"
          active-text-color="#42b983">
        <el-menu-item index="1" style="width: auto">{{ $t("basic.market_info_text") }}</el-menu-item>
        <el-menu-item index="2" style="width: auto">{{ $t("basic.research_report_text") }}</el-menu-item>
        <el-menu-item index="3" style="width: auto">{{ $t("basic.perspectives_and_interviews_text") }}</el-menu-item>
      </el-menu>
    </div>

    <div class="article-list" v-loading="articleListLoading">

      <div class="article-list-container" v-if="articleList.length !== 0">
        <div v-for="(article,index) in articleList" :key="index" @click="showArticleDetail(article.articleId)"
             class="article-item-container">

          <mobile-article-card
              :article-title="article.articleTitle"
              :article-brief-content="article.articleBriefContent"
              :release-time="moment(article.releaseTime).format('yyyy-MM-DD HH:mm')"
              :article-like="article.articleLike"
              :article-views="article.articleViews"
              :article-comments="article.articleComments"
          >
          </mobile-article-card>
        </div>
      </div>

      <!--      暂无内容提示-->
      <div v-else style="color: #949797;display: flex;align-items: center;height: 80%;justify-content: center">
        <h1>{{ $t("basic.no_data_msg") }}</h1>
      </div>

    </div>

    <floating-menu class="floating-menu-style" v-if="isShowMenu === 'true'">
      <div class="menu-item" @click="jumpToUser">
        <i class="fa fa-user fa-3x"></i>
      </div>

      <div class="menu-item" @click="jumpToEditArticle">
        <i class="fa fa-pencil fa-3x"></i>
      </div>
      <el-tooltip placement="left" effect="light" :content="$t('basic.one_click_tips')">
      </el-tooltip>
    </floating-menu>

    <div v-else>
      <router-link to="/user" class="menu-item-user">
        <i class="fa fa-user fa-3x"></i>
      </router-link>
    </div>
  </div>

</template>

<script>
import MobileArticleCard from "@/components/component/MobileArticleCard";
import FloatingMenu from "@/components/menu/FloatingMenu";
import moment from "moment";

export default {
  name: "MobileHome",
  components: {FloatingMenu, MobileArticleCard},
  data() {
    return {
      articleListLoading: true,
      isShowMenu: sessionStorage.getItem("isShowMenu"),

      activeIndex: "",

      totalMenuWidth: 0,

      articleList: [],

    }
  },

  created() {
    this.activeIndex = this.$route.params.menuIndex || sessionStorage.getItem("homeActiveIndex");
    this.getArticlesByParams(this.activeIndex);
    this.moment = moment; // 挂载 moment 到组件实例
  },

  mounted() {
    this.calculateTotalWidth();
    window.addEventListener('resize', this.calculateTotalWidth);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.calculateTotalWidth);
  },

  methods: {
    calculateTotalWidth() {
      this.$nextTick(() => {
        const menuItems = this.$el.querySelectorAll('.el-menu-item');
        let totalWidth = 10;
        menuItems.forEach(item => {
          totalWidth += item.offsetWidth;
        });
        this.totalMenuWidth = totalWidth;
      });
    },

    jumpToUser() {
      this.$router.push('/user')
    },

    jumpToEditArticle() {
      this.$router.push('/editArticle')
    },

    getArticlesByParams(articleTopic) {
      this.articleListLoading = true;
      sessionStorage.setItem("homeActiveIndex", articleTopic);

      const requestData = {
        articleTopic: articleTopic,
        // 仅获取文章状态为已发布的文章
        articleStatus: 2,
      }

      this.$http.get('/getArticleByParams', {

        params: requestData
      }).then(res => {
        console.log(res)
        this.articleList = res.data.data;
      }).catch(err => {
        console.error(err)
      }).finally(() => {
        this.articleListLoading = false;
      })
    },

    showArticleDetail(articleId) {
      //  获取文章详细信息
      this.$router.push({name: 'ArticleDetails', params: {id: articleId}});
    },

    handleSelect(key, keyPath) {
      console.log(key, keyPath);
      this.getArticlesByParams(key);
    },

    truncateText(text, limit) {
      return text.length > limit ? text.slice(0, limit) + "..." : text;
    },
  }
}
</script>

<style scoped>
.topic-style {
  min-width: 345px;
  overflow-x: auto;
}

.topic-style::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important;
}

.nav-menu-style {
  min-width: 100vw;
}

.article-list {
  display: flex;
  justify-content: center;
  height: calc(100vh - 60px);
  width: 100%;
  overflow-y: auto;
}

.article-list-container {
  margin-top: 10px;
  width: 100%;
}

.article-item-container {
  width: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center
}

.floating-menu-style {
  bottom: 20px !important; /* 修改 bottom 样式 */
  right: 20px !important; /* 修改 right 样式 */
  -webkit-tap-highlight-color: transparent; /* 设置透明色 */
  -webkit-user-select: none; /* 禁止用户选择文本 */
}

.menu-item {
  text-align: center;
  font-size: 13px;
  width: 55px;
  height: 55px;
  background-color: #fff;
  color: #949797;
  font-weight: bold;
  border: 1px solid #cccccc;
  box-shadow: 0 5px 4px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  -webkit-tap-highlight-color: transparent; /* 设置透明色 */
  -webkit-user-select: none; /* 禁止用户选择文本 */
}

.menu-item-user {
  position: absolute;
  bottom: 20px;
  right: 20px;
  text-align: center;
  font-size: 13px;
  width: 55px;
  height: 55px;
  background-color: #fff;
  color: #949797;
  font-weight: bold;
  border: 1px solid #cccccc;
  box-shadow: 0 5px 4px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  -webkit-tap-highlight-color: transparent; /* 设置透明色 */
}

</style>
