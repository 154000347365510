<template>
  <div class="floating-menu" :class="{ 'open': isOpen }">
    <button @click="toggleMenu" class="toggle-button">{{$t("basic.menu_text")}}</button>
    <Transition name="fade">
      <div v-show="isOpen" class="menu-items">
        <!-- Your menu items go here -->
<!--        <div @click="handleMenuItemClick('Option 1')" class="menu-item">提问</div>-->
<!--        <div @click="handleMenuItemClick('Option 2')" class="menu-item">留言</div>-->
<!--        <div @click="handleMenuItemClick('Option 3')" class="menu-item">客服</div>-->
        <slot></slot>
        <!-- Add more menu items as needed -->
      </div>
    </Transition>

  </div>
</template>

<script>
export default {
  name:"FloatingMenu",

  data() {
    return {
      isOpen: true,
    };
  },
  methods: {
    toggleMenu() {
      this.isOpen = !this.isOpen;
    },
    handleMenuItemClick(option) {
      // Handle menu item click event
      console.log(`Clicked: ${option}`);
      // You can perform other actions here based on the clicked option
    },
  },
};
</script>

<style scoped>
.floating-menu {
  position: fixed;
  bottom: 50px;
  right: 50px;
}

.toggle-button {
  text-align: center;
  width: 55px;
  height: 55px;
  font-weight: bold;
  font-size: 18px;
  border-radius: 50%;
  background-color: #2c3e50;
  box-shadow: 0 5px 4px rgba(0, 0, 0, 0.2);
  color: #42b983;
  padding: 0px;
  border: none;
  cursor: pointer;
}

.menu-items {
  margin-right: -32px;
  width: 80px;
  position: absolute;
  bottom: 60px;
  right: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: transparent;
  /*box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);*/
  /*border-radius: 5px;*/
  padding: 10px;
  display: none;
}

.menu-item {
  width: 40px;
  height: 40px;
  background-color: #fff;
  color: #949797;
  font-weight: bold;
  border: 1px solid #cccccc;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.menu-items div {
  cursor: pointer;
  padding: 8px;
  /*border-radius: 3px;*/
  transition: background-color 0.3s ease;
}

.menu-items div:hover {
  background-color: #f0f0f0;
}

.open .menu-items {
  display: flex;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
