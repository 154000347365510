<template>
  <LayoutContainer>
    <template v-slot:my-header>
      <div>
        <el-dropdown trigger="click">
              <span class="el-dropdown-link" style="cursor: pointer;color:#42b983;font-weight: bold">
                {{ $t("basic.current_account") }}{{ username }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="signOut">{{ $t("basic.quit_account") }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </template>

    <div style="display:flex;flex-direction:column;align-items:center;  min-width: 1410px;">
      <div class="kdj-param-form">
        <div>
          <i class="el-icon-caret-right"></i>
          <el-popover
              placement="top-start"
              width="200"
              trigger="hover"
              :content="$t('basic.input_code_description')">
            <el-button type="text" style="color: black;margin-right: 10px" slot="reference">
              {{ $t('basic.stock_codes') }}
            </el-button>
          </el-popover>
          <el-autocomplete
              class="inline-input"
              v-model="securityCode"
              :fetch-suggestions="querySearch"
              placeholder="请输入内容"
              @select="handleSelect"
              clearable
          ></el-autocomplete>
        </div>

        <div>
          <span>
            <i class="el-icon-caret-right"></i>
            <el-popover
                placement="top-start"
                width="200"
                trigger="hover"
                :content="$t('basic.date_range_description')">
              <el-button type="text" style="color: black;margin-right: 10px" slot="reference">{{
                  $t("basic.date_range")
                }}</el-button>
            </el-popover>
            <el-date-picker
                v-model="startAndEndDate"
                style="width: 250px"
                class=".el-picker-panel .el-picker-panel__sidebar"
                type="daterange"
                align="right"
                unlink-panels
                range-separator="~"
                :start-placeholder="$t('basic.start_date_enter_tips')"
                :end-placeholder="$t('basic.end_date_enter_tips')"
                :picker-options="pickerOptions">
            </el-date-picker>
          </span>
        </div>

      </div>

      <div>
        <el-button @click="getKdjIndex" style="margin: 20px">{{ $t("basic.compare_button") }}</el-button>
      </div>

      <div
          class="echart"
          ref="mychart"
          id="mychart"
          :style="{ float: 'left', width: '100%', height: '400px' }"
      ></div>
    </div>

  </LayoutContainer>


</template>

<script>
import moment from "moment";
import * as echarts from "echarts";
import LayoutContainer from "@/components/layout/LayoutContainer";

export default {
  name: "KdjIndex",
  components: {LayoutContainer},
  data() {
    return {
      isCollapse: true,
      loading: true,

      securityCode: '',

      jIndexDay: [],
      jIndexWeek: [],
      jIndexMonth: [],
      date: [],
      securityData: [],
      startAndEndDate: [],
      multipleSelection: [],
      username: sessionStorage.getItem("username"),

      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [

          {
            text: this.$t("basic.five_years_prev_trading").toString(),
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 365 * 5);
              const today = new Date();
              picker.$emit('pick', [date, today]);
            }
          },
          {
            text: this.$t("basic.three_years_prev_trading").toString(),
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 365 * 3);
              const today = new Date();
              picker.$emit('pick', [date, today]);
            }
          },
          {
            text: this.$t("basic.one_year_prev_trading").toString(),
            onClick: (picker) => {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 365);
              const today = new Date();
              picker.$emit('pick', [date, today]);
            }
          },

          {
            text: this.$t("basic.three_years_prev_month").toString(),
            onClick: (picker) => {
              const today = new Date();
              const oldYear = today.getFullYear() - 3;

              // 计算上个月的最后一天
              const lastMonthLastDay = new Date(today.getFullYear(), today.getMonth(), 0);

              // 设置开始日期（上个月的第一天）
              const startDate = new Date(oldYear, lastMonthLastDay.getMonth() + 1, 0);

              // 设置结束日期（上个月的最后一天往前一年）
              const endDate = new Date(today.getFullYear(), lastMonthLastDay.getMonth(), lastMonthLastDay.getDate());

              picker.$emit('pick', [startDate, endDate]);
            }
          },
          {
            text: this.$t("basic.one_year_prev_month").toString(),
            onClick: (picker) => {
              const today = new Date();
              const lastYear = today.getFullYear() - 1;

              // 计算上个月的最后一天
              const lastMonthLastDay = new Date(today.getFullYear(), today.getMonth(), 0);

              // 设置开始日期（上个月的第一天）
              const startDate = new Date(lastYear, lastMonthLastDay.getMonth() + 1, 0);

              // 设置结束日期（上个月的最后一天往前一年）
              const endDate = new Date(today.getFullYear(), lastMonthLastDay.getMonth(), lastMonthLastDay.getDate());

              picker.$emit('pick', [startDate, endDate]);
            }
          },
          {
            text: this.$t("basic.entire_five_years").toString(),
            onClick(picker) {
              const today = new Date();
              const lastYear = today.getFullYear() - 5;

              // 设置开始日期（前一年的1月1日）
              const startDate = new Date(lastYear, 0, 1); // 0 表示 1 月

              // 设置结束日期（前一年的12月31日）
              const endDate = new Date(lastYear + 4, 11, 31); // 11 表示 12 月

              picker.$emit('pick', [startDate, endDate]);
            }
          },
          {
            text: this.$t("basic.entire_three_years").toString(),
            onClick(picker) {
              const today = new Date();
              const lastYear = today.getFullYear() - 3;

              // 设置开始日期（前一年的1月1日）
              const startDate = new Date(lastYear, 0, 1); // 0 表示 1 月

              // 设置结束日期（前一年的12月31日）
              const endDate = new Date(lastYear + 2, 11, 31); // 11 表示 12 月

              picker.$emit('pick', [startDate, endDate]);
            }
          },
          {
            text: this.$t("basic.entire_one_year").toString(),
            onClick(picker) {
              const today = new Date();
              const lastYear = today.getFullYear() - 1;

              // 设置开始日期（前一年的1月1日）
              const startDate = new Date(lastYear, 0, 1); // 0 表示 1 月

              // 设置结束日期（前一年的12月31日）
              const endDate = new Date(lastYear, 11, 31); // 11 表示 12 月
              picker.$emit('pick', [startDate, endDate]);
            }
          },

        ]
      },

      echartOption: {
        title: {
          text: 'KDJ Index'
        },
        tooltip: {
          trigger: 'axis'
        },
        dataZoom: [{
          type: 'inside'
        }],
        legend: {
          data: [this.$t("basic.J_value_daily"), this.$t("basic.J_value_weekly"), this.$t("basic.J_value_monthly")]
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          // type: 'category',
          type: 'time',
          boundaryGap: false,
          data: [],
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: this.$t("basic.J_value_daily"),
            type: 'line',
            // stack: 'Total',
            data: [],
          },
          {
            name: this.$t("basic.J_value_weekly"),
            type: 'line',
            // stack: 'Total',
            data: [],
          },
          {
            name: this.$t("basic.J_value_monthly"),
            type: 'line',
            // stack: 'Total',
            data: [],
          },
        ]
      },
    }
  },

  created() {
    this.getSecurityCodeList();
  },

  mounted() {
    this.initEcharts();
  },

  watch: {
    // k_index(newValue){
    //   if(newValue.length !== 0){
    //
    //   }
    // },
  },

  methods: {
    chartChange() {

    },

    initEcharts() {
      this.$set(this.echartOption.title, 'text', this.securityCode + ' KDJ Index');
      this.$set(this.echartOption.xAxis, 'data', this.date);
      this.$set(this.echartOption.series[0], 'data', this.jIndexDay);
      this.$set(this.echartOption.series[1], 'data', this.jIndexWeek);
      this.$set(this.echartOption.series[2], 'data', this.jIndexMonth);
      const myChart = echarts.init(this.$refs.mychart);// 图标初始化
      myChart.setOption(this.echartOption);// 渲染页面
      //随着屏幕大小调节图表
      window.addEventListener("resize", () => {
        myChart.resize();
      });
    },

    getKdjIndex() {
      if (!this.securityCode || !this.startAndEndDate) {
        this.$message.error(this.$t('basic.form_filling_err').toString())
        return;
      }

      let secuType = 1;
      if (this.securityCode.endsWith("OF")) {
        secuType = 3;
      }

      const param = {
        codes: this.securityCode,
        adjustFlag: '3',
        curType: '1',
        secuType: secuType,
        startDate: moment(this.startAndEndDate[0]).format('yyyy-MM-DD'),
        // startDate:'2019-07-01',
        endDate: moment(this.startAndEndDate[1]).format(('yyyy-MM-DD')),
      }

      this.$http.get('/getKdjIndex', {
        headers: {
          'Authorization': sessionStorage.getItem("token") // 替换 yourToken 为实际的 token 值
        },
        params: param
      }).then(response => {
        console.log(response)
        // 将字符串数组转换为数值数组
        debugger
        const kdjDataList = response.data;
        // this.jIndexDay = kdjDataList.KDJDayValue[0]['j_index'];
        // this.jIndexWeek = kdjDataList.KDJWeekValue[0]['j_index'];
        // this.jIndexMonth = kdjDataList.KDJMonthValue[0]['j_index'];
        // this.k_index = response.data[0].k_index.map(element => Number(element.toFixed(3)));
        // this.d_index = response.data[0].d_index.map(element => Number(element.toFixed(3)));
        // this.j_index = response.data[0].j_index.map(element => Number(element.toFixed(3)));
        const weekDate = kdjDataList.KDJWeekValue[0]['dates'].map((dateStr => new Date(dateStr)));
        const monthDate = kdjDataList.KDJMonthValue[0]['dates'].map((dateStr => new Date(dateStr)));
        this.date = kdjDataList.KDJDayValue[0]['dates'].map((dateStr => new Date(dateStr)));

        this.jIndexDay = this.date.map((date, index) => [date, kdjDataList.KDJDayValue[0]['j_index'][index]]);
        this.jIndexWeek = weekDate.map((date, index) => [date, kdjDataList.KDJWeekValue[0]['j_index'][index]]);
        this.jIndexMonth = monthDate.map((date, index) => [date, kdjDataList.KDJMonthValue[0]['j_index'][index]]);
        this.initEcharts();
      }).catch(err => {
        console.error(err)
      })


    },

    querySearch(queryString, cb) {
      const securityData = this.securityData;
      const results = queryString ? securityData.filter(this.createFilter(queryString)) : securityData;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (securityData) => {
        return (securityData.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    handleSelect(item) {
      console.log(item);
    },

    getSecurityCodeList() {
      this.$http.get('/getSecurityCodeList',{
        headers: {
          'Authorization': sessionStorage.getItem("token") // 替换 yourToken 为实际的 token 值
        }
      }).then(response => {
        console.log(response)
        this.securityData = response.data.map((item) => ({value: item}));
      }).catch(err => {
        console.error(err);
      })
    },

    collapseNav() {
      this.isCollapse = !this.isCollapse;
    },

    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },


    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },

    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    signOut() {
      sessionStorage.setItem("username", '');
      sessionStorage.setItem("isLoggedIn", '');
      this.$message.success(this.$t("basic.sign_out_success").toString());
      this.$router.push('/login');
    },
  },
}
</script>

<style scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}

.toggle-button {
  width: 100%;
}

.header-style {
  background-color: #2c3e50;
  color: #42b983;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.kdj-param-form {
  display: flex;

}


</style>
