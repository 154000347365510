<template>
  <div class="my-article-container">
    <div class="topic-navigate">
      <i class="fa fa-reply" style="color: #ffffff;padding: 10px" @click="$router.push('/user')"></i>
    </div>

    <div class="article-list" v-loading="articleListLoading">

<!--      暂无内容提示-->
      <div v-if="articleList.length === 0" style="color: #949797;display: flex;align-items: center;height: 80%;justify-content: center">
        <h1>{{$t("basic.no_data_msg")}}</h1>
      </div>

      <div class="article-list-container">
        <div v-for="(article,index) in articleList" :key="index" @click="showMyArticleDetail(article)"
             class="article-item-container">

          <mobile-article-card
              :article-title="article.articleTitle"
              :article-brief-content="article.articleBriefContent"
              :release-time="moment(article.releaseTime).format('yyyy-MM-DD HH:mm')"
              :article-like="article.articleLike"
              :article-views="article.articleViews"
              :article-comments="article.articleComments"
          >
            <!-- 调用插槽，并传入状态标签的内容 -->
            <template v-slot:statusTag>
              <el-tag :type="article.typeJudge" size="small" style="margin-right: 5px">{{ article.articleStatus }}</el-tag>
            </template>
          </mobile-article-card>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import MobileArticleCard from "@/components/component/MobileArticleCard";
import moment from "moment";
import FloatingMenu from "@/components/menu/FloatingMenu";

export default {
  name: "MobileMyArticle",
  components: {FloatingMenu, MobileArticleCard},

  data() {
    return {
      articleListLoading: true,

      articleList: [],
    }
  },

  created() {
    this.getMyArticle();
    this.moment = moment;
  },

  methods: {
    showMyArticleDetail(article) {
      //  获取文章详细信息
      const articleId = article.articleId;
      if (article.typeJudge === "") {
        //  当前文章状态为草稿，直接跳转到文章编辑界面
        this.$router.push({
          name: 'EditDraft', params: {
            id: String(article.articleId)
          }
        })
      } else if (article.typeJudge === "success") {
        //  当前文章状态为已发布
        this.$router.push({
          name:'ArticleDetails',params:{
            id:String(article.articleId),
            isCurrentUserArticle:String("yes"),
          }
        })
      } else if (article.typeJudge === "warning") {
        //  当前文章状态为已下架
        this.$router.push({
          name:'ArticleDetails',params:{
            id:String(article.articleId),
            isCurrentUserArticle:String("yes"),
          }
        })
      }

      // this.$router.push({name: 'ArticleDetails', params: {
      //   id: String(articleId)
      // }});
    },

    getMyArticle() {
      this.articleListLoading = true;
      this.$http.get('getMyArticle', {
        headers: {
          'Authorization': sessionStorage.getItem("token") // 替换 yourToken 为实际的 token 值
        }
      }).then(res => {
        console.log(res)
        this.articleList = res.data.data;

        this.articleList.forEach(article => {
          switch (article.articleStatus) {
            case 1:
              article.articleStatus = this.$t("basic.draft_text");
              article.typeJudge = "";
              break;
            case 2:
              article.articleStatus = this.$t("basic.published_text");
              article.typeJudge = "success";
              break;
            case 3:
              article.articleStatus = this.$t("basic.archived_text");
              article.typeJudge = "warning";
              break;
            default:
              break;
          }
        })
      }).catch(err => {
        console.error(err);
      }).finally(() => {
        this.articleListLoading = false;
      })
    },

  }
}
</script>

<style scoped>
.my-article-container {
  display: flex;
  flex-direction: column;
}

.topic-navigate {
  display: flex;
  align-items: center;
  background-color: #545c64;
  height: 50px;
}

.article-list {
  height: calc(100vh - 50px);
  overflow-y: auto;
}

.article-list-container {
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.article-item-container{
  width: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center
}

</style>
