export default {
    basic: {
        //登录界面
        login_title: '用户登录',
        username: '账号',
        username_enter_tips: '登录账号',
        password: '密码',
        password_enter_tips: '登录密码',
        login_button: '登录',
        sign_up_tips: '没有账号？立即注册',
        forget_password:'忘记密码',
        no_username_or_password_err: '请输入账号或密码',
        login_success: '登录成功',
        login_failed: '登录失败',
        incorrect_username_or_password: '账号或密码错误',
        account_not_exist: '账户不存在',
        read_and_agree_disclaimer_text:'阅读并同意《免责声明》',
        disclaimer_text:'免责声明',
        disclaimer_content:'同意免责声明：\n       股票价格受多种因素影响，夏普比例最大化仅是基于历史数据对某一个组合中个股之间一个相对权重的建议，并不预测该股票价格未来的涨跌。' +
            '本建议系依据管理人在美国证监会网站公布的一个特定时间的组合，不代表该组合的现状。有关建议仅是本系统作为考察夏普比例的适用性而作的内部研究，在数据加工和输出的过程中难免会出现错误。' +
            '\n       该组合的管理者和任何其他机构、个人使用本系统或者参照本系统产生的任何结论和建议，也仅供其作为学习和研究的参考，不得作为其投资的具体依据。由此产生任何后果责任均与本公司和本系统发明人无关。',
        agree_text:'同意',
        disagree_text:'不同意',
        read_disclaimer_text:'请阅读并同意免责声明。',


        //注册界面
        register_title: '用户注册',
        //用户名与密码复用登录界面
        email: '邮箱',
        email_enter_tips: '输入邮箱账号',
        get_verification_code_button: '获取验证码',
        verification_code: '验证码',
        verification_code_enter_tips: '输入验证码',
        register_button: '注册',
        sign_in_tips: '已有帐号？返回登录',
        incomplete_registration_information: '请完善以下信息',
        register_success: '账户创建成功',
        register_failed: '注册失败',
        username_already_exist: '用户名已存在',
        email_no_exist_err: '目标邮箱不存在',
        email_send_success: '邮件发送成功',
        email_send_err: '邮件发送失败',
        incorrect_verification_code: '验证码不正确',
        verification_timeout: '验证码已过期，请重新申请验证码',

        //主界面
        system_title:'高方德基金组合优化系统',
        login_to_choice: '重新登录choice接口',
        quit_choice: '退出choice接口',
        search_record_management:'搜索记录管理',
        current_account: '当前账户: ',
        quit_account: '退出登录',
        language_tips:'选择语言',

        //表单信息
        stock_codes: '证券代码',
        stock_codes_enter_tips: '请输入股票代码',
        stock_count: '已输入证券数: ',
        stock_enter_err_tips: '输入有误，请检查逗号使用',
        portfolio_type:'组合类型',
        portfolio_type_tips:'请选择组合的类型',
        date_range: '选择日期',
        start_date_enter_tips: '开始日期',
        end_date_enter_tips: '截止日期',
        weight_limit: '权重上限(%)',
        //日期快捷键
        five_years_prev_trading:'上个交易日往前五年',
        three_years_prev_trading:'上个交易日往前三年',
        one_year_prev_trading:'上个交易日往前一年',
        three_years_prev_month:'上个月末往前三年',
        one_year_prev_month:'上个月末往前一年',
        entire_five_years:'前五年整年',
        entire_three_years:'前三年整年',
        entire_one_year:'前一年整年',
        year_to_date:'今年年初至今',
        //组合类型
        portfolio_type_stock:'股票',
        portfolio_type_bond:'债券',
        portfolio_type_fund:'基金',
        portfolio_type_index:'指数',
        //收盘价
        date_period: '日期周期',
        daily_closing_price: '日收盘价',
        weekly_closing_price: '周收盘价',
        monthly_closing_price: '月收盘价',
        yearly_closing_price: '年收盘价',
        //复权方式
        adjustment: '复权方式',
        forward_adjustment: '前复权',
        backward_adjustment: '后复权',
        no_adjustment: '不复权',
        //表单功能信息描述
        input_code_notification:'* 本系统暂时不支持跨市场组合测算功能',
        chinese_mutual_fund_only_text:'* 仅支持中国国内基金',
        input_code_description:'输入相同币种的证券代码。若存在与组合类型已选项不同的证券，请保证该证券代码附带后缀信息。',
        portfolio_type_description:'选择组合所属的类型',
        date_range_description:'选择该组合需要测算的日期区间（可点击日历左侧的快捷键来快速填入日期区间）',
        weight_limit_description:'拖动进度条或直接输入数字来指定权重上限值',
        date_period_description:'选择组合用来测算的数据的日期周期',
        adjustment_description:'选择组合用来测算的数据的复权方式',

        //币种
        currency: '币种',
        original_currency: '原始币种',
        RMB: '人民币',
        HKD: '港币',
        USD: '美元',
        //市场类型
        market: '市场类型',
        CNSESH: '上海证券交易所',
        CNSESZ: '深圳证券交易所',
        HKSE00: '香港证券交易所',
        USSE00: '美国证券交易所',
        USSEND: '美国纳斯达克市场',
        USSENY: '纽约证券交易所',
        CNFEBC: '渤海商品交易所',
        CNFEDC: '大连商品交易所',
        CNFESF: '上海期货交易所',
        CNFEZC: '郑州商品交易所',
        INE000: '上海国际能源交易中心',
        CNGCSH: '上海黄金交易所',
        HKME00: '香港商品交易所',
        nature_day: '自然日',
        all_market_trading_days: '全部市场交易日',
        CNSH00: '沪股通交易日',
        CNSHHK: '胡港股通交易日',
        CNSZ00: '深股通交易日',
        CNSZHK: '深港股通交易日',
        NYMEX0: '纽约商业期货交易所',
        USFENY: '纽约商品交易所',
        CME000: '芝加哥商业交易所',
        LDMETL: '伦敦金属交易所',
        LDEXCH: '伦敦证券交易所',
        SGSE00: '新加坡交易所',
        //按钮
        cal_weight_button: '计算优化权重',
        calculating_button: '计算中...',
        clear_all_button: '清空结果',
        one_click_generation_button: '一键生成',
        one_click_tips:'一键生成指定日期前一年、前三年、前五年的夏普比率和优化权重',
        wait_a_minute_tips:'加载中，请稍后...',
        customize_portfolio:'自定义组合',
        operation_guidance:'操作引导',
        generate_portfolio_sharpe_button:'计算夏普比率',
        portfolio_sharpe_list:'夏普比率',
        security_listing_date:'上市日期',
        security_sharpe_since_listing:'上市以来',
        security_five_year_sharpe:'五年期',
        security_three_year_sharpe:'三年期',
        security_one_year_sharpe:'一年期',
        menu_text:'菜单',


        //数据区域
        system_recommended_weight_allocation:'优化权重',
        mean_weighted_interval_total_return: '均权总收益率',
        weighted_return: '均权年化收益率',
        weighted_volatility: '均权年化波动',
        weighted_sharpe: '均权夏普比率',
        weighted_interval_total_return: '优权总收益率',
        weighted_return_with_premium: '优权年化收益率',
        weighted_volatility_with_premium: '优权年化波动',
        sharpe_with_premium: '优权夏普比率',
        asc_sort_button:'升序排序',
        desc_sort_button:'降序排序',

        //表格区域
        table_stock: '证券代码',
        table_weight: '权重分配',
        cover_table:'请选择一个表格进行覆盖',
        cover_table1:'表格1',
        cover_table2:'表格2',
        cover_table3:'表格3',
        cover_table4:'表格4',
        cover_table5:'表格5',
        cover_table_button:'确定',

        //回测功能区域
        compare_title: '均权收益与优权收益回测比较',
        choose_table: '数据选择',
        choose_table_tips: '请选择一个表格',
        base_on_table1: '根据表格1数据测算组合收益',
        base_on_table2: '根据表格2数据测算组合收益',
        base_on_table3: '根据表格3数据测算组合收益',
        base_on_table4: '根据表格4数据测算组合收益',
        base_on_table5: '根据表格5数据测算组合收益',
        choose_new_date: '选择新日期',
        choose_start_date_tips: '开始日期',
        choose_end_date_tips: '截止日期',
        choose_date_notice:'注意：请注意当天收盘价时间！若在收盘前，请将截止日期往前推一天',
        no_weight_compare_button:'请先计算权重',
        compare_button:'计算',
        weight_limit_tips:'权重上限：',
        compare_date:'日期',
        compare_mean_weighted_return:'均权收益率',
        compare_weighted_return:'优权收益率',
        //回测功能区域描述
        table_choose_description:'选择一个表格的权重数据进行回测',
        new_date_range_description:'选择需要回测的日期区间',
        customize_weight:'自定义权重',
        remain_weight:'剩余可支配权重',
        reset_text:'重置',
        portfolio_yield_detail:'组合收益详情',
        total_returns:'个股收益 (%)',
        total_weighted_returns:'个股权后收益 (%)',
        view_details:'查看详情',
        stock_yield_detail:'个股收益详情',
        date_text:'日期',
        actual_returns:'实际收益 (%)',
        after_weight_returns:'权后收益 (%)',
        custom_weight_returns_title:'自定义权重收益率',
        custom_weight_volatility_title:'自定义权重年化波动率',
        custom_weight_sharpe_ratio_title:'自定义权重夏普比率',


        //弹窗提示信息
        login_choice_success:'登录成功',
        sign_out_success:'注销成功',
        form_filling_err:'请填写以下表单',
        date_filling_err:'日期填写有误，请重新输入',
        no_data_err:'存在证券无交易数据：',
        unrecognized_code_err:' 无法识别，请添加代码后缀或检查拼写',
        no_login_err:'身份验证过期！请重新登录',
        code_invalid_err:'股票代码输入有误',
        no_data_msg:'暂无数据',
        cal_success:'计算完毕',
        form_filling_except_date_err:'请完善除日期外的表单',
        date_and_table_data_err:'请选择需要计算的时间段和选择表格数据',

        //侧边栏
        jump_to_home:'主页',
        jump_to_kdj:'KDJ 指标',
        jump_to_bookmarks:'收藏',
        jump_to_customize:'自定义',
        jump_to_record:'记录',
        jump_to_settings:'设置',
        jump_to_user:'个人中心',
        jump_to_help:'疑问解答',

        //收藏界面
        portfolio_compare_title:'组合数据对比',
        bookmark_name:'收藏名称',
        bookmark_name_tips:'请输入收藏名称',
        //组合类型已存在
        // portfolio_type:'组合类型',
        bookmark_content:'收藏内容',
        bookmark_date:'收藏日期',
        operation:'操作',
        add_bookmark_button_text:'添加组合',
        add_bookmark_text:'添加收藏',
        add_text:'添加',
        view_portfolio_changes_button:'查看',
        add_bookmark_title:'添加组合收藏',
        enter_code_tips:'请输入相关证券代码',
        available_options_list:'待选列表' ,
        selected_options_list:'已选列表',
        delete_button_text:'删除',
        add_success_text:'添加成功',
        add_failed_text:'添加失败',
        recalculate_button_text:'重新计算',
        generate_report:'生成报告',

                //组合类型
        // portfolio_type_stock:'股票',
        // portfolio_type_fund:'基金',
        portfolio_performance_changes:'组合表现变化情况',
        mean_with_total_change:'均权总收益变化',
        mean_return_change:'均权年化收益率变化',
        mean_annualized_volatility_change:'均权年化波动变化',
        mean_sharpe_change:'均权夏普比率变化',
        optimize_with_total_change:'优权总收益变化',
        weighted_return_change:'优权年化收益率变化',
        weighted_annualized_volatility_change:'优权年化波动变化',
        weighted_sharpe_change:'优权夏普比率变化',
        weight_allocation_change:'权重变化情况',
        weight_increase_code:'权重增加的证券：',
        weight_decrease_code:'权重减少的证券：',
        KDJ_index_performance:'KDJ指标表现情况',
        K_index_performance:'K值表现情况',
        D_index_performance:'D值表现情况',
        J_index_performance:'J值表现情况',
        J_value_daily:'J值日线',
        J_value_weekly:'J值周线',
        J_value_monthly:'J值月线',



        //kdj指标界面


        //搜索记录管理界面
        record_management_title:'搜索记录管理',
        create_time:'日期',
        record_content:'记录内容',
        delete_button:'删除记录',
        code_exist_err:'已存在于收藏内容中，请勿重复添加',

        //弹窗提示信息
        no_record_choose_err:'请选择需要删除的记录',
        delete_confirm:'确定删除这些记录吗？',
        delete_confirm_title:'提示',
        confirm_button_text:'确 定',
        cancel_button_text:'取 消',
        delete_success:'删除成功',
        delete_failed:'删除失败',
        delete_cancel:'已取消删除',

    //    用户中心界面
        personal_information:'用户信息',
    //    用户名同username
    //    邮箱同email
    //    唯一身份id，即uuid
        personal_id:'ID',
        activate_time:'注册时间',
        update_password:'修改密码',
        verify_email_tips:'为保证账号安全，需要验证邮箱',
        new_password:'新密码',
        new_password_confirm:'确认密码',
        passwords_not_match_text:'两次输入的密码不一致，请重新输入',
        password_update_success_text:'密码修改完成',

    //    引导内容
        back_text:'上一步',
        next_text:'下一步',
        skip_text:'跳过',
        done_text:'完成',
        code_input_text:'证券代码输入',
        code_input_description:'输入相同交易货币类型的股票代码，并用半角的逗号 "," 来区分隔。例如：BABA,GOOGL,NVDA,<br>如果输入的股票组合中存在指数，请保证指数的代码携带代码后缀。例如：恒生指数HSI参与组合时，需写为HSI.HI。',
        portfolio_type_choose_text:'组合类型选择',
        portfolio_type_choose_description:'组合类型分为股票和基金两种类型。组合类型为股票时，可以实现股票、指数的组合计算；组合类型为基金时，可以完成中国国内基金的组合计算。',
        date_range_choose_text:'日期区间选择',
        date_range_choose_description:'系统根据历史数据来给出优化建议，可自定义时间段来确定历史数据的选择区间。',
        weight_limit_text:'权重上限',
        weight_limit_text_description:'为每个股票设置权重上限，需确保股票个数 * 权重上限 ≥ 100%。',
        backtesting_text:'回测功能区域',
        backtesting_description:`通过上方计算出组合的系统优化建议后，可在此回测当前优化建议的收益。`,
        data_and_date_range_choose_text:'数据选择和日期选择',
        data_and_date_range_choose_description:`1、选择上方五个表格中的某一项权重分配建议来作为回测使用的决策。<br>2、选择组合需要回测，观察区间收益的时间段。`,
        customize_weight_text:'自定义权重',
        customize_weight_description:`自定义该组合中每个股票的权重分配值，回测计算和自定义权重前都需保证证券代码输入框中存在组合，并且至少完成一次组合优化计算。`,
        view_yield_detail_text:'查看收益详情',
        view_yield_detail_description:`点击“放大镜”图标按钮，即可查看组合中每只股票的实际收益，权后收益，以及查看某只股票每月的收益详情。`,
        reboot_text:'重新引导',
        reboot_description:'点击此处可重新查看引导流程。',

    //    文章区域
        enter_article_title_tips:'请输入标题 (5-100字)',
        enter_article_content_tips:'请输入文章内容',
        article_source_text:'文章来源',
        original_text:'原创',
        repost_text:'转载',
        translate_text:'翻译',
        article_topic_text:'文章专题',
        market_info_text:'市场资讯',
        research_report_text:'研究报告',
        perspectives_and_interviews_text:'观点与访谈',
        publish_text:'发布文章',
        topic_content_empty_error:'请完善文章主题或内容',
        publish_success_text:'发布成功',
        more_choice_text:'更多',
        unknown_text:'未知',
        browsing_history_text:'浏览记录',
        my_article_text:'我的文章',
        my_bookmark_text:'我的收藏',
        published_text:'已发布',
        draft_text:'草稿',
        archived_text:'已下架',
        published_on_text:'发布于',
        confirm_save_draft_text:'是否保存草稿？',
        yes_text:'是',
        discard_text:'否',
        save_success_text:'保存成功',
        save_failed_text:'保存失败',
        update_article_text:'修改文章',
        confirm_quit_update_text:'确认要退出修改吗 ?',
        publish_failed_text:'发布失败',
        confirm_delete_text:'确定要删除该文章吗？',
        quit_success_text:'退出成功',
        quit_failed_text:'退出失败',
        manage_article_text:'管理文章',
        quit_manage_text:'退出管理',
        undercarriage_text:'下架',
        edit_text:'编辑',
        confirm_undercarriage_text:'确认要下架该文章吗？',
        undercarriage_success_text:'下架成功',
        undercarriage_failed_text:'下架失败',
        update_nickname_text:'修改昵称',
        new_nickname_text:'新昵称',
        enter_new_nickname_tips:'请输入新昵称',
        submit_text:'提交',
        duplicated_nickname_tips:'新昵称与原昵称不得相同',
        update_success_text:'修改成功',
        update_failed_text:'修改失败',
        nickname_already_exist_text:'昵称已存在，请重新输入',
        comment_section_text:'评论区',
        enter_comment_tips:'在这里发表你对该文章的看法吧！',
        publish_btn_text:'发布',
        delete_comment_confirm_text:'确定要删除这条评论吗？',
        reply_text:'回复',
        commentable_after_login:'登录后即可参与评论',
        total_reply_count_text:'总回复数：',
        check_all_text:', 查看全部',

        click_to_comment_text:'点击评论',
        transmit_text:'转发',
        like_text:'点赞',
        like_failed_text:'点赞失败',
        copy_url_tips:'文章链接已复制到剪贴板',
        copy_url_error_tips:'文章链接获取失败',
        content_text:'正文',
        my_subscriptions_text:'我的订阅',
        add_subscription_text:'添加订阅',
        topic_already_subscribe_text:'已订阅该专题，无法重复订阅',
        invitation_code_text:'邀请码',
        enter_invitation_code_text:'请输入专题邀请码',
        invalid_invitation_code_text:'* 无效的邀请码',
        create_topic_text:'创建专题',
        topic_name_text:'专题名称',
        enter_topic_name_text:'请输入专题名称(必填)',
        topic_desc_text:'专题描述',
        enter_topic_desc_text:'请输入专题描述(必填)',
        create_success_text:'创建成功',
        create_failed_text:'创建失败',
        publish_article_to_managed_topic:'发布至我管理的专题',
        my_topic_text:'我的专题',
        user_interface:'用户界面',
        unsubscribe_text:'退订',
        manage_topic_text:'管理专题',
        unsubscribed_topic_text:'退订专题：',
        confirm_unsubscribe_text:'确定要退订该专题吗？',
        unsubscribe_success_text:'退订成功',
        unsubscribe_failed_text:'退订失败',
        get_invitation_code_text:'获取邀请码',
        copy_invitation_code_success_text:'邀请码已复制到剪贴板',
        copy_invitation_code_failed_text:'邀请码获取失败',


    }
}
