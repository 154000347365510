<template>

  <LayoutContainer>

    <template v-slot:my-header>
      <div>
        <el-dropdown trigger="click">
              <span class="el-dropdown-link" style="cursor: pointer;color:#42b983;font-weight: bold">
                {{ $t("basic.current_account") }}{{ username }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="signOut">{{ $t("basic.quit_account") }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </template>

    <div style="display: flex;height: 83vh;margin-left: 5vh;">
      <div class="discussion_list" v-loading="discussionLoading">
        <div v-for="(discussion,index) in discussionList" :key="index" @click="showDescriptionDetail(index)"
             style="cursor: pointer">
          <el-descriptions style="user-select: none" :title="discussion.discussionTitle" class="discussion_container"
                           :column="4">
            <el-descriptions-item label="提问人" label-style="font-weight:bold">{{ discussion.username }}
            </el-descriptions-item>
            <el-descriptions-item label="回答数" label-style="font-weight:bold">{{ discussion.answerCount }}
            </el-descriptions-item>
            <el-descriptions-item label="提问时间" label-style="font-weight:bold">{{ discussion.discussionTime }}
            </el-descriptions-item>
            <el-descriptions-item label="问题状态" label-style="font-weight:bold">
              <el-tag size="small" :type="getTagType(discussion.discussionStatus)">{{
                  discussion.discussionStatus
                }}
              </el-tag>
            </el-descriptions-item>
            <el-descriptions-item label="问题内容" label-style="font-weight:bold">
              {{ truncateText(discussion.discussionContent, 50) }}
            </el-descriptions-item>
          </el-descriptions>
        </div>
      </div>

      <div class="personal_info">
        <el-descriptions :column="3" :colon="false" direction="vertical" :content-style="contentStyle"
                         :label-style="labelStyle">
          <el-descriptions-item span="3">
            <el-image
                style="width: 100px; height: 100px;border-radius: 50%"
                :src="'data:image/jpeg;base64,' + base64Image"
                fit="cover"
            ></el-image>
          </el-descriptions-item>
          <el-descriptions-item label="提问数">{{ questionNumber }}</el-descriptions-item>
          <el-descriptions-item label="回答数">{{ answerNumber }}</el-descriptions-item>
          <el-descriptions-item label="被采纳数">{{ adoptedNumber }}</el-descriptions-item>
        </el-descriptions>
      </div>

    </div>

    <div class="floating-menu" :class="{ 'open': isOpen }">
      <button @click="toggleMenu" class="toggle-button">菜单</button>
      <Transition name="fade">
        <div v-show="isOpen" class="menu-items">
          <!-- Your menu items go here -->
          <div @click="editQuestionDialogVisible = true" class="menu-item">提问</div>
          <div @click="handleMenuItemClick('Option 3')" class="menu-item">客服</div>
          <!-- Add more menu items as needed -->
        </div>
      </Transition>

    </div>

    <el-dialog title="编辑问题" :visible.sync="editQuestionDialogVisible" style="z-index: auto">
      <el-form>
        <el-form-item label="提问人">
          <span>{{username}}</span>
        </el-form-item>
        <el-form-item label="标题">
          <el-input v-model="questionTitle" maxlength="30" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="问题内容">
          <el-input
              type="textarea"
              :rows="3"
              placeholder="请输入内容"
              v-model="questionContent">
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" style="display: flex;justify-content: center">
        <el-button type="primary" @click="submitDiscussion">提交</el-button>
        <el-button @click="editQuestionDialogVisible = false">取消</el-button>
      </div>
    </el-dialog>
  </LayoutContainer>

</template>

<script>
import moment from "moment";
import LayoutContainer from "@/components/layout/LayoutContainer";
import FloatingMenu from "@/components/menu/FloatingMenu";

export default {
  name: "Help",
  components: {FloatingMenu, LayoutContainer},
  data() {
    return {
      isOpen: true,
      isCollapse: true,
      loading: true,
      discussionLoading:true,
      editQuestionDialogVisible:false,

      base64Image: sessionStorage.getItem('userImage'),
      username: sessionStorage.getItem("username"),
      questionNumber: '',
      answerNumber: '',
      adoptedNumber: '',
      questionContent:'',
      questionTitle:'',

      fits: ['fill', 'contain', 'cover', 'none', 'scale-down'],
      discussionList: [],
      contentStyle: {
        "text-align": "center"
      },
      labelStyle: {
        "text-align": "center",
        "font-weight": "bold",
      },
    }
  },

  created() {
    this.getDiscussionList();
    this.getUserSituation();
  },

  mounted() {
  },

  methods: {
    submitDiscussion(){
      if(!this.questionTitle || !this.questionContent){
        this.$message.warning("请完善问题描述");
      }else{
        const discussionParam = {
          discussionTitle:this.questionTitle,
          discussionContent:this.questionContent,
        }
        this.$http.post("/insertDiscussion",discussionParam,{
          headers: {
            'Authorization': sessionStorage.getItem("token") // 替换 yourToken 为实际的 token 值
          }
        }).then(response=>{
          console.log(response)
          if(response.data.code === 'INSERT_SUCCESS'){
            this.$router.go(0);
            this.$message.success("提交成功");
          }
        }).catch(err=>{
          console.error(err)
        })
      }
    },

    getUserSituation() {
      this.$http.get('/selectUserSituation', {
        headers: {
          'Authorization': sessionStorage.getItem("token") // 替换 yourToken 为实际的 token 值
        }
      }).then(response => {
        this.adoptedNumber = response.data.adoptedNumber;
        this.answerNumber = response.data.answerNumber;
        this.questionNumber = response.data.questionNumber;
      }).catch(err=>{
        console.error(err);
      })
    },

    getDiscussionList() {
      this.discussionLoading = true;

      this.$http.get('/getDiscussionList',{
        headers: {
          'Authorization': sessionStorage.getItem("token") // 替换 yourToken 为实际的 token 值
        }
      }).then(res => {
        this.discussionList = res.data.map(discussion => {
          let statusText = '';
          switch (discussion.discussionStatus) {
            case 1:
              statusText = '未解决';
              break;
            case 2:
              statusText = '已回答未采纳';
              break;
            case 3:
              statusText = '已解决';
              break;
            default:
              break;
          }
          return {
            ...discussion,
            discussionStatus: statusText,
            discussionTime: moment(discussion.discussionTime).format('yyyy-MM-DD')
          };
        })
      }).catch(err => {
        console.error(err)
      }).finally(()=>{
        this.discussionLoading = false;
      })
    },

    getTagType(status) {
      switch (status) {
        case '未解决':
          return 'danger';
        case '已回答未采纳':
          return 'warning';
        case '已解决':
          return 'success';
        default:
          return 'default'; // 或者根据需要返回其他默认类型
      }
    },

    toggleMenu() {
      this.isOpen = !this.isOpen;
    },
    handleMenuItemClick(option) {
      // Handle menu item click event
      console.log(`Clicked: ${option}`);
      // You can perform other actions here based on the clicked option
    },
    showDescriptionDetail(index) {
      this.$message.success("click" + index)
    },
    truncateText(text, limit) {
      return text.length > limit ? text.slice(0, limit) + "..." : text;
    },
    collapseNav() {
      this.isCollapse = !this.isCollapse;
    },

    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },

    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },

    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    signOut() {
      sessionStorage.setItem("username", '');
      sessionStorage.setItem("isLoggedIn", '');
      this.$message.success(this.$t("basic.sign_out_success").toString());
      this.$router.push('/login');
    },
  },
}
</script>

<style scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}

.toggle-button {
  width: 100%;
}

.header-style {
  background-color: #2c3e50;
  color: #42b983;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.discussion_list {
  height: 95%;
  width: 1005px;
  overflow-y: auto;
  border: 2px solid #949797;
  padding: 15px;
}

.discussion_container {
  border: 1px solid #ebebeb;
  width: 1000px;
  height: 125px;
  padding: 10px 10px 50px;
  margin-bottom: 5px;
}

.personal_info {
  border: 1px solid #ebebea;
  margin-left: 50px;
  width: 220px;
  height: 220px;
  border-radius: 10px;
}
.floating-menu {
  position: fixed;
  bottom: 50px;
  right: 50px;
}

.toggle-button {
  height: 54px;
  font-weight: bold;
  font-size: 18px;
  border-radius: 50%;
  background-color: #2c3e50;
  box-shadow: 0 5px 4px rgba(0, 0, 0, 0.2);
  color: #42b983;
  padding: 10px;
  border: none;
  cursor: pointer;
}

.menu-items {
  margin-right: -32px;
  width: 80px;
  position: absolute;
  bottom: 60px;
  right: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: transparent;
  /*box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);*/
  /*border-radius: 5px;*/
  padding: 10px;
  display: none;
}

.menu-item {
  width: 40px;
  height: 40px;
  background-color: #fff;
  color: #949797;
  font-weight: bold;
  border: 1px solid #cccccc;
  box-shadow: 0 5px 4px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.menu-items div {
  cursor: pointer;
  padding: 8px;
  /*border-radius: 3px;*/
  transition: background-color 0.3s ease;
}

.menu-items div:hover {
  background-color: #f0f0f0;
}

.open .menu-items {
  display: flex;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
