<template>
  <div class="user-message-container">
    <div class="user-images">
      <i class="fa fa-mail-reply"
         style="position: absolute;left: 0;top: 0;padding: 15px;color: white;"
         @click="$router.push('/home')"
      ></i>

      <el-image
          style="width: 100px; height: 100px;border-radius: 50%"
          :src="imageUrl"
          fit="cover"
      ></el-image>

      <div class="username-container">
        <span>{{ username }}</span>
      </div>

    </div>

    <div v-if="!isUpdatePassword" class="user-functions-container">
      <div class="function-block">
        <div class="function-style" @click="$router.push('/browsingHistory')">
          <i class="fa fa-history fa-2x"></i>
          <span style="font-size: 12px">{{ $t("basic.browsing_history_text") }}</span>
        </div>

        <div class="function-style" @click="$router.push('/myArticle')">
          <i class="fa fa-book fa-2x"></i>
          <span style="font-size: 12px">{{ $t("basic.my_article_text") }}</span>
        </div>

        <div class="function-style" @click="$router.push('/myBookmark')">
          <i class="fa fa-star-o fa-2x"></i>
          <span style="font-size: 12px">{{ $t("basic.my_bookmark_text") }}</span>
        </div>
      </div>

      <div class="user-operation-container">
        <el-button @click="isUpdatePassword = true" class="user-functions-btn">{{ $t("basic.update_password") }}
        </el-button>
        <el-button @click="logout" class="user-functions-btn">{{ $t("basic.quit_account") }}</el-button>
      </div>

    </div>

    <div v-if="isUpdatePassword" class="user-functions-container">
      <div class="update-msg-container" v-if="!updatePasswordFormVisible">
        <div style="display: flex;flex-direction: column;align-items: center">
          <i class="fa fa-shield fa-5x"></i>
          <span style="font-size: small;color: #949797">{{ $t("basic.verify_email_tips") }}</span>
        </div>

        <div class="update-info-element-style">
          <span>{{ $t('basic.email') }}</span>
          <div style="display: flex">
            <el-input type="text" v-model="email" :placeholder="$t('basic.email_enter_tips')"></el-input>
            <el-button v-loading="sendEmailFlag" style="margin-left: 25px;color: dodgerblue;font-weight: bold"
                       @click="sendEmail">{{ $t("basic.get_verification_code_button") }}
            </el-button>
          </div>
        </div>

        <div class="update-info-element-style">
          <span>{{ $t('basic.verification_code') }}</span>
          <el-input v-model="verificationCode"></el-input>
        </div>

        <!--              点击确定需要验证验证码是否正确，正确则跳转到更新密码界面-->
        <el-button class="user-functions-btn" type="primary" @click="verifyEmail" :disabled="!isSendEmail">{{
            $t("basic.confirm_button_text")
          }}
        </el-button>
      </div>

      <!--    验证码验证通过，更新密码-->
      <div class="update-msg-container" v-if="updatePasswordFormVisible">
        <div class="update-info-element-style">
          <span>{{ $t('basic.new_password') }}</span>
          <el-input v-model="newPassword" type="password" show-password></el-input>
        </div>

        <div class="update-info-element-style">
          <span>{{ $t('basic.new_password_confirm') }}</span>
          <el-input v-model="newPasswordConfirm" type="password" show-password></el-input>
        </div>

        <el-button class="user-functions-btn" type="primary" @click="confirmUpdatePassword" :disabled="!isSendEmail">
          {{ $t("basic.confirm_button_text") }}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MobileUser",

  data() {
    return {
      updatePasswordFormVisible: false,
      sendEmailFlag: false,
      isSendEmail: false,
      isUpdatePassword: false,

      email: '',
      newPassword: '',
      newPasswordConfirm: '',
      verificationCode: '',
      tempToken: '',

      imageUrl: sessionStorage.getItem('userImage'),
      username: sessionStorage.getItem('username'),
    }
  },

  methods: {
    sendEmail() {
      if(this.email === ''){
        this.$message.warning(this.$t("basic.form_filling_err").toString());
        return;
      }

      this.sendEmailFlag = true;

      const requestData = {
        username: this.username,
        email: this.email
      }

      this.$http.post('/sendVerifyCodeEmail', requestData).then(response => {

        console.log(response);
        if (response.data.code === "EMAIL_SEND_SUCCESS") {
          this.$message.success(this.$t("basic.email_send_success").toString());
          this.isSendEmail = true;
        }

      }).catch(err => {
        this.$message.error(this.$t("basic.email_send_err").toString());
        console.error(err);
      }).finally(() => {
        this.sendEmailFlag = false;
      })
    },

    verifyEmail() {
      //  验证邮箱
      const requestData = {
        username: this.username,
        email: this.email,
        verificationCode: this.verificationCode
      }

      this.$http.post('/verifyCode', requestData).then(response => {
        console.log(response);
        if (response.data.code === "VERIFY_SUCCESS") {
          this.updatePasswordFormVisible = true;

          this.tempToken = response.data.tempToken;
        } else {
          this.$message.error(response.data.code);
        }
      }).catch(err => {
        console.error(err);
      })
    },

    confirmUpdatePassword() {
      if (this.newPassword !== this.newPasswordConfirm) {
        this.$message.warning(this.$t("basic.passwords_not_match_text").toString());
        return;
      }

      if(this.newPassword === '' || this.newPasswordConfirm === ''){
        this.$message.warning(this.$t("basic.form_filling_err").toString());
        return;
      }

      this.updatePasswordFlag = true;

      const requestData = {
        username: this.username,
        password: this.newPassword,
        tempToken: this.tempToken,
      }

      this.$http.post("/updatePassword", requestData).then(response => {
        if (response.data.code === "UPDATE_SUCCESS") {
          this.$message.success(this.$t("basic.password_update_success_text").toString())
        //   修改成功，跳转到用户界面
          this.isUpdatePassword = false;
        } else {
          this.$message.error(response.data.code);
        }
      }).catch(error => {
        console.error(error);
      }).finally(() => {
        this.updatePasswordFlag = false;
      })
    },

    logout() {
      this.$http.get('/user/logout', {
        headers: {
          'Authorization': sessionStorage.getItem("token") // 替换 yourToken 为实际的 token 值
        }
      }).then((response) => {
        if (response.data.code === 200) {
          this.$message.success(this.$t("basic.quit_success_text").toString());
          sessionStorage.clear();
          this.$router.push("/home");
        } else {
          this.$message.error(this.$t("basic.quit_failed_text").toString());
        }
      }).catch(error => {
        console.error(error);
      })
    },
  }
}
</script>

<style scoped>
.user-message-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #545c64;
}

.user-images {
  width: 100%;
  height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /*background-color: #545c64;*/
}

.username-container {
  padding: 10px 0;
  color: white;
  font-weight: bold;
}

.user-functions-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 70%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: #f5f5f5;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3); /* 水平偏移量 垂直偏移量 模糊半径 颜色 */
}

.function-block {
  display: flex;
  flex-direction: row;
  width: 80%;
  padding: 10px 10px;
  margin-top: 10px;
  background-color: white;
  border: 1px solid #ebebeb;
  border-radius: 10px;
  justify-content: space-around;
}

.function-style {
  padding: 10px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.user-operation-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  position: absolute;
  bottom: 40px;
}

.user-functions-btn {
  width: 80%;
  border-radius: 30px;
  margin-bottom: 10px;
}

.user-functions-btn {
  width: 80%;
  border-radius: 30px;
  margin-left: 0;
}

.update-info-element-style {
  width: 95%;
  padding: 9px;
}

.update-msg-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
